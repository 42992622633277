import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { rootReducer } from "./redux/reducers";
import thunk from "redux-thunk";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/tr_TR";

// export const API_ADDRESS = "https://devagrotek.azurewebsites.net";
// export const API_ADDRESS = 'https://agrotekapi.azurewebsites.net'
//export const API_ADDRESS = 'https://agrotekapi.covisart.com:8443';
//export const API_ADDRESS = 'https://agrotekapi.azurewebsites.net'
export const API_ADDRESS = "https://factoryapi.covisart.com";
//export const API_ADDRESS = 'https://localhost:7290';

const authState = sessionStorage.getItem("user")
  ? {
      auth: { user: JSON.parse(String(sessionStorage.getItem("user"))) },
    }
  : undefined;
export const store = createStore(
  rootReducer,
  authState,
  applyMiddleware(thunk)
);

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById("root")
);
