import React from "react";
import { Modal, Form, Input, Radio } from "antd";

interface RegistrationFormProps {
  visible: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
}

const config: IAgrotekConfig = JSON.parse(
  localStorage.getItem("config") ?? "{}"
);

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Yeni Bir Kullanıcı Oluştur."
      okText="Oluştur"
      cancelText="İptal Et"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
        requiredMark={false}
      >
        <Form.Item
          name="Mail"
          label="E-mail"
          rules={[
            { required: true, message: "Lütfen bir mail adresi girin." },
            { type: "email", message: "Lütfen geçerli bir mail adresi girin." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Name"
          label="Ad"
          rules={[{ required: true, message: "Lütfen bir isim girin." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Surname"
          label="Soyad"
          rules={[{ required: true, message: "Lütfen bir soyisim girin." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Role"
          className="collection-create-form_last-form-item"
          rules={[{ required: true, message: "Lütfen bir sınıf seçin." }]}
        >
          <Radio.Group>
            {(() => {
              return config.role.map((role) => {
                return <Radio value={role.type}>{role.text}</Radio>;
              });
            })()}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegistrationForm;
