import React from "react";
import { Typography } from "antd";

interface Props {
  title: string;
  data: string | number;
  color: string;
}
const { Title } = Typography;

function ReportSquare(props: Props) {
  const { title, data, color } = props;

  return (
    <div style={{ boxSizing: "border-box", overflow: "auto", maxWidth: "100%", margin: 10, borderRadius: 10 }}>
      <div style={{ display: "flex", flexDirection: "column", alignContent: "center", width: "100%", backgroundColor: color, padding: 5 }}>
        <Title level={5} style={{ margin: 0, color: "white" }}>
          {title}
        </Title>
        <Title level={3} style={{ margin: 0, color: "white" }}>
          {data}
        </Title>
      </div>
    </div>
  );
}

export default ReportSquare;
