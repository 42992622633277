import { Spin, Timeline, Card } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConvertDateTime, ConvertOrderLocation } from "../helper";
import IAgrotekConfig from "../IAgrotekConfig";
import { RootState } from "../redux/reducers";
import { GetGenericTaskHistory } from "../services/OrderService";

interface Props {
  isVisible: boolean;
  task_id: number;
  serial_number: string;
  setIsVisible: (bool: boolean) => void;
}

const config: IAgrotekConfig = JSON.parse(localStorage.getItem("config") ?? "{}");

function GenericTaskHistory(props: Props) {
  const { isVisible, setIsVisible, task_id } = props;
  const [data, setData] = useState((null as unknown) as []);

  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    GetHistory();
  }, [task_id]);

  function GetHistory() {
    GetGenericTaskHistory(task_id).then((res) => {
      if (res) {
        setData(res);
      }
    });
  }

  function renderTimeline() {
    return data?.map((timeline: any) => {
      return (
        <Timeline.Item key={"timelinekey" + timeline.row_created_date} label={ConvertDateTime(timeline.row_created_date)}>
          <p>
            <b>{config.role.find((role) => role.type === timeline.location)?.text}</b>
          </p>
          <p>{`Gönderen: ${timeline.name} ${timeline.surname}`}</p>
        </Timeline.Item>
      );
    });
  }

  return (
    <Modal title={task_id + " Numaralı Makinenin İşlem Geçmişi"} visible={isVisible} footer={null} onCancel={() => setIsVisible(false)}>
      <div style={{ display: "flex", width: "100%", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
        {data ? (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card title="Makine İşlem Geçmişi" style={{ marginBottom: 5 }}>
              <Timeline mode="left" style={{ width: "100%" }}>
                {renderTimeline()}
              </Timeline>
            </Card>
          </div>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </Modal>
  );
}

export default GenericTaskHistory;
