import { Card, Table } from 'antd';
import { useEffect, useState } from 'react';
import { GetChassisNeeds, GetCommanderNeeds, GetFanNeeds, GetPumpNeeds, GetTowNeeds } from '../services/NeedListService';

function NeedListScreen(Props: { type: string }) {
  function NeedsListTable() {
    const [data, setData] = useState([] as { name: string; amount: number }[]);

    useEffect(() => {
      GetData();
    }, []);

    function GetData() {
      switch (Props.type) {
        case 'Fan':
          GetFanNeeds().then((res) => {
            if (res) {
              let formattedData: { name: string; amount: number }[] = [];
              Object.keys(res).forEach((key: string) => {
                formattedData.push({ name: key, amount: res[key] });
              });
              setData(formattedData);
            }
          });
          break;
        case 'Kumanda':
          GetCommanderNeeds().then((res) => {
            if (res) {
              let formattedData: { name: string; amount: number }[] = [];
              Object.keys(res).forEach((key: string) => {
                formattedData.push({ name: key, amount: res[key] });
              });
              setData(formattedData);
            }
          });
          break;
        case 'Çeki':
          GetTowNeeds().then((res) => {
            if (res) {
              let formattedData: { name: string; amount: number }[] = [];
              Object.keys(res).forEach((key: string) => {
                formattedData.push({ name: key, amount: res[key] });
              });
              setData(formattedData);
            }
          });
          break;
        case 'Pompa':
          GetPumpNeeds().then((res) => {
            if (res) {
              let formattedData: { name: string; amount: number }[] = [];
              Object.keys(res).forEach((key: string) => {
                formattedData.push({ name: key, amount: res[key] });
              });
              setData(formattedData);
            }
          });
          break;
        case 'Şase':
          GetChassisNeeds().then((res) => {
            if (res) {
              let formattedData: { name: string; amount: number; finished_amount: number }[] = [];
              Object.keys(res['total']).forEach((key: string) => {
                formattedData.push({ name: key, amount: res['total'][key], finished_amount: res['finished'][key] });
              });
              setData(formattedData);
            }
          });
          break;
      }
    }

    const columns =
      Props.type === 'Şase'
        ? [
            {
              title: 'Ürün Adı',
              dataIndex: 'name',
              key: 'type',
              width: 50,
            },
            {
              title: 'Gereken Miktar',
              dataIndex: 'amount',
              width: 50,
            },
            {
              title: 'Stoktaki Miktar',
              dataIndex: 'finished_amount',
              width: 50,
              render: (data: any) => <div>{data ? data : 0}</div>,
            },
            {
              title: 'Net Stok',
              width: 50,
              render: (data: any, record: any, full_data: any) => {
                let net_stock = (record.finished_amount ? record.finished_amount : 0) - record.amount;
                return <div style={{ color: net_stock < 0 ? 'red' : undefined, fontWeight: 'bolder' }}>{net_stock}</div>;
              },
            },
          ]
        : [
            {
              title: 'Ürün Adı',
              dataIndex: 'name',
              key: 'type',
              width: 50,
            },
            {
              title: 'Gereken Miktar',
              dataIndex: 'amount',
              width: 50,
            },
          ];

    return (
      <Card style={{ width: '60%', marginBottom: '1%', marginTop: '2%' }}>
        <Table
          pagination={false}
          style={{ width: '100%' }}
          title={() => (
            <>
              <b>{Props.type} İhtiyaç Listesi</b>
            </>
          )}
          dataSource={data.sort((a, b) => a.name.localeCompare(b.name))}
          columns={columns}
        />
      </Card>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <NeedsListTable />
      </div>
    </>
  );
}

export default NeedListScreen;
