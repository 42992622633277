import {
  Table,
  Button,
  Modal,
  Popconfirm,
  Select,
  Layout,
  Col,
  Row,
  Divider,
} from "antd";
import { Component } from "react";
import { Body } from "./MainScreen";
import { ColumnsType } from "antd/es/table";
import { UserAddOutlined } from "@ant-design/icons";
import {
  DeleteUser,
  ReopenUser,
  GetAllUsers,
  Register,
  ChangePasswordAdmin,
  ChangeRole,
} from "../services/UsersService";
import RegistrationForm from "../components/RegistrationForm";
import ChangePasswordForm from "../components/ChangePasswordForm";
import IAgrotekConfig from "../IAgrotekConfig";
import { Footer } from "antd/es/layout/layout";

const { Content, Sider } = Layout;

interface Employe {
  name: string;
  surname: string;
  mail: string;
  role: string;
  isDeleted: boolean;
}

const { Option } = Select;
const config: IAgrotekConfig = JSON.parse(
  localStorage.getItem("config") ?? "{}"
);

class ManageEmployeScreen extends Component {
  state = {
    selectedRowKeys: [],
    changePasswordMail: "",
    registrationButtonLoading: false, //Yeni kullanıcı ekleme butonunun status'u
    registrationFormVisible: false, //Yeni kullanıcı ekleme formu görünür mü
    changePasswordFormVisible: false, //Kullanıcının şifre değiştirme formu görünür mü
    tableLoading: true,
    data: [],
  };

  columns: ColumnsType<Employe> = [
    {
      title: "Ad",
      dataIndex: "name",
      sorter: (a: Employe, b: Employe) => a.name.localeCompare(b.name),
    },
    {
      title: "Soyad",
      dataIndex: "surname",
      sorter: (a: Employe, b: Employe) => a.surname.localeCompare(b.surname),
    },
    {
      title: "E-Mail",
      dataIndex: "mail",
      sorter: (a: Employe, b: Employe) => a.mail.localeCompare(b.mail),
    },
    {
      title: "Rol",
      render: (value, record) => {
        return (
          <>
            <Select
              defaultValue={record.role}
              style={{ width: 120 }}
              onChange={async (value) => {
                const result = await ChangeRole(record.mail, value);
                if (!result) {
                  Modal.error({
                    title: "Bir hata oluştu",
                    content: (
                      <p>
                        Kullanıcının rolü değiştirilemedi lütfen tekrar deneyin.
                      </p>
                    ),
                    okText: "Tamam",
                  });
                }
              }}
            >
              {(() => {
                return config.role.map((role) => {
                  return <Option value={role.type}>{role.text}</Option>;
                });
              })()}
            </Select>
          </>
        );
      },
    },
    {
      title: "İşlemler",
      width: 10,
      render: (value, record) => {
        return (
          <>
            <Popconfirm
              title={`Bu kullanıcıyı ${
                record.isDeleted ? "açmak" : "silmek"
              } istediğinizden emin misiniz?`}
              onConfirm={async () => {
                if (record.isDeleted) {
                  if (await ReopenUser(record.mail)) {
                    this.getTableData();
                  }
                } else {
                  if (await DeleteUser(record.mail)) {
                    this.getTableData();
                  }
                }
              }}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button block danger type="primary">
                {record.isDeleted ? "Yeniden Aç" : "Kullanıcıyı Sil"}
              </Button>
            </Popconfirm>
            <Button
              style={{ marginTop: 5 }}
              onClick={() => {
                this.showChangePasswordForm(record.mail);
              }}
              block
              type="default"
            >
              Şifre Değiştir
            </Button>
          </>
        );
      },
    },
  ];

  async getTableData() {
    let users = await GetAllUsers();
    if (users) {
      this.setState({ data: users });
    }
    this.setState({ tableLoading: false });
  }

  componentDidMount() {
    this.getTableData();
  }

  showRegistrationForm = () => {
    this.setState({
      registrationButtonLoading: true,
      registrationFormVisible: true,
    });
  };

  showChangePasswordForm = (mail: string) => {
    this.setState({
      changePasswordFormVisible: true,
      changePasswordMail: mail,
    });
  };

  onSelectChange = (selectedRowKeys: string) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      registrationButtonLoading,
      tableLoading,
      selectedRowKeys,
      data,
      registrationFormVisible,
      changePasswordFormVisible,
      changePasswordMail,
    } = this.state;

    const hasSelected = selectedRowKeys.length > 0;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Content>
          <Divider orientation="center" plain>
            <Row>
              <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                <Button
                  type="primary"
                  onClick={this.showRegistrationForm}
                  icon={<UserAddOutlined />}
                  loading={registrationButtonLoading}
                >
                  Kullanıcı Ekle
                </Button>
              </Col>
            </Row>
          </Divider>

          <Table
            loading={tableLoading}
            rowKey={(row) => row.mail}
            columns={this.columns}
            dataSource={data}
          />
          <RegistrationForm
            visible={registrationFormVisible}
            onCreate={async (values) => {
              let user = await Register(values);
              if (user) {
                let data = [].concat(this.state.data, user);
                this.setState({
                  data,
                  registrationFormVisible: false,
                  registrationButtonLoading: false,
                });
              } else {
                Modal.error({
                  title: "Bir hata oluştu",
                  content: (
                    <p>Bu mail adresi daha önce kayıt olmuş olabilir.</p>
                  ),
                  okText: "Tamam",
                });
              }
            }}
            onCancel={() => {
              this.setState({
                registrationButtonLoading: false,
                registrationFormVisible: false,
              });
            }}
          />
          <ChangePasswordForm
            visible={changePasswordFormVisible}
            mail={changePasswordMail}
            onCreate={async (values) => {
              let user = await ChangePasswordAdmin(values);
              if (user) {
                this.setState({ changePasswordFormVisible: false });
                Modal.success({
                  title: "İşlem başarılı",
                  content: <p>Kullanıcının şifresi başarıyla değişti.</p>,
                  okText: "Tamam",
                });
              } else {
                Modal.error({
                  title: "Bir hata oluştu",
                  content: <p>Lütfen tekrar deneyin.</p>,
                  okText: "Tamam",
                });
              }
            }}
            onCancel={() => {
              this.setState({ changePasswordFormVisible: false });
            }}
          />
        </Content>
      </Layout>
    );
  }
}

export default ManageEmployeScreen;
