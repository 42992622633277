import { Capitalize } from "../../helper";

const FanInvoice = ({ item }) => {
  const configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const formattedDate = `${day}/${month}/${year}`;

  const data = {
    Ürün: item.description,
    Marka: configFile.brand.filter((b) => b.type === item.brand)[0]?.text,
    Tip: configFile.type.filter((b) => b.type === item.type)[0]?.text
      ? configFile.type.filter((b) => b.type === item.type)[0]?.text
      : configFile.machine_type.filter((b) => b.type === item.type)[0]?.text,

    ...(item.cooler_type && {
      "Fan Tipi": configFile.cooler_type.filter(
        (b) => b.type === item.cooler_type
      )[0]?.text,
    }),

    ...(item.tank_size && {
      "Kazan Boyutu": item.tank_size + " LT",
    }),

    ...(item.pump && {
      Pompa: configFile.pump.filter((b) => b.type === item.pump)[0]?.text,
    }),

    ...(item.tank_size && {
      "Kazan Boyutu": item.tank_size + " LT",
    }),

    ...(item.mixer_type && {
      "Mixer Tipi": configFile.mixer_type.filter(
        (b) => b.type === item.mixer_type
      )[0]?.text,
    }),

    ...(item.meme_type && {
      "Meme Tipi": configFile.meme_type.filter(
        (b) => b.type === item.meme_type
      )[0]?.text,
    }),

    ...(item.meme_distance && {
      "Meme Aralığı": item.meme_distance,
    }),

    ...(item.tow_type && {
      "Çeki Tipi": configFile.tow_type.filter(
        (b) => b.type === item.tow_type
      )[0]?.text,
    }),

    ...(item.arm_type && {
      "Kol Tipi": configFile.arm_type.filter((b) => b.type === item.arm_type)[0]
        ?.text,
    }),

    ...(item.arm_length && {
      "Kol Uzunluğu": item.arm_length,
    }),

    "Şase Rengi": configFile.chassis_color.filter(
      (b) => b.type === item.chassis_color
    )[0]?.text,
    "Kazan Rengi": Capitalize(item.caldron_color),
    "Kazan Tipi": configFile.caldron_type.filter(
      (b) => b.type === item.caldron_type
    )[0]?.text,
    Kumanda: configFile.commander.filter((b) => b.type === item.commander)[0]
      ?.text,
    "Ek Özellikler": item.alt_features,
  };

  return (
    <div
      style={{
        width: "21cm",
        height: "29.7cm",
      }}
    >
      <div style={{ border: "1px solid black", marginRight: "4px" }}>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid black",
            gap: "0.5cm",
            padding: "0.3cm",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <img width="40" height="40" src="agrotek.png" alt="logo" />
          <div style={{ display: "flex", gap: "0.5cm", alignItems: "center" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <span>ÇİFTÇİ</span>
              <div
                style={{
                  border: "1px solid orange",
                  height: "15px",
                  width: "30px",
                }}
              />
            </div>
            <div style={{ display: "flex", gap: "5px" }}>
              <span>BAYİ</span>
              <div
                style={{
                  border: "1px solid orange",
                  height: "15px",
                  width: "30px",
                }}
              />
            </div>
            <div style={{ display: "flex", gap: "5px" }}>
              <span>KURUMSAL</span>
              <div
                style={{
                  border: "1px solid orange",
                  height: "15px",
                  width: "30px",
                }}
              />
            </div>
          </div>
          <span>SÖZLEŞME TARİHİ: {formattedDate} </span>
          <span>SERİ NO: {item?.serial_number}</span>
          <span>
            PLASİYER: <br />
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <span style={{ textAlign: "center" }}>
              MÜŞTERİNİN AD-SOYAD/ÜNVANI:
            </span>
            <span
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                height: "32px",
              }}
            >
              MÜŞTERİNİN ADRESİ / TEL NO:
            </span>
            <span
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                height: "32px",
              }}
            >
              MAKİNE ADI:
            </span>

            <span
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                height: "32px",
              }}
            >
              SATIŞ ŞEKLİ:
            </span>

            <span
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              TAHSİLAT VADESİ:
            </span>
            <br />
            <br />
          </div>
          <div
            style={{ backgroundColor: "black", width: "1px", height: "166px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 3,
            }}
          >
            <span style={{ paddingLeft: "20px" }}>{item?.customer}</span>
            <span
              style={{
                paddingLeft: "20px",
                height: "32px",
                borderTop: "1px solid black",
              }}
            >
              {item?.address} / {item?.phone_number}
            </span>
            <div
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                height: "32px",
                maxHeight: "32px",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <span
                style={{
                  whiteSpace: "break-spaces",
                  fontWeight: "bold",
                }}
              >
                ADET <br />
                <span style={{ fontWeight: "normal" }}>
                  {item?.order_amount} AD.
                </span>
              </span>
              <div
                style={{
                  backgroundColor: "black",
                  width: "1px",
                  height: "32px",
                }}
              />
              <div style={{}}>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  FİYAT
                  <br />
                  <span style={{ fontWeight: "normal" }}>
                    {item.price.toFixed(3)} {item.currency}
                  </span>
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  width: "1px",
                  height: "32px",
                }}
              />
              <div style={{}}>
                <span
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "break-spaces",
                  }}
                >
                  TUTAR <br />
                  <span style={{ fontWeight: "normal" }}>
                    {(item.order_amount * item.price).toFixed(3)}{" "}
                    {item.currency}
                  </span>
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  width: "1px",
                  height: "32px",
                }}
              />
              <span
                style={{
                  marginTop: "-16px",
                  textDecoration: "underline",
                }}
              >
                ÖZEL NOTLAR
              </span>
            </div>

            <div
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                display: "flex",
                height: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginTop: "0.2cm",
                  marginLeft: "0.7cm",
                }}
              >
                <span>NAKİT</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: "1.4cm",
                  marginTop: "0.2cm",
                }}
              >
                <span>ZİRAİ KREDİ</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: "1.2cm",
                  marginTop: "0.2cm",
                }}
              >
                <span>C/H</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: "1.8cm",
                  marginTop: "0.2cm",
                }}
              >
                <span>ÇEK</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                display: "flex",
              }}
            >
              <span
                style={{
                  flex: 2,

                  borderRight: "1px solid black",
                }}
              >
                ÖDEME TARİHİ
              </span>
              <span
                style={{
                  flex: 1,

                  borderRight: "1px solid black",
                }}
              >
                TUTAR
              </span>
              <span style={{ flex: 2 }}>KALAN BAKİYE</span>
            </div>
            <div
              style={{
                height: "32px",
                textAlign: "center",
                borderTop: "1px solid black",
                display: "flex",
              }}
            >
              <span
                style={{
                  height: "37px",

                  flex: 2,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              ></span>
              <span
                style={{
                  height: "37px",

                  flex: 1,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              ></span>
              <span
                style={{
                  height: "37px",
                  flex: 2,
                  borderBottom: "1px solid black",
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "1rem", paddingBottom: 0, textAlign: "justify" }}>
        <span>
          1-1-Makina, ……………………… …………………………. adresinde ALICIYA teslim
          edilecektir. Taşıma ve Nakliye Bedeli …..……. ait olup, bu nedenle
          oluşacak hasarlar da ……………. sorumluluğundadır. Makina’nın teslimi ile
          sorumluluk ALICIYA geçer.
          <br /> 2-Makina 2 yıl boyunca SATICININ garantisi altında olup bu süre
          içerisinde makinanın servis ve bakımları ücretsiz olarak yapılacaktır.
          Sonraki yıllarda makinanın servis ve bakımından ücret alınabileceğini
          ALICI kabul eder. Makinanın teslimi ile birlikte, makinanın kurulum ve
          kullanımla ilgili olarak ALICI’ya eğitim verilecektir. Hatalı
          kullanımdan doğan arızalar garanti kapsamı dışındadır.
          <br /> 3-Nakit satımlarda, ALICI’dan peşinat alınır, kalan bedel en
          geç makinanın teslim günü ALICI tarafından SATICI’ya ödenecektir.
          Bedelin ödenmemesi halinde makina, SATICI tarafından ALICI’ya teslim
          edilmez.
          <br /> 4-Makina ……….. tarihinde ALICI’ya teslim edilecektir.
          SATICI’nın teslimdeki 1 aylık gecikmesinin, olağan olduğunu ALICI
          baştan kabul etmektedir. ALICI’nın; en geç …………. tarihine kadar
          makinayı teslim almaması halinde, ALICI ; makina bedelini bu tarihten
          itibaren işleyecek ticari temerrüt faizi ile tazmin etmekle yükümlü
          olacağını kabul ve taahhüt eder.
          <br />
          5-ALICI; makina’nın teslimi anında makinanın başında fotoğraflarının
          çekilmesini ve bunun SATICI’ya ait sosyal medya hesaplarında ad-soyad
          ve il bilgisi ile paylaşılmasını açık rızası ile kabul etmiş olup,
          bunun kişisel veri ihlali olmadığını ve olmayacağını kabul ve beyan
          etmiştir.
          <br /> 6-SATICI; 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun
          10.maddesi gereği Aydınlatma Metnini ALICI’ya arz etmektedir.
          SATICI’nın veri sorumlusu Agrotek İlaçlama Makinaları Ve Tarım
          Tek.İth.İhr.Zirai Ürünler San.Tic. Ltd. Şti. olup, aşağıdaki kaşe ve
          imza bölümünde adres, vergi dairesi ve numarası, mersis numarası ve
          telefon ve fax numaraları bulunmaktadır. SATICI ; işbu sözleşme
          kapsamında ALICI’nın ad ve soyadını, ünvanını, T.C. Kimlik Numarasını,
          adres bilgisini, vergi kimlik numarasını ve fotoğrafını işleyecektir.
          İşbu kişisel veriler SATICI tarafından faturalandırma, ürün teslimi,
          eğitim hizmet takibi,garanti takibi, ürün takibi, reklam ve tanıtım
          sebepleri ile işlenecektir. Kişisel veri işleme süresi 10 senedir.
          Kişisel Verilerden ALICI’nın adı-soyadı, bulunduğu il ve makina ile
          çekilmiş fotoğrafları SATICI’nın sosyal medya hesaplarında
          paylaşılacaktır. ALICININ ad ve soyad, ünvan, T.C. Kimlik Numarası,
          adres bilgisi, vergi kimlik numarası gibi kişisel verileri
          faturalandırma süreci ile ilgili olarak, Vergi Dairesi, Tarım İl
          Müdürlüğü, Sanayi ve Ticaret Bakanlığı, Hazine ve Maliye Bakanlığı ve
          sair resmi kurumlarla paylaşılmaktadır. 6698 sayılı Kanun’un
          11.maddesi uyarınca SATICI; ALICI’yı “Kişisel verilerinin işlenip
          işlenmediğini öğrenme,kişisel verileri işlenmişse buna ilişkin bilgi
          talep etme, kişisel verilerinin işlenme amacını ve bunların amacına
          uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt
          dışında kişisel verilerinin aktarıldığı üçüncü kişileri bilme, kişisel
          verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesini isteme, kişisel verilerin silinmesini veya yok
          edilmesini isteme, kişisel verilerin düzeltilmesi, silinmesi veya yok
          edilmesine ilişkin işlemlerin kişisel verilerin aktarıldığı üçüncü
          kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik
          sistemler vasıtasıyla analiz edilmesi suretiyle aleyhine bir sonucun
          ortaya çıkmasına itiraz etme,kişisel verilerinin kanuna aykırı olarak
          işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini
          talep etme” haklarına sahip olduğu konusunda açıkça bilgilendirmiştir.
        </span>
      </div>
      <div
        style={{
          padding: "1rem",
          paddingBottom: 0,
          display: "flex",
          gap: "24rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: "bold",
          }}
        >
          <span>FATURA NO :</span>
          <span>İRSALİYE NO :</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: "bold",
          }}
        >
          <span>SEVK TARİHİ :</span>
          <span>ARAÇ BİLGİSİ :</span>
        </div>
      </div>
      <div
        style={{
          padding: "1rem",
          marginTop: "1rem",
          display: "flex",
          gap: "3rem",
        }}
      >
        <span
          style={{
            border: "1px solid black",
            padding: "4px",
            height: "20px",
            whiteSpace: "nowrap",
          }}
        >
          SERİ NO: {item.serial_number}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-1.2rem",
          }}
        >
          <span>SATICI KAŞE / İMZA</span>
          <textarea cols="25" rows="5"></textarea>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-1.2rem",
          }}
        >
          <span>ALICI AD-SOYAD / İMZA</span>
          <textarea cols="25" rows="5"></textarea>
        </div>
      </div>

      <span
        style={{
          marginLeft: "1cm",
          textDecoration: "underline",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        }}
      >
        SATIŞA KONU MAKİNE KONFİGRASYONU
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "10%",
        }}
      >
        <div>
          <ul>
            {Object.entries(data).map(([key, value]) => (
              <li key={key}>
                <strong>{key}:</strong> {value}
              </li>
            ))}
          </ul>
        </div>

        <span
          style={{
            border: "1px solid black",
            width: "400px",
            height: "200px",
            padding: "4px",
          }}
        >
          NOTLAR:
        </span>
      </div>
      <div style={{ paddingLeft: "0.5cm" }}>
        <span>
          Siparişinizi <strong>takip.covisart.com.tr</strong> adresinden{" "}
          <strong>{item.customer}</strong> müşteri adı ve{" "}
          <strong>{item.order_no}</strong> sipariş numarası ile takip
          edebilirsiniz.
        </span>
      </div>
      {/* <div
        style={{
          padding: "1rem",
          marginTop: "1rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <span>MODEL :</span>
          <span>BAĞLANTI TİPİ :</span>
          <span>TANK CİNSİ :</span>
          <span>TANK RENGİ :</span>
          <span>TANK İÇİ YIKAMA :</span>
          <span>ÇEKİ TİPİ :</span>
          <span>POMPA :</span>
          <span>KUMANDA :</span>
          <span>ENJEKTÖR :</span>
          <span>FAN TİPİ :</span>
          <span>PERVANE ÇAPI CM :</span>
          <span>PERVANE TİPİ :</span>
          <span>ŞASİ RENGİ :</span>
          <span>EK ÖZELLİKLER :</span>
        </div>
        <div
          style={{
            marginLeft: "5rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>FERRARİ</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AGROTEK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>MEK.TOZLAYICI</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>HERB.ATAÇ.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>ASILIR</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ÇEKİLİR</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>POLYESTER</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>POLYETİLEN</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>KB</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>MB</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YB</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KG</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KM</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KY</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>SG</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>VAR</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YOK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>PEHLİVAN</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>DAYAMALI</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>DÜZ ÇEKİ</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>AR 1064</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AR 1203</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AR 1604</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BP 165</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BHA 170</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BHA 200</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BHS 200</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AGR 160</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>BY MATIC 50</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>M170</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YERLİ BY MATIC</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YERLİ M170</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>VDR50</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BRAGIA SEL.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AGR.SEL.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>M 62</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>M 82</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YERLİ BŞK.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YERLİ ÇVK.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>AYR.TF.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ŞANZ.TF.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TF.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>MK.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KTF.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TKF.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BAĞ.M.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ESP.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AV.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>72</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>82</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>93</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>ALÜM. KAVR.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BAŞAK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ALM.G.PLASTİK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>BOYA</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>GALV.DALD.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>KOMPLE MEME</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ÜST YÖL.MEMESİZ.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ÜST YÖNL.MEMELİ.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ALT.İLAÇLAMA.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ÜST İLAÇLAMA.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TOZ İLAÇ ERT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default FanInvoice;
