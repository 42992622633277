/* eslint-disable eqeqeq */
import React from "react";
import ReactDomServer from "react-dom/server";

import { Table, Input, Button, Divider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GetFinishedOrdersForDealers } from "../../services/OrderService";
import { renderToString } from "react-dom/server";

import {
  Capitalize,
  ConvertDateTime,
  ConvertOrderLocation,
} from "../../helper";
import OrderHistory from "../../components/OrderHistory";
import { connect } from "react-redux";
import axios from "axios";
import ExcelJS from "exceljs";

import "dayjs/locale/tr";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Link } from "react-router-dom";
import ProformaInvoice from "../Invoice/ProformaInvoice";
import FanInvoice from "../Invoice/FanInvoice";

dayjs.extend(customParseFormat);

dayjs.locale("tr");

const QRCode = require("qrcode.react");

export function renderProperties(key, value) {
  if (value) {
    return (
      <div style={{ display: "flex" }}>
        <p style={{ fontWeight: "bold", marginRight: "5px" }}>{key}:</p>
        <p>{value}</p>
      </div>
    );
  }
}

class CompletedOrderScreenForDealers extends React.Component {
  constructor(props) {
    super(props);
    this.renderOrderDetails = this.renderOrderDetails.bind(this);
  }
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  renderOrderDetails(record) {
    return Object.keys(this.state.orderDetails).map((detail) => {
      return detail == record.id
        ? this.state.orderDetails[detail].map((detail) => {
            return (
              <>
                <div
                  style={{
                    width: "80%",
                    marginTop: 30,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {renderProperties("TCKN No", record.tckn)}
                    {renderProperties(
                      "Seri No",
                      Capitalize(detail.serial_number)
                    )}
                    {renderProperties(
                      "Pompa Seri No",
                      Capitalize(detail.pump_serial)
                    )}
                    {renderProperties(
                      "Bulunduğu Konum",
                      ConvertOrderLocation(detail.location) +
                        (detail.comment ? ` (${detail.comment})` : " ")
                    )}
                    {renderProperties(
                      "Son İşlem Tarihi",
                      ConvertDateTime(detail.row_created_date)
                    )}
                    {renderProperties(
                      "Planlama Tarihi",
                      ConvertDateTime(detail.planned_date)
                    )}
                  </div>
                  <div style={{ width: "min-content" }}>
                    {detail.location !== "teslimat" &&
                    detail.location !== "iptal" &&
                    this.props.user.role == "admin" ? (
                      <Button
                        style={{ marginBottom: 10, width: "100%" }}
                        type="primary"
                        onClick={() => {
                          this.setState({
                            orderModalVisible: true,
                            orderSelected: {
                              serial_number: detail.serial_number,
                              order_id: record.id,
                            },
                          });
                        }}
                      >
                        Siparişi Kaydır
                      </Button>
                    ) : null}

                    <Button
                      style={{ marginBottom: 10, width: "100%" }}
                      onClick={() => {
                        let qrCodeValue =
                          detail.serial_number + "," + record.description;
                        this.printQRCode(qrCodeValue, detail.serial_number);
                      }}
                    >
                      Qr Kodu Yazdır
                    </Button>
                    <Button
                      style={{ marginBottom: 10, width: "100%" }}
                      onClick={() => {
                        this.setState({
                          historyModalVisible: true,
                          historyModalId: detail.id,
                          orderSelected: {
                            serial_number: detail.serial_number,
                            order_id: record.id,
                          },
                        });
                      }}
                    >
                      İşlem Geçmişini Göster
                    </Button>
                    {detail.test_comment ? (
                      <Button
                        block
                        onClick={() => {
                          axios
                            .get("./finalkontrolformu.xlsx", {
                              responseType: "blob",
                            })
                            .then((res) => {
                              const FileSaver = require("file-saver");

                              var reader = new FileReader();
                              reader.readAsArrayBuffer(res.data);
                              reader.onload = function (e) {
                                const workbook = new ExcelJS.Workbook();
                                workbook.xlsx.load(e.target.result).then(() => {
                                  var worksheet = workbook.getWorksheet(1);
                                  var row = worksheet.getRow(58);
                                  row.getCell(1).value = detail.test_comment; // A5's value set to 5
                                  row.commit();

                                  var row2 = worksheet.getRow(8);
                                  row2.getCell(1).value = detail.serial_number;
                                  row2.getCell(5).value = detail.test_manager; // A5's value set to 5
                                  row2.getCell(6).value = ConvertDateTime(
                                    detail.test_date
                                  ); // A5's value set to 5
                                  row2.commit();

                                  workbook.xlsx.writeBuffer().then((buffer) => {
                                    const blob = new Blob([buffer], {
                                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                                    });

                                    FileSaver.saveAs(blob, "deneme.xlsx");
                                  });
                                });
                              };
                            });
                        }}
                      >
                        Test Raporu
                      </Button>
                    ) : null}
                  </div>
                </div>
                <Divider />
              </>
            );
          })
        : null;
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onChange = (checked) => {
    this.setState({ keepMachineDetails: checked ? true : false });
  };

  state = {
    movableMachines: [],
    orderModalVisible: false,
    keepMachineDetails: false,
    csvdata: [
      [
        "Seri No",
        "Sipariş No",
        "Sipariş Tarihi",
        "Müşteri",
        "Telefon",
        "Adres",
        "Sipariş Alan",
        "Durum",
      ],
    ],
    orderDetails: {},
    loading: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a, b) => a.order_no.localeCompare(b.order_no),
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Müşteri",
        dataIndex: "customer",
        sorter: (a, b) => a.customer.localeCompare(b.customer),
        ...this.getColumnSearchProps("customer"),
      },
      {
        title: "Telefon No",
        dataIndex: "phone_number",
        ...this.getColumnSearchProps("phone_number"),
      },
      {
        title: "Lokasyon",
        dataIndex: "address",
        ...this.getColumnSearchProps("address"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Ürün Tipi",
        dataIndex: "production_unit",
        sorter: (a, b) => a.production_unit.localeCompare(b.production_unit),
        ...this.getColumnSearchProps("production_unit"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Miktar",
        sorter: (a, b) => a.pending_amount - b.pending_amount,
        ...this.getColumnSearchProps("order_amount"),
        render: (text, order) => (
          <td style={{ cursor: "pointer" }} onClick={() => {}}>
            {String(order.order_amount - order.pending_amount) +
              "/" +
              order.order_amount}
          </td>
        ),
      },
      {
        title: "Sipariş Tarihi",
        dataIndex: "date",
        //render: (text: string) => <td>{ConvertDateTime(text)}</td>,
        sorter: (a, b) =>
          dayjs(a.date, "D MMMM YYYY, HH:mm").unix() -
          dayjs(b.date, "D MMMM YYYY, HH:mm").unix(),
        ...this.getColumnSearchProps("date"),
      },

      {
        title: "Sipariş Alan",
        dataIndex: "order_tooker",
        sorter: (a, b) => a.order_tooker.localeCompare(b.order_tooker),
        ...this.getColumnSearchProps("order_tooker"),
        render: (text) => <td>{text.toUpperCase()}</td>,
      },
      {
        title: "Sipariş Durumu",
        dataIndex: "laststatus",
        sorter: (a, b) => a.laststatus.localeCompare(b.laststatus),
        ...this.getColumnSearchProps("laststatus"),
        render: (text, column) => (
          <td>
            {column.status === "pendingfinance"
              ? "FİNANS ONAYI BEKLİYOR"
              : text?.toUpperCase()}
          </td>
        ),
      },
      {
        title: "Planlanan Tarih",
        dataIndex: "planned_date",
        sorter: (a, b) =>
          dayjs(a.planned_date, "D MMMM YYYY, HH:mm").unix() -
          dayjs(b.planned_date, "D MMMM YYYY, HH:mm").unix(),
        ...this.getColumnSearchProps("planned_date"),
        render: (text) => (
          <td>{text ? ConvertDateTime(text, "D MMMM YYYY") : "Planlanmadı"}</td>
        ),
      },
    ],
  };

  printQRCode(value, serialNumber) {
    let printWindow = window.open("", "", "height=1024, width=1024");

    let svg = renderToString(
      <div style={{ width: "50mm", height: "64mm" }}>
        <QRCode
          value={value}
          style={{ height: "44mm", width: "44mm", margin: "3mm" }}
          renderAs="svg"
        />
        <p style={{ margin: 10 }}>{serialNumber}</p>
      </div>
    );
    printWindow?.document.write(String(svg));

    printWindow.document.body.style.margin = 0;
    printWindow?.document.close();
    printWindow?.print();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isFinishedOrders !== prevProps.isFinishedOrders ||
      this.props.isCancelledOrders !== prevProps.isCancelledOrders
    ) {
      this.GetOrders();
    }
  }

  GetOrders() {
    GetFinishedOrdersForDealers().then((result) => {
      if (result) {
        let dateModifiedData = result?.map((order) => {
          order.date = dayjs(order.date).format("D MMMM YYYY, HH:mm");
          return order;
        });
        this.setState({ data: dateModifiedData });
      }
    });
  }

  componentDidMount = () => {
    this.GetOrders();
  };
  searchInput;

  render() {
    const state = this.state;

    return (
      <div
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Table
          rowKey={(row) => "key" + row.id}
          size="small"
          loading={this.state.loading}
          expandable={{
            expandedRowRender: (record) => (
              <div
                key={"keyexpandable " + record.id}
                style={{ marginLeft: "3rem" }}
              >
                {renderProperties("Ürün", record.description)}
                {renderProperties("Tarih", record.date)}
                {renderProperties(
                  "Marka",
                  this.configFile.brand.filter(
                    (b) => b.type === record.brand
                  )[0]?.text
                )}
                {renderProperties("Sipariş No", record.order_no)}
                {renderProperties(
                  "Sipariş Miktarı",
                  String(record.order_amount)
                )}
                {renderProperties(
                  "Tip",
                  this.configFile.type.filter((b) => b.type === record.type)[0]
                    ?.text
                    ? this.configFile.type.filter(
                        (b) => b.type === record.type
                      )[0]?.text
                    : this.configFile.machine_type.filter(
                        (b) => b.type === record.type
                      )[0]?.text
                )}
                {renderProperties(
                  "Fan Tipi",
                  this.configFile.cooler_type.filter(
                    (b) => b.type === record.cooler_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Kazan Boyutu",
                  record.tank_size ? record.tank_size + " LT" : null
                )}
                {renderProperties(
                  "Kazan Materyali",
                  this.configFile.caldron_material.filter(
                    (b) => b.type === record.caldron_material
                  )[0]?.text
                )}
                {renderProperties(
                  "Pompa",
                  this.configFile.pump.filter((b) => b.type === record.pump)[0]
                    ?.text
                )}
                {renderProperties("Model Yılı", record.year)}
                {renderProperties(
                  "Meme Tipi",
                  this.configFile.meme_type.filter(
                    (b) => b.type === record.meme_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Mixer Tipi",
                  this.configFile.mixer_type.filter(
                    (b) => b.type === record.mixer_type
                  )[0]?.text
                )}
                {renderProperties("Meme Aralığı", record.meme_distance)}
                {renderProperties(
                  "Çeki Tipi",
                  this.configFile.tow_type.filter(
                    (b) => b.type === record.tow_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Kol Tipi",
                  this.configFile.arm_type.filter(
                    (b) => b.type === record.arm_type
                  )[0]?.text
                )}
                {renderProperties("Kol Uzunluğu", record.arm_length)}
                {renderProperties(
                  "Şase Rengi",
                  this.configFile.chassis_color.filter(
                    (b) => b.type === record.chassis_color
                  )[0]?.text
                )}
                {renderProperties(
                  "Kazan Rengi",
                  Capitalize(record.caldron_color)
                )}
                {renderProperties(
                  "Kazan Tipi",
                  this.configFile.caldron_type.filter(
                    (b) => b.type === record.caldron_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Kumanda",
                  this.configFile.commander.filter(
                    (b) => b.type === record.commander
                  )[0]?.text
                )}
                {renderProperties("Alternatif Özellikler", record.alt_features)}
                {renderProperties(
                  "Ödeme Detayları",
                  record.payment_detail || "Ödeme Detayı Yok"
                )}
                {renderProperties(
                  "Fiyat",
                  record.price
                    ? record.price + record.currency
                    : "Fiyat Bilgisi Yok"
                )}

                <Button
                  style={{ marginRight: "10px" }}
                  type="primary"
                  onClick={() => {
                    let printWindow = window.open(
                      "",
                      "",
                      "height=1024, width=1024"
                    );

                    printWindow?.document.write(
                      ReactDomServer.renderToStaticMarkup(
                        <FanInvoice item={record} />
                      )
                    );

                    printWindow.document.head.appendChild(
                      document.createElement("style")
                    ).innerHTML =
                      "body {margin:0; padding:4px;} span {font-size: 12px;} .ant-table-cell {border-bottom: 1px solid black;} table {  border-collapse: collapse;} table, th, td {border: 1px solid; text-align: center;}";

                    printWindow.document.body.contentEditable = true;

                    printWindow?.document.close();
                  }}
                >
                  Satış Sözleşmesi
                </Button>

                <Button
                  style={{ marginRight: "10px" }}
                  type="primary"
                  onClick={() => {
                    let printWindow = window.open(
                      "",
                      "",
                      "height=1024, width=1024"
                    );

                    printWindow?.document.write(
                      ReactDomServer.renderToStaticMarkup(
                        <ProformaInvoice item={record} />
                      )
                    );

                    printWindow.document.head.appendChild(
                      document.createElement("style")
                    ).innerHTML =
                      "span {font-size: 12px;} .ant-table-cell {border-bottom: 1px solid black;} table {  border-collapse: collapse;} table, th, td {border: 1px solid; text-align: center;}";

                    printWindow.document.body.contentEditable = true;

                    printWindow?.document.close();
                  }}
                >
                  Proforma Fatura
                </Button>

                <Link
                  style={{
                    display:
                      record.order_amount !== record.pending_amount && "none",
                    color: "white",
                    marginBottom: 10,
                    backgroundColor: "#1677ff",
                    padding: "8px",
                    borderRadius: "6px",
                  }}
                  to={{
                    pathname: `/editOrder/${record.serial_number}`,
                  }}
                >
                  Düzenle
                </Link>
              </div>
            ),
          }}
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
        />

        {this.state.historyModalVisible ? (
          <OrderHistory
            isVisible={this.state.historyModalVisible}
            machine_id={this.state.historyModalId}
            serial_number={this.state.orderSelected?.serial_number}
            setIsVisible={(visible) =>
              this.setState({ historyModalVisible: visible })
            }
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CompletedOrderScreenForDealers);
