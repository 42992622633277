import React, { ReactNode } from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GenericOrder, GetDeliveryReport } from "../services/OrderService";
import OrderDetails from "../components/OrderDetails";
import { ConvertDateTime } from "../helper";

const moment = require("moment/min/moment-with-locales");
moment.locale("tr");

export default class DeliveryScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: ReactNode) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    csvdata: [["Seri No", "Teslim Tarihi", "Müşteri", "Telefon No", "Adres"]],
    orderDetails: {},
    loading: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Teslim Tarihi",
        dataIndex: "row_created_date",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          moment(a.row_created_date).unix() - moment(b.row_created_date).unix(),
        render: (text: string) => <td>{ConvertDateTime(text)}</td>,
      },
      {
        title: "Müşteri",
        dataIndex: "customer",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.customer.localeCompare(b.customer),
        ...this.getColumnSearchProps("customer"),
      },
      {
        title: "Telefon No",
        dataIndex: "phone_number",
        ...this.getColumnSearchProps("phone_number"),
      },
      {
        title: "Adres",
        dataIndex: "address",
        ...this.getColumnSearchProps("address"),
      },

      {
        title: "Açıklama",
        dataIndex: "comment",
        ...this.getColumnSearchProps("comment"),
      },
    ],
  };

  componentDidMount = async () => {
    const result = await GetDeliveryReport();

    this.setState({ data: result });
  };
  searchInput: React.ReactNode;

  render() {
    const state = this.state;

    return (
      <Table
        rowKey={(row) => "key" + row.serial_number}
        size="small"
        loading={this.state.loading}
        pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
        columns={this.state.columns}
        dataSource={state.data}
        expandable={{
          expandedRowRender: (record) => (
            <OrderDetails serial_number={record.serial_number} />
          ),
        }}
      />
    );
  }
}
