import { Button, Card, Form, Input, Popover, Table } from "antd";
import Search from "antd/lib/input/Search";
import IAgrotekConfig, {
  ISerializationEffectiveField,
  ISerializationUnEffectiveField,
} from "../IAgrotekConfig";
import { getConfig } from "../redux/actions/authActions";
import { addConfig, deleteConfig } from "../services/ConfigService";

interface Props {}
const configFile: IAgrotekConfig = JSON.parse(
  localStorage.getItem("config") ?? "{}"
);

const typeToString: { [key: string]: string } = {
  machine_type: "Makine Tipleri",
  brand: "Markalar",
  type: "Tipler",
  cooler_type: "Fan Tipleri",
  tank_size: "Depo Boyutları",
  pump: "Pompalar",
  tow_type: "Çeki Tipleri",
  chassis_color: "Şase Renkleri",
  caldron_type: "Kazan Tipleri",
  fan_type: "Pervane Tipleri",
  arm_type: "Kol Tipleri",
  fan_radius: "Fan Çapları",
  order_tooker: "Sipariş Alanlar",
  commander: "Kumandalar",
  turktraktormodel: "Türk Traktör Modelleri",
  meme_type: "Meme Tipleri",
  mixer_type: "Mixer Tipleri",
  caldron_material: "Kazan Materyalleri",
  role: "Kullanıcı Rolleri",
};

function MachineTypeConfigScreen(props: Props) {
  function renderTable() {
    return Object.keys(configFile).map((key) => {
      let configs = configFile[key];
      if (typeof configs == "object" && key != "role") {
        if (Object.keys(configs[0]).length === 2) {
          return renderDoubleColumnTable(
            configs as ISerializationUnEffectiveField[],
            key
          );
        } else if (Object.keys(configs[0]).length === 3) {
          return renderTripleColumnTable(
            configs as ISerializationEffectiveField[],
            key
          );
        }
      }
      return null;
    });
  }

  function renderDoubleColumnTable(
    config: ISerializationUnEffectiveField[],
    key: string
  ) {
    const columns = [
      {
        title: "Tip",
        dataIndex: "type",
        key: "type",
        width: 45,
      },
      {
        title: "Gösterim",
        dataIndex: "text",
        width: 45,
      },
      {
        title: "İşlemler",
        width: 10,
        render: (text: any) => (
          <td>
            <Button
              style={{ marginBottom: 10 }}
              danger
              type="primary"
              onClick={() => {
                deleteConfig({ ...text, key }).then((res) => {
                  if (res) getConfig();
                });
              }}
            >
              Sil
            </Button>
          </td>
        ),
      },
    ];
    return (
      <Card key={key} style={{ marginBottom: 10 }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>{typeToString[key]}</b>
              <Popover
                content={
                  <Form
                    layout="vertical"
                    onFinish={(values) => {
                      addConfig(String(key), values).then((res) => {
                        if (res) getConfig();
                      });
                    }}
                  >
                    <Form.Item
                      name="type"
                      label="Tip"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece küçük harf ve boşluksuz yazın.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="text" label="Gösterim">
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`${typeToString[key]}a Ekleme Yap`}
                trigger="click"
              >
                <Button type="primary" style={{ float: "right" }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={config?.sort((a: any, b: any) =>
            a.type.localeCompare(b.type)
          )}
          columns={columns}
          rowKey="type"
        />
      </Card>
    );
  }

  function renderTripleColumnTable(
    config: ISerializationEffectiveField[],
    key: string
  ) {
    const columns = [
      {
        title: "Tip",
        dataIndex: "type",
        key: "type",
        width: 30,
      },
      {
        title: "Gösterim",
        dataIndex: "text",
        width: 30,
      },
      {
        title: "Kısaltma (Barkod üzerinde)",
        dataIndex: "shortend",
        width: 30,
      },
      {
        title: "İşlemler",
        width: 10,
        render: (text: any) => (
          <td>
            <Button
              style={{ marginBottom: 10 }}
              danger
              type="primary"
              onClick={() => {
                deleteConfig({ ...text, key }).then((res) => {
                  if (res) getConfig();
                });
              }}
            >
              Sil
            </Button>
          </td>
        ),
      },
    ];
    return (
      <Card key={key} style={{ marginBottom: 10 }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>{typeToString[key]}</b>
              <Popover
                content={
                  <Form
                    layout="vertical"
                    onFinish={(values) => {
                      addConfig(String(key), values);
                    }}
                  >
                    <Form.Item
                      name="type"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece küçük harf ve boşluksuz yazın.",
                        },
                      ]}
                      label="Tip"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="text" label="Gösterim">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="shortend"
                      label="Kısaltma"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece büyük harf yazın, ve harf sayısına dikkat edin.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`${typeToString[key]}a Ekleme Yap`}
                trigger="click"
              >
                <Button type="primary" style={{ float: "right" }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={config?.sort((a: any, b: any) =>
            a.type.localeCompare(b.type)
          )}
          columns={columns}
          rowKey="type"
        />
      </Card>
    );
  }

  return (
    <>
      <div
        key="hello"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          key="uretimSuresiProgress"
          title="Üretim Süresi İşlemleri"
          style={{ width: "60%", marginBottom: "1%", marginTop: "2%" }}
        >
          <Search
            style={{ marginBottom: 10 }}
            addonBefore="Şase Üretim Süresi"
            placeholder={String(configFile?.chassis_production_time)}
            suffix="Gün"
            enterButton="Değiştir"
            size="large"
            onSearch={(value) => {
              addConfig("chassis_production_time", { text: value });
            }}
            key="saseUretim"
          />
          <Search
            style={{ marginBottom: 10 }}
            addonBefore="Fan Üretim Süresi"
            suffix="Gün"
            placeholder={String(configFile?.fan_production_time)}
            enterButton="Değiştir"
            size="large"
            onSearch={(value) => {
              addConfig("fan_production_time", { text: value });
            }}
            key="fanUretim"
          />
          <Search
            style={{ marginBottom: 10 }}
            addonBefore="Kol Üretim Süresi"
            placeholder={String(configFile?.arm_production_time)}
            suffix="Gün"
            enterButton="Değiştir"
            size="large"
            onSearch={(value) => {
              addConfig("arm_production_time", { text: value });
            }}
            key="kolUretim"
          />
        </Card>
        <Card
          key="configTable"
          style={{ width: "60%", marginBottom: "1%", marginTop: "2%" }}
          title=""
        >
          {renderTable()}
        </Card>
      </div>
    </>
  );
}

export default MachineTypeConfigScreen;
