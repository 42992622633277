import { useSelector } from "react-redux";
import { RootState } from "./redux/reducers/index";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import MainScreen from "./screens/MainScreen";
import "antd/dist/antd-with-locales.min";
import LoginScreen from "./screens/LoginScreen";
import { getConfig } from "./redux/actions/authActions";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import { ConfigProvider, theme } from "antd";
import { useEffect } from "react";

function App() {
  getConfig();
  const user = useSelector((state: RootState) => state.auth.user);
  //const [theme, setTheme] = useState<MenuTheme>('dark');

  useEffect(() => {
    if (
      !user &&
      !(
        window.location.pathname === "/login" ||
        window.location.pathname === "/forgotPassword"
      )
    ) {
      window.location.href = "/login";
    }
  }, [user]);

  return (
    <BrowserRouter forceRefresh>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Switch>
          <Route path="/forgotPassword">
            <ForgotPasswordScreen />
          </Route>
          <Route path="/login">
            <LoginScreen />
          </Route>
          <Route path="/">{user && <MainScreen />}</Route>
        </Switch>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
