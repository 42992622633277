/* eslint-disable eqeqeq */
import React from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Capitalize, ConvertDateTime } from "../helper";
import OrderDetails from "../components/OrderDetails";
import { sendTo, GetProductionReadyOrders } from "../services/OrderService";
import { connect } from "react-redux";

const moment = require("moment/min/moment-with-locales");
moment.locale("tr");

class PendingOrdersScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    orderModalVisible: false,
    orderDetails: {},
    loading: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a, b) => a.order_no.localeCompare(b.order_no),
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Müşteri",
        dataIndex: "customer",
        sorter: (a, b) => a.customer.localeCompare(b.customer),
        ...this.getColumnSearchProps("customer"),
      },
      {
        title: "Telefon No",
        dataIndex: "phone_number",
        ...this.getColumnSearchProps("phone_number"),
      },
      {
        title: "Adres",
        dataIndex: "address",
        ...this.getColumnSearchProps("address"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Ürün Tipi",
        dataIndex: "production_unit",
        render: (text) => <td>{Capitalize(text)}</td>,
        sorter: (a, b) => a.production_unit.localeCompare(b.production_unit),
      },

      {
        title: "Sipariş Tarihi",
        dataIndex: "last_location_date",
        render: (text) => <td>{ConvertDateTime(text)}</td>,
        sorter: (a, b) =>
          moment(a.last_location_date).unix() -
          moment(b.last_location_date).unix(),
      },
      {
        title: "İşlemler",
        render: (text, order) => (
          <td>
            {this.props.user.role == "admin" ? (
              <Button
                disabled={
                  order.production_unit == "diger"
                    ? false
                    : !order.can_start_production
                }
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  sendTo(order.production_unit, order.id).then((res) => {
                    if (res) {
                      this.GetPendingOrdersData();
                    }
                  });
                }}
              >
                Üretime Başla
              </Button>
            ) : null}
          </td>
        ),
        sorter: (a, b) =>
          (a.production_unit == "diger" ? false : !a.can_start_production) -
          (b.production_unit == "diger" ? false : !b.can_start_production),
      },
    ],
  };

  componentDidMount = () => {
    this.GetPendingOrdersData();
  };

  GetPendingOrdersData = () => {
    GetProductionReadyOrders().then((result) => {
      this.setState({ data: result });
    });
  };

  searchInput;

  render() {
    const state = this.state;

    return (
      <Table
        rowKey={(row) => "key" + row.id}
        size="small"
        loading={state.loading}
        pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
        columns={state.columns}
        dataSource={state.data}
        expandable={{
          expandedRowRender: (record) => (
            <OrderDetails serial_number={record.serial_number} />
          ),
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PendingOrdersScreen);
