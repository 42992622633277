import { Spin, Timeline, Popconfirm, Button, Card } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConvertDateTime, ConvertOrderLocation } from "../helper";
import { RootState } from "../redux/reducers";
import {
  GetArmHistory,
  GetChassisHistory,
  GetFanHistory,
  GetFinancialApproveHistory,
  GetOrderHistory,
  UndoSendTo,
} from "../services/OrderService";

interface Props {
  isVisible: boolean;
  machine_id: number;
  serial_number: string;
  setIsVisible: (bool: boolean) => void;
}

enum PartTypes {
  arm,
  fan,
  chassis,
}

function MachinePartsHistory(props: { machine_id: number; type: PartTypes }) {
  const { machine_id, type } = props;
  const [data, setData] = useState(null as unknown as []);
  const [lastLocation, setLastLocation] = useState(null);

  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    GetHistory();
  }, [machine_id]);

  function GetHistory() {
    switch (type) {
      case PartTypes.arm:
        GetArmHistory(machine_id).then((res) => {
          if (res.length > 0) {
            setData(res);
            setLastLocation(
              res?.reduce((a, b) =>
                a.row_created_date > b.row_created_date ? a : b
              )
            );
          }
        });
        break;
      case PartTypes.fan:
        GetFanHistory(machine_id).then((res) => {
          if (res.length > 0) {
            setData(res);
            setLastLocation(
              res?.reduce((a, b) =>
                a.row_created_date > b.row_created_date ? a : b
              )
            );
          }
        });
        break;
      case PartTypes.chassis:
        GetChassisHistory(machine_id).then((res) => {
          if (res.length > 0) {
            setData(res);
            setLastLocation(
              res?.reduce((a, b) =>
                a.row_created_date > b.row_created_date ? a : b
              )
            );
          }
        });
        break;
    }
  }

  function renderTimeline() {
    return data?.map((timeline: any) => {
      return (
        <Timeline.Item
          key={"timelinekey" + timeline.row_created_date}
          label={ConvertDateTime(timeline.row_created_date)}
        >
          <p>
            <b>{ConvertOrderLocation(timeline.location)}</b>
          </p>
          <p>{`Gönderen: ${timeline.name} ${timeline.surname}`}</p>
        </Timeline.Item>
      );
    });
  }

  return data ? (
    <Card
      style={{ marginBottom: 5 }}
      title={
        type === PartTypes.arm
          ? "Kol İşlem Geçmişi"
          : type === PartTypes.chassis
          ? "Şase İşlem Geçmişi"
          : "Fan İşlem Geçmişi"
      }
    >
      <Timeline mode="left" style={{ width: "100%" }}>
        {renderTimeline()}
      </Timeline>
    </Card>
  ) : null;
}

function OrderHistory(props: Props) {
  const { isVisible, setIsVisible, machine_id, serial_number } = props;
  const [data, setData] = useState(null as unknown as []);
  const [lastLocation, setLastLocation] = useState(null);
  const [financialApproveDate, setFinancialApproveDate] = useState() as any;
  const [generalManagerApproveDate, setGeneralManagerApproveDate] =
    useState() as any;

  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    GetHistory();
  }, [machine_id]);

  function GetHistory() {
    GetFinancialApproveHistory(machine_id).then((res) => {
      setGeneralManagerApproveDate(
        res.filter((item: any) => item?.approval_role === "generalmanager")
      );
      setFinancialApproveDate(
        res.filter((item: any) => item?.approval_role === "financemanager")
      );
    });
    GetOrderHistory(machine_id).then((res) => {
      if (res) {
        setData(res);
        setLastLocation(
          res?.reduce((a: any, b: any) =>
            a.row_created_date > b.row_created_date ? a : b
          )
        );
      }
    });
  }

  function renderTimeline() {
    return data?.map((timeline: any) => {
      return (
        <Timeline.Item
          key={"timelinekey" + timeline.row_created_date}
          label={ConvertDateTime(timeline.row_created_date)}
        >
          <p>
            <b>{ConvertOrderLocation(timeline.location)}</b>
          </p>
          <p>{`Gönderen: ${timeline.name} ${timeline.surname}`}</p>
        </Timeline.Item>
      );
    });
  }

  return (
    <Modal
      title={serial_number + " Numaralı Makinenin İşlem Geçmişi"}
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem 0px",
              }}
            >
              <span>Finans Onayı Verilme Tarihi</span>
              <span>
                {financialApproveDate?.[0]?.row_created_date
                  ? ConvertDateTime(financialApproveDate?.[0]?.row_created_date)
                  : "-"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1rem",
              }}
            >
              <span>Genel Müdür Onayı Verilme Tarihi</span>
              <span>
                {generalManagerApproveDate?.[0]?.row_created_date
                  ? ConvertDateTime(
                      generalManagerApproveDate?.[0]?.row_created_date
                    )
                  : "-"}
              </span>
            </div>
            <Card title="Makine İşlem Geçmişi" style={{ marginBottom: 5 }}>
              <Timeline mode="left" style={{ width: "100%" }}>
                {renderTimeline()}
              </Timeline>
            </Card>
            <MachinePartsHistory
              machine_id={machine_id}
              type={PartTypes.chassis}
            />
            <MachinePartsHistory machine_id={machine_id} type={PartTypes.fan} />
            <MachinePartsHistory machine_id={machine_id} type={PartTypes.arm} />
            {user.role === "admin" &&
            lastLocation?.location !== "onaybekliyor" &&
            lastLocation?.location !== "iptal" ? (
              <Popconfirm
                title="Bu siparişi bir önceki konuma göndermek istediğinizden emin misiniz?"
                onConfirm={() => {
                  UndoSendTo(machine_id).then((res) => {
                    if (res) {
                      GetHistory();
                    }
                  });
                }}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button style={{ marginBottom: 10 }} type="primary">
                  Bir Önceki Konuma Geri Gönder
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </Modal>
  );
}

export default OrderHistory;
