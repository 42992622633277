/* eslint-disable eqeqeq */
import React from "react";
import { Row, Col, Spin, Layout, DatePicker } from "antd";
import { GetReports, GetReportsWithDate } from "../services/ReportService";
import ReportSquare from "../components/ReportSquare";
import ExportCSV from "../components/ExportCSV";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
const moment = require("moment/min/moment-with-locales");
moment.locale("tr");

interface ReportModel {
  toplam_siparis: number;
  turbo_siparis: number;
  kollu_siparis: number;
  diger_siparis: number;
  kollugereken: number;
  turbogereken: number;
  digergereken: number;
  kolluplanlanan: number;
  turboplanlanan: number;
  digerplanlanan: number;
  turbo_bugun_yapilan_is: [string];
  kollu_bugun_yapilan_is: [string];
  diger_bugun_yapilan_is: [string];
  test_bugun_yapilan_is: [string];
  teslimat_bugun_yapilan_is: [string];
  stocks: { [key: string]: number };
}

class StatisticsScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");
  state = {
    reports: null as unknown as ReportModel,
    selectedDate: moment().format("YYYY-MM-DD"),
  };

  searchInput: React.ReactNode;

  componentDidMount() {
    GetReportsWithDate(this.state.selectedDate).then((res) => {
      if (res) {
        res.test_bugun_yapilan_is = this.uniq(res.test_bugun_yapilan_is);
        res.turbo_bugun_yapilan_is = this.uniq(res.turbo_bugun_yapilan_is);
        res.diger_bugun_yapilan_is = this.uniq(res.diger_bugun_yapilan_is);
        res.teslimat_bugun_yapilan_is = this.uniq(
          res.teslimat_bugun_yapilan_is
        );
        res.kollu_bugun_yapilan_is = this.uniq(res.kollu_bugun_yapilan_is);
      }
      if (res) this.setState({ reports: res });
    });
  }

  uniq(a: Array<any>) {
    return Array.from(new Set(a));
  }

  handleDateChange = async (event: any) => {
    await this.setState({
      selectedDate: event.target.value,
      reports: {
        ...this.state.reports,
        test_bugun_yapilan_is: [],
        turbo_bugun_yapilan_is: [],
        diger_bugun_yapilan_is: [],
        teslimat_bugun_yapilan_is: [],
        kollu_bugun_yapilan_is: [],
      },
    });
    GetReportsWithDate(this.state.selectedDate).then((res) => {
      if (res) {
        res.test_bugun_yapilan_is = this.uniq(res.test_bugun_yapilan_is);
        res.turbo_bugun_yapilan_is = this.uniq(res.turbo_bugun_yapilan_is);
        res.diger_bugun_yapilan_is = this.uniq(res.diger_bugun_yapilan_is);
        res.teslimat_bugun_yapilan_is = this.uniq(
          res.teslimat_bugun_yapilan_is
        );
        res.kollu_bugun_yapilan_is = this.uniq(res.kollu_bugun_yapilan_is);
      }
      if (res) this.setState({ reports: res });
    });
  };

  renderReports() {
    const { reports } = this.state;
    return (
      <>
        <ReportSquare
          title="Toplam Onay Bekleyen Sipariş"
          data={reports?.toplam_siparis}
          color="#4A66C5"
        />
        <ReportSquare
          title="Kollu Onay Bekleyen Sipariş"
          data={reports?.kollu_siparis}
          color="#898C94"
        />
        <ReportSquare
          title="Kollu Üretimde"
          data={reports?.kollugereken}
          color="#FF7800"
        />
        <ReportSquare
          title="Kollu Planlanan"
          data={reports?.kolluplanlanan}
          color="#EE204D"
        />
        <ReportSquare
          title="Turbo Onay Bekleyen Sipariş"
          data={reports?.turbo_siparis}
          color="#9C459E"
        />
        <ReportSquare
          title="Turbo Üretimde"
          data={reports?.turbogereken}
          color="#8EBF00"
        />
        <ReportSquare
          title="Turbo Planlanan"
          data={reports?.turboplanlanan}
          color="#4A66C5"
        />
      </>
    );
  }

  renderStocks() {
    const { reports } = this.state;

    return (
      <>
        {Object.keys(reports?.stocks).length > 0 ? (
          <>
            <ReportSquare
              title="Diğer Onay Bekleyen Sipariş"
              data={reports?.diger_siparis}
              color="#00C5CD"
            />
            <ReportSquare
              title="Diğer Üretimde"
              data={reports?.digergereken}
              color="#499370"
            />
            <ReportSquare
              title="Diğer Planlanan"
              data={reports?.digerplanlanan}
              color="#8EBF00"
            />
            <ReportSquare
              title="Bahçedeki Turbo Makinalar"
              data={
                reports?.stocks["bahce turbo"]
                  ? reports?.stocks["bahce turbo"]
                  : 0
              }
              color="#B4674D"
            />
            <ReportSquare
              title="Depodaki Turbo Makinalar"
              data={
                reports?.stocks["depo turbo"]
                  ? reports?.stocks["depo turbo"]
                  : 0
              }
              color="#EE204D"
            />
            <ReportSquare
              title="Bahçedeki Kollu Makinalar"
              data={
                reports?.stocks["bahce kollu"]
                  ? reports?.stocks["bahce kollu"]
                  : 0
              }
              color="#1A4876"
            />
            <ReportSquare
              title="Depodaki Kollu Makinalar"
              data={
                reports?.stocks["depo kollu"]
                  ? reports?.stocks["bahce kollu"]
                  : 0
              }
              color="#8EE53F"
            />
            <ReportSquare
              title="Bahçedeki Diğer Makinalar"
              data={
                reports?.stocks["bahce diger"]
                  ? reports?.stocks["bahce diger"]
                  : 0
              }
              color="#FF7518"
            />
            <ReportSquare
              title="Depodaki Diğer Makinalar"
              data={
                reports?.stocks["depo diger"]
                  ? reports?.stocks["depo diger"]
                  : 0
              }
              color="#00C5CD"
            />
          </>
        ) : (
          <Spin />
        )}
      </>
    );
  }

  renderDailyCompletedTasks() {
    return (
      <div
        style={{
          boxSizing: "border-box",
          overflow: "auto",
          maxWidth: "100%",
          margin: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            width: "100%",
            backgroundColor: "#1A4876",
            padding: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <input
              id="datepicker-input"
              style={{
                border: "1px solid white",
                // backgroundColor: "transparent",
                color: "#1A4876",
                borderRadius: 10,
                padding: "0.4rem",
                marginTop: "1rem",
                outline: "none",
              }}
              type="date"
              value={this.state.selectedDate}
              onChange={this.handleDateChange}
            />
            <Title level={3} style={{ color: "white" }}>
              Yapılan İşler
            </Title>
          </div>
          <Row gutter={[0, 24]}>
            <Col xs={24} sm={12} md={12} style={{ alignItems: "center" }}>
              <Title
                level={5}
                style={{ margin: 0, color: "white", textAlign: "center" }}
              >
                Turbo Üretilen Makineler
              </Title>
              {(() => {
                return this.state?.reports?.turbo_bugun_yapilan_is.map(
                  (serial_number: string) => {
                    return (
                      <Row key={"turborowkey" + serial_number}>
                        <Text
                          code
                          style={{ margin: 0, width: "100%", color: "white" }}
                        >
                          {serial_number}
                        </Text>
                      </Row>
                    );
                  }
                );
              })()}
            </Col>
            <Col xs={24} sm={12} md={12} style={{ alignItems: "center" }}>
              <Title
                level={5}
                style={{ margin: 0, color: "white", textAlign: "center" }}
              >
                Kollu Üretilen Makineler
              </Title>
              {(() => {
                return this.state?.reports?.kollu_bugun_yapilan_is.map(
                  (serial_number: string) => {
                    return (
                      <Row key={"kollurowkey" + serial_number}>
                        <Text
                          code
                          style={{ margin: 0, width: "100%", color: "white" }}
                        >
                          {serial_number}
                        </Text>
                      </Row>
                    );
                  }
                );
              })()}
            </Col>
            <Col xs={24} sm={12} md={12} style={{ alignItems: "center" }}>
              <Title
                level={5}
                style={{ margin: 0, color: "white", textAlign: "center" }}
              >
                Diğer Üretilen Makineler
              </Title>
              {(() => {
                return this.state?.reports?.diger_bugun_yapilan_is.map(
                  (serial_number: string) => {
                    return (
                      <Row key={"digerrowkey" + serial_number}>
                        <Text
                          code
                          style={{ margin: 0, width: "100%", color: "white" }}
                        >
                          {serial_number}
                        </Text>
                      </Row>
                    );
                  }
                );
              })()}
            </Col>
            <Col xs={24} sm={12} md={12} style={{ alignItems: "center" }}>
              <Title
                level={5}
                style={{ margin: 0, color: "white", textAlign: "center" }}
              >
                Test Edilen Makineler
              </Title>
              {(() => {
                return this.state?.reports?.test_bugun_yapilan_is.map(
                  (serial_number: string) => {
                    return (
                      <Row key={"testrowkey" + serial_number}>
                        <Text
                          code
                          style={{ margin: 0, width: "100%", color: "white" }}
                        >
                          {serial_number}
                        </Text>
                      </Row>
                    );
                  }
                );
              })()}
            </Col>
            <Col xs={24} sm={12} md={12} style={{ alignItems: "center" }}>
              <Title
                level={5}
                style={{ margin: 0, color: "white", textAlign: "center" }}
              >
                Teslim Edilen Makineler
              </Title>
              {(() => {
                return this.state?.reports?.teslimat_bugun_yapilan_is.map(
                  (serial_number: string) => {
                    return (
                      <Row key={"teslimatrowkey" + serial_number}>
                        <Text
                          code
                          style={{ margin: 0, width: "100%", color: "white" }}
                        >
                          {serial_number}
                        </Text>
                      </Row>
                    );
                  }
                );
              })()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        {this.state.reports ? (
          <Row>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              {this.state.reports ? this.renderReports() : <Spin />}
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              {this.state.reports ? this.renderStocks() : <Spin />}
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {this.state.reports ? (
                  this.renderDailyCompletedTasks()
                ) : (
                  <Spin />
                )}
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <ExportCSV />
              </Col>
            </Col>
          </Row>
        ) : (
          <Spin style={{ alignSelf: "center" }} />
        )}
      </Layout>
    );
  }
}

export default StatisticsScreen;
