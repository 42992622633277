import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { Capitalize, ConvertDateTime } from "../helper";
import IAgrotekConfig from "../IAgrotekConfig";
import { renderProperties } from "../screens/OrderScreen";
import { GenericOrder, GetOrderDetails } from "../services/OrderService";
import OrderHistory from "./OrderHistory";

import "dayjs/locale/tr";
import dayjs from "dayjs";
dayjs.locale("tr");

interface Props {
  serial_number: string;
  data: any;
  externalDetails: any;
}

function OrderDetails(props: Props) {
  const configFile = JSON.parse(
    localStorage.getItem("config") ?? "{}"
  ) as IAgrotekConfig;
  const { serial_number } = props;
  const [record, setRecord] = useState(null as unknown as GenericOrder);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    GetOrderDetails(serial_number).then((res) => {
      if (res) {
        setRecord(res);
      }
    });
  }, []);

  return (
    <div style={{ marginLeft: "40px" }}>
      {props.externalDetails ? props.externalDetails : <></>}
      {record ? (
        <>
          {renderProperties("Ürün", record.description)}
          {renderProperties(
            "Son İşlem Tarihi",
            ConvertDateTime(record.last_location_date)
          )}
          {renderProperties(
            "Marka",
            configFile.brand.filter((b) => b.type === record.brand)[0]?.text
          )}
          {renderProperties("Sipariş No", record.order_no)}
          {renderProperties("Sipariş Miktarı", String(record.order_amount))}
          {renderProperties(
            "Tip",
            configFile.type.filter((b) => b.type === record.type)[0]?.text
              ? configFile.type.filter((b) => b.type === record.type)[0]?.text
              : configFile.machine_type.filter((b) => b.type === record.type)[0]
                  ?.text
          )}
          {renderProperties(
            "Fan Tipi",
            configFile.cooler_type.filter(
              (b) => b.type === record.cooler_type
            )[0]?.text
          )}
          {renderProperties("Kazan Boyutu", record.tank_size + " LT")}
          {renderProperties(
            "Pompa",
            configFile.pump.filter((b) => b.type === record.pump)[0]?.text
          )}
          {renderProperties("Pompa Seri No", record?.pump_serial)}
          {renderProperties("Model Yılı", record.year)}
          {renderProperties(
            "Meme Tipi",
            configFile.meme_type.filter((b) => b.type === record.meme_type)[0]
              ?.text
          )}
          {renderProperties(
            "Mixer Tipi",
            configFile.mixer_type.filter((b) => b.type === record.mixer_type)[0]
              ?.text
          )}
          {renderProperties("Meme Aralığı", record.meme_distance)}
          {renderProperties(
            "Çeki Tipi",
            configFile.tow_type.filter((b) => b.type === record.tow_type)[0]
              ?.text
          )}
          {renderProperties(
            "Kol Tipi",
            configFile.arm_type.filter((b) => b.type === record.arm_type)[0]
              ?.text
          )}
          {renderProperties("Kol Uzunluğu", record.arm_length)}
          {renderProperties(
            "Şase Rengi",
            configFile.chassis_color.filter(
              (b) => b.type === record.chassis_color
            )[0]?.text
          )}
          {renderProperties("Kazan Rengi", Capitalize(record.caldron_color))}
          {renderProperties(
            "Kazan Tipi",
            configFile.caldron_type.filter(
              (b) => b.type === record.caldron_type
            )[0]?.text
          )}
          {renderProperties(
            "Kumanda",
            configFile.commander.filter((b) => b.type === record.commander)[0]
              ?.text
          )}
          {renderProperties("Alternatif Özellikler", record.alt_features)}
          {!props.hideShowTransactions && (
            <Button type="primary" onClick={() => setIsVisible(true)}>
              İşlem Geçmişini Göster
            </Button>
          )}
          <OrderHistory
            serial_number={serial_number}
            machine_id={record.id}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
        </>
      ) : (
        <Spin style={{ margin: "25%" }} size="large" />
      )}
    </div>
  );
}

export default OrderDetails;
