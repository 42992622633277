/* eslint-disable eqeqeq */
import { UndoOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  Layout,
  Radio,
  Select,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import IAgrotekConfig from "../IAgrotekConfig";
import Cities from "../cities.json";
import Countries from "../countries.json";
import { padLeft } from "../helper";
import { getCustomerList, getOrderNumber } from "../services/ConfigService";
import { TakeOrder } from "../services/OrderService";
import { Body } from "./MainScreen";

const { Option, OptGroup } = Select;
const QRCode = require("qrcode.react");
const { Content } = Layout;

function CreateOrderScreen() {
  const configFile: IAgrotekConfig = JSON.parse(
    localStorage.getItem("config") ?? "{}"
  );
  const [isFetching, setIsFetching] = useState(false);
  const [orderNoCounter, setOrderNoCounter] = useState(0);
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [customerList, setCustomerList] = useState([] as any);
  const [productionUnit, setProductionUnit] = useState("turbo");
  const [payment_type, setPaymentType] = useState("normal");

  const [serialState, setSerialState] = useState({
    brand: "",
    type: "",
    cooler_type: "",
    mixer_type: "",
    currency: "",
    arm_type: "",
    tank_size: 0,
    pump: "",
    order_no: "",
    order_amount: 0,
    year: "",
    proforma_no: "",
  });

  let serialCode = SerialCodeGenerator();
  const [form] = Form.useForm();

  const createOrderNo = (pt: string) => {
    setIsFetching(true);
    getOrderNumber().then((res) => {
      setOrderNoCounter(15);
      setSerialState({
        ...serialState,
        order_no: String(pt === "normal" ? res.order_no : res.proforma_no),
      });

      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (orderNoCounter > 0) {
      setTimeout(() => {
        setOrderNoCounter(orderNoCounter - 1);
      }, 1000);
    }
  }, [orderNoCounter]);

  useEffect(() => {
    createOrderNo(payment_type);
    getCustomerList().then((res) => {
      setCustomerList(res);
    });
  }, []);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityForNonTR, setSelectedCityForNonTR] = useState("");

  const sortedCountries = Countries.sort((a: any, b: any) => {
    return a?.localeCompare(b);
  });
  const sortedCities = Cities.sort((a: any, b: any) => {
    return a?.localeCompare(b);
  });

  useEffect(() => {
    setSelectedCity("");
    setSelectedCityForNonTR("");
  }, [selectedCountry]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content>
        <Body
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap-reverse",
          }}
        >
          <Form
            form={form}
            onFieldsChange={() => {
              let product =
                form.getFieldValue("brand") == "turktraktor"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("model")
                  : form.getFieldValue("production_unit") == "turbo"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("tank_size") +
                    "LT " +
                    form.getFieldValue("cooler_type") +
                    form.getFieldValue("fan_radius") +
                    "cm " +
                    form.getFieldValue("fan_type")
                  : form.getFieldValue("production_unit") == "kollu"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("tank_size") +
                    "LT " +
                    form.getFieldValue("arm_type") +
                    " " +
                    form.getFieldValue("arm_length")
                  : form.getFieldValue("production_unit") == "diger"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("type")
                  : "";
              setQrCodeValue(serialCode + "," + product.toUpperCase());
              if (
                form.getFieldValue("model") &&
                form.getFieldValue("brand") == "turktraktor"
              ) {
                form.setFieldsValue({
                  caldron_color: form.getFieldValue("model"),
                });
              }
            }}
            onFinish={async (values) => {
              setIsFetching(true);
              setOrderNoCounter(0);
              values.order_no = serialState.order_no;
              if (payment_type == "ziraikredi") {
                values.proforma_no = serialState.proforma_no;
              }

              let address =
                values.address +
                " " +
                (values.city === undefined ? "" : `,${values.city},`) +
                " " +
                (values.cityForNonTR === undefined
                  ? ""
                  : `,${values.cityForNonTR},`) +
                " " +
                values.country;

              values.address = address;
              delete values.city;
              delete values.country;
              delete values.cityForNonTR;

              const result = await TakeOrder(values, serialCode);
            }}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: "calc(100% - 168px)", minWidth: "450px" }}
            requiredMark={false}
          >
            <Form.Item
              name="date"
              initialValue={moment()}
              label="Tarih"
              required
            >
              <DatePicker disabled />
            </Form.Item>

            <Form.Item
              name="customer"
              label="Müşteri Adı"
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                { max: 250, message: "Maksimum sınır aşıldı." },
              ]}
              required
            >
              <AutoComplete
                style={{ width: 200 }}
                options={customerList}
                placeholder="aramak için yazın."
                filterOption={(inputValue: any, option: any) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Form.Item
              name="tckn"
              label="TCKN"
              rules={[{ max: 11, message: "Maksimum sınır aşıldı." }]}
              required
            >
              <Input maxLength={11} />
            </Form.Item>
            <Form.Item
              name="address"
              label="Adres"
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                { max: 250, message: "Maksimum sınır aşıldı." },
              ]}
              required
            >
              <Input />
            </Form.Item>

            <Form.Item name="country" label="Ülke" required>
              <Select
                showSearch
                onSelect={(type: any) => {
                  setSelectedCountry(type);
                }}
              >
                {sortedCountries?.map((country: any, index: number) => {
                  return (
                    <Option key={index} value={country}>
                      {country}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {selectedCountry !== "Türkiye" && selectedCountry !== "" ? (
              <Form.Item name="cityForNonTR" label="Şehir">
                <TextArea
                  value={selectedCityForNonTR}
                  onChange={(e) => setSelectedCityForNonTR(e.target.value)}
                  style={{ maxWidth: "400px" }}
                  rows={1}
                />
              </Form.Item>
            ) : (
              <Form.Item name="city" label="Şehir">
                <Select
                  showSearch
                  value={selectedCity}
                  defaultValue={selectedCity}
                  onSelect={(type: any) => {
                    setSelectedCity(type);
                  }}
                >
                  {sortedCities?.map((city: any, index: number) => {
                    return (
                      <Option key={index} value={city}>
                        {city}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              name="phone_number"
              label="Telefon No"
              initialValue={"+90"}
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                {
                  max: 13,
                  min: 13,
                  message: "Telefon numarası 13 haneden oluşmalıdır.",
                },
              ]}
              required
            >
              <Input
                placeholder="Ülke alan koduyla birlikte girin.."
                maxLength={13}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="production_unit"
              label="Üretim Birimi"
              initialValue="turbo"
              required
            >
              <Radio.Group
                onChange={(e) => {
                  setProductionUnit(e.target.value);
                }}
              >
                <Radio value="turbo">Turbo</Radio>
                <Radio value="kollu">Kollu</Radio>
                <Radio value="diger">Diğer</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="brand" label="Marka" required>
              <Select
                onSelect={(type: any) => {
                  const brand = configFile.brand.filter(
                    (brand) => brand.type == type
                  );
                  setSerialState({ ...serialState, brand: brand[0].shortend });
                }}
              >
                {(function myFunction() {
                  return configFile.brand.map((brand) => {
                    return (
                      <Option key={brand.type} value={brand.type}>
                        {brand.text}
                      </Option>
                    );
                  });
                })()}
              </Select>
            </Form.Item>
            {productionUnit == "turbo" && serialState.brand == "T" ? (
              <Form.Item name="model" label="Model" required>
                <Select>
                  {(function myFunction() {
                    return configFile.turktraktormodel.map((brand) => {
                      return (
                        <Option key={brand.text} value={brand.text}>
                          {brand.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              </Form.Item>
            ) : null}

            {productionUnit == "turbo" || productionUnit === "kollu" ? (
              <Form.Item name="mixer_type" label="Mixer Tipi">
                <Select
                  onSelect={(type: any) => {
                    const mixer_type = configFile.mixer_type.filter(
                      (mixer_type) => mixer_type.type == type
                    );
                    setSerialState({
                      ...serialState,
                      mixer_type: mixer_type[0].shortend,
                    });
                  }}
                >
                  {(function myFunction() {
                    return configFile?.mixer_type?.map((mixer_type) => {
                      return (
                        <Option key={mixer_type.type} value={mixer_type.type}>
                          {mixer_type.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item name="type" label="Tip" required>
              {productionUnit === "turbo" || productionUnit === "kollu" ? (
                <Select
                  onSelect={(btype: any) => {
                    const type = configFile.type.filter(
                      (type) => type.type == btype
                    );
                    setSerialState({ ...serialState, type: type[0].shortend });
                  }}
                >
                  {(function myFunction() {
                    return configFile.type.map((type) => {
                      return (
                        <Option key={type.type} value={type.type}>
                          {type.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              ) : (
                <Select
                  onSelect={(btype: any) => {
                    const type = configFile.machine_type.filter(
                      (machine_type) => machine_type.type == btype
                    );
                    setSerialState({ ...serialState, type: type[0].shortend });
                  }}
                >
                  {(function myFunction() {
                    return configFile.machine_type.map((machine_type) => {
                      return (
                        <Option
                          key={machine_type.type}
                          value={machine_type.type}
                        >
                          {machine_type.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              )}
            </Form.Item>
            {productionUnit == "kollu" || productionUnit == "turbo" ? (
              <>
                <Form.Item
                  name="caldron_color"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 32, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Kazan Rengi"
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item name="caldron_stock" label="Kazan Tedarik">
                  <Radio.Group>
                    <Radio value="onstock">Stoktan</Radio>
                    <Radio value="new">Kazan Üreticiden</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="caldron_material"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 19, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Materyal"
                  required
                >
                  <Select>
                    {(function myFunction() {
                      return configFile.caldron_material.map(
                        (caldron_material) => {
                          return (
                            <Option
                              key={caldron_material.type}
                              value={caldron_material.type}
                            >
                              {caldron_material.text}
                            </Option>
                          );
                        }
                      );
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item name="tank_size" label="Depo Hacmi" required>
                  <Select
                    onSelect={(type) => {
                      const tank_size = configFile.tank_size.filter(
                        (tank_size) => tank_size.type == type
                      );
                      setSerialState({
                        ...serialState,
                        tank_size: parseInt(tank_size[0].shortend),
                      });
                    }}
                  >
                    <OptGroup label="Litre">
                      {(function myFunction() {
                        return configFile.tank_size.map((tank_size) => {
                          return (
                            <Option key={tank_size.type} value={tank_size.type}>
                              {tank_size.text}
                            </Option>
                          );
                        });
                      })()}
                    </OptGroup>
                  </Select>
                </Form.Item>
                <Form.Item name="tow_type" label="Çeki Tipi" required>
                  <Select>
                    {(function myFunction() {
                      return configFile.tow_type.map((tow_type) => {
                        return (
                          <Option key={tow_type.type} value={tow_type.type}>
                            {tow_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item name="pump" label="Pompa" required>
                  <Select
                    onSelect={(type: any) => {
                      const pump = configFile.pump.filter(
                        (pump) => pump.type == type
                      );
                      setSerialState({
                        ...serialState,
                        pump: pump[0].shortend,
                      });
                    }}
                  >
                    {(function myFunction() {
                      return configFile.pump.map((pump) => {
                        return (
                          <Option key={pump.type} value={pump.type}>
                            {pump.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item name="commander" label="Kumanda" required>
                  <Select>
                    {(function myFunction() {
                      return configFile.commander.map((commander) => {
                        return (
                          <Option key={commander.type} value={commander.type}>
                            {commander.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="meme_type"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 19, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Meme Tipi"
                  required
                >
                  <Select>
                    {(function myFunction() {
                      return configFile.meme_type.map((meme_type) => {
                        return (
                          <Option key={meme_type.type} value={meme_type.type}>
                            {meme_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
              </>
            ) : null}
            {productionUnit == "kollu" ? (
              <>
                <Form.Item name="arm_type" label="Kol Tipi" required>
                  <Select
                    onSelect={(btype: any) => {
                      const arm_type = configFile.arm_type.filter(
                        (type) => type.type == btype
                      );
                      setSerialState({
                        ...serialState,
                        arm_type: arm_type[0].shortend,
                      });
                    }}
                  >
                    {(function myFunction() {
                      return configFile.arm_type.map((arm_type) => {
                        return (
                          <Option key={arm_type.type} value={arm_type.type}>
                            {arm_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="meme_distance"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 19, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Meme Aralığı"
                  required
                >
                  <Input maxLength={19} />
                </Form.Item>
                <Form.Item
                  name="arm_length"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 30, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Kol Uzunluğu"
                  required
                >
                  <Input maxLength={20} />
                </Form.Item>
              </>
            ) : null}

            {productionUnit == "turbo" ? (
              <>
                <Form.Item name="cooler_type" label="Fan Tipi" required>
                  <Select
                    onSelect={(type: any) => {
                      const cooler_type = configFile.cooler_type.filter(
                        (cooler_type) => cooler_type.type == type
                      );
                      setSerialState({
                        ...serialState,
                        cooler_type: cooler_type[0].shortend,
                      });
                    }}
                  >
                    {(function myFunction() {
                      return configFile.cooler_type.map((cooler_type) => {
                        return (
                          <Option
                            key={cooler_type.type}
                            value={cooler_type.type}
                          >
                            {cooler_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>

                {productionUnit == "turbo" ? (
                  <Form.Item name="caldron_type" label="Kazan Tipi" required>
                    <Select>
                      {(function myFunction() {
                        return configFile.caldron_type.map((caldron_type) => {
                          return (
                            <Option
                              key={caldron_type.type}
                              value={caldron_type.type}
                            >
                              {caldron_type.text}
                            </Option>
                          );
                        });
                      })()}
                    </Select>
                  </Form.Item>
                ) : null}

                {productionUnit == "turbo" ? (
                  <Form.Item name="fan_radius" label="Pervane Çapı" required>
                    <Select>
                      {(function myFunction() {
                        return configFile.fan_radius.map((fan_radius) => {
                          return (
                            <Option
                              key={fan_radius.type}
                              value={fan_radius.type}
                            >
                              {fan_radius.text}
                            </Option>
                          );
                        });
                      })()}
                    </Select>
                  </Form.Item>
                ) : null}
                {productionUnit == "turbo" ? (
                  <Form.Item name="fan_type" label="Pervane Tipi" required>
                    <Select>
                      {(function myFunction() {
                        return configFile.fan_type.map((fan_type) => {
                          return (
                            <Option key={fan_type.type} value={fan_type.type}>
                              {fan_type.text}
                            </Option>
                          );
                        });
                      })()}
                    </Select>
                  </Form.Item>
                ) : null}
              </>
            ) : null}

            <Form.Item name="order_no" label="Sipariş No">
              <Input
                disabled
                value={serialState.order_no}
                style={{ width: "324px" }}
                maxLength={6}
                onChange={(e) => {
                  setSerialState({
                    ...serialState,
                    order_no: padLeft(e.target.value, "0", 6),
                  });
                }}
              />
              <Button
                disabled={isFetching || orderNoCounter}
                icon={<Spin indicator={<UndoOutlined spin={isFetching} />} />}
                onClick={() => createOrderNo(payment_type)}
              />
            </Form.Item>
            <Form.Item
              name="order_amount"
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                { max: 5, message: "Maksimum sınıra ulaşıldı." },
              ]}
              label="Sipariş Adedi"
              required
            >
              <Input
                max={99}
                min={1}
                type="number"
                onChange={(e) => {
                  setSerialState({
                    ...serialState,
                    order_amount: parseInt(e.target.value),
                  });
                }}
              />
            </Form.Item>
            <Form.Item name="year" label="Yıl" initialValue={moment()} required>
              <DatePicker disabled picker="year" />
            </Form.Item>

            <Form.Item name="chassis_color" label="Şase Rengi" required>
              <Select>
                {(function myFunction() {
                  return configFile.chassis_color.map((chassis_color) => {
                    return (
                      <Option
                        key={chassis_color.type}
                        value={chassis_color.type}
                      >
                        {chassis_color.text}
                      </Option>
                    );
                  });
                })()}
              </Select>
            </Form.Item>

            <Form.Item
              name="alt_features"
              rules={[{ max: 250, message: "Maksimum sınıra ulaşıldı." }]}
              label="Ek Özellikler"
              required
            >
              <Input.TextArea maxLength={250} />
            </Form.Item>
            <Form.Item name="order_tooker" label="Sipariş Alan" required>
              <Select>
                {(function myFunction() {
                  return configFile.order_tooker.map((order_tooker) => {
                    return (
                      <Option key={order_tooker.type} value={order_tooker.type}>
                        {order_tooker.text}
                      </Option>
                    );
                  });
                })()}
              </Select>
            </Form.Item>
            <Form.Item name="serial_number" label="Seri No">
              <Input disabled placeholder={serialCode} />
            </Form.Item>

            <Form.Item
              initialValue={payment_type}
              name="payment_type"
              label="Ödeme Tipi"
              required
            >
              <Radio.Group
                value={payment_type}
                onChange={(e) => {
                  setPaymentType(e.target.value);
                  createOrderNo(e.target.value);
                }}
              >
                <Radio value="normal">Normal</Radio>
                <Radio value="ziraikredi">Zirai Kredi</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="payment_detail"
              rules={[{ max: 250, message: "Maksimum sınıra ulaşıldı." }]}
              label="Ödeme Detay"
              required
            >
              <Input.TextArea maxLength={250} />
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Form.Item
                style={{ flex: 1, marginLeft: "1.6rem" }}
                required
                name="price"
                label="Fiyat"
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                style={{ width: "80px" }}
                name="currency"
                initialValue="₺"
                required
              >
                <Select>
                  <Option value="₺">₺</Option>
                  <Option value="€">€</Option>
                  <Option value="$">$</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                loading={isFetching}
                disabled={!orderNoCounter}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                Siparişi Oluştur {orderNoCounter || "(Sipariş No'yu Yenileyin)"}
              </Button>
            </Form.Item>
          </Form>
          <div style={{ display: "block", width: "min-content" }}>
            <QRCode value={qrCodeValue} renderAs="svg" size={178} />
          </div>
        </Body>
      </Content>
    </Layout>
  );

  function SerialCodeGenerator(): string {
    switch (productionUnit) {
      case "kollu":
        return (
          serialState.brand +
          serialState.type +
          serialState.arm_type +
          serialState.tank_size +
          serialState.pump +
          0 +
          serialState.order_no +
          0 +
          serialState.order_amount
        );
      case "turbo":
        return (
          serialState.brand +
          serialState.type +
          serialState.cooler_type +
          serialState.tank_size +
          serialState.pump +
          0 +
          serialState.order_no +
          0 +
          serialState.order_amount
        );
      case "diger":
        return (
          serialState.brand +
          serialState.type +
          0 +
          serialState.order_no +
          0 +
          serialState.order_amount
        );
      default:
        return "";
    }
  }

  function printQRCode(amount: number) {
    let printWindow = window.open("", "", "height=1024, width=1024");
    let reversed = reverseString(serialCode);
    let data = splitTwoPartsBarcode(reversed);
    let qrCode = qrCodeValue.split(",");

    for (let i = amount; i > 0; i--) {
      qrCode[0] = data[0] + "0" + i;

      let svg = renderToString(
        <div style={{ width: "50mm", height: "64mm" }}>
          <QRCode
            value={qrCode[0] + "," + qrCode[1]}
            style={{ height: "44mm", width: "44mm", margin: "3mm" }}
            renderAs="svg"
          />
          <p style={{ margin: 10 }}>{qrCode[0]}</p>
        </div>
      );
      //<QRCode value= style={{ width: "54mm", height: "70mm" }} renderAs="svg" />);

      printWindow?.document.write(String(svg));
    }
    if (printWindow) {
      printWindow.document.body.style.margin = "0";
      printWindow.document.close();
      printWindow.print();
    }
  }
}

export function splitTwoPartsBarcode(str: string) {
  var index = str.indexOf("0"); // Gets the first index where a space occours
  var amount = reverseString(str.substr(0, index)); // Gets the first part
  var serial = reverseString(str.substr(index + 1));

  return [serial, amount];
}

export function reverseString(str: string) {
  var newString = "";
  for (var i = str.length - 1; i >= 0; i--) {
    newString += str[i];
  }
  return newString;
}

export default CreateOrderScreen;
