import React from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import { GetLogs } from "../services/ReportService";
import moment from "moment";
import { ConvertDateTime } from "../helper";

export function renderProperties(key, value) {
  if (value) {
    return (
      <div style={{ display: "flex" }}>
        <p style={{ fontWeight: "bold", marginRight: "5px" }}>{key}:</p>
        <p>{value}</p>
      </div>
    );
  }
}

class OrderLogs extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    let stringKeys = selectedKeys.toString().toLowerCase();
    this.setState({
      searchText: stringKeys,
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        width: 200,
        title: "Kayıt Tarihi",
        dataIndex: "row_created_date",
        sorter: (a, b) =>
          moment(a.row_created_date).unix() - moment(b.row_created_date).unix(),
        render: (text) => <td>{ConvertDateTime(text)}</td>,
      },
      {
        title: "Kayıt",
        dataIndex: "log",
        sorter: (a, b) => a.log.localeCompare(b.log),
        ...this.getColumnSearchProps("log"),
      },
    ],
  };

  componentDidMount = () => {
    GetLogs().then((logs) => {
      this.setState({ data: logs });
    });
  };

  render() {
    const state = this.state;
    return (
      <div
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          maxHeight: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Table
          rowKey={(row, idx) => "key" + idx}
          size="large"
          pagination={{ defaultPageSize: 100, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(OrderLogs);
