export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CONFIG = "CONFIG";

interface Action {
  type: string;
  payload: any;
}

const initialState = {
  user: null,
};

function AuthReducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, user: action.payload };
    case LOGOUT:
      return { ...state, user: null };
    default:
      return state;
  }
}

export default AuthReducer;
