/* eslint-disable eqeqeq */
import React from "react";
import { Table, Input, Button, Modal, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Capitalize, ConvertDateTime } from "../helper";
import OrderDetails from "../components/OrderDetails";
import {
  GetApprovalPendingOrders,
  addApproval,
  updateAnOffer,
} from "../services/OrderService";
import { connect } from "react-redux";
import TextArea from "antd/es/input/TextArea";

const moment = require("moment/min/moment-with-locales");
moment.locale("tr");

class PendingOrdersScreenForFinance extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onFinish = (values) => {};
  onFinishFailed = (errorInfo) => {};

  state = {
    orderModalVisible: false,
    priceForModal: 0,
    detailForModal: "",
    updatePriceModalVisible: false,
    confirmButtonLoadingForModals: false,
    selectedOrderId: "",
    orderDetails: {},
    loading: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a, b) => a.order_no.localeCompare(b.order_no),
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Müşteri",
        dataIndex: "customer",
        sorter: (a, b) => a.customer.localeCompare(b.customer),
        ...this.getColumnSearchProps("customer"),
      },
      {
        title: "Telefon No",
        dataIndex: "phone_number",
        ...this.getColumnSearchProps("phone_number"),
      },
      {
        title: "Adres",
        dataIndex: "address",
        ...this.getColumnSearchProps("address"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Ürün Tipi",
        dataIndex: "production_unit",
        render: (text) => <td>{Capitalize(text)}</td>,
        sorter: (a, b) => a.production_unit.localeCompare(b.production_unit),
      },

      {
        title: "Sipariş Tarihi",
        dataIndex: "date",
        render: (text) => <td>{ConvertDateTime(text)}</td>,
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      },
      {
        title: "Kimden",
        dataIndex: "order_tooker",
        render: (text) => {
          let fromDealer = text.startsWith("Bayi");

          return (
            <span style={{ color: fromDealer ? "red" : "initial" }}>
              {text}
            </span>
          );
        },
      },
      {
        title: "İşlemler",
        render: (text, order) =>
          order.order_tooker.startsWith("Bayi") ? (
            <td>
              {this.props.user.role == "admin" ? (
                order.price !== 0 ? (
                  <Button
                    style={{
                      marginBottom: 10,
                    }}
                    type="primary"
                    onClick={() => {
                      this.setState({
                        updatePriceModalVisible: true,
                        selectedOrderId: order.id,
                        orderDetails: order,
                      });
                    }}
                  >
                    Fiyatı Güncelle
                  </Button>
                ) : (
                  <Button
                    style={{
                      marginBottom: 10,
                    }}
                    type="default"
                    onClick={() => {
                      this.setState({
                        orderModalVisible: true,
                        selectedOrderId: order.id,
                        orderDetails: order,
                      });
                    }}
                  >
                    Fiyat Teklifi Ver
                  </Button>
                )
              ) : null}
            </td>
          ) : (
            <td>
              {this.props.user.role == "admin" ? (
                <Button
                  style={{ marginBottom: 10 }}
                  type="primary"
                  onClick={() => {
                    addApproval(order.id).then((res) => {
                      if (res) {
                        this.GetPendingOrdersData();
                      }
                    });
                  }}
                >
                  Finans Onayı Ver
                </Button>
              ) : null}
            </td>
          ),
        sorter: (a, b) =>
          (a.production_unit == "diger" ? false : !a.can_start_production) -
          (b.production_unit == "diger" ? false : !b.can_start_production),
      },
    ],
  };

  componentDidMount = () => {
    this.GetPendingOrdersData();
  };

  GetPendingOrdersData = () => {
    GetApprovalPendingOrders().then((result) => {
      this.setState({ data: result });
    });
  };
  UpdateAnOffer = () => {
    this.setState({ confirmButtonLoadingForModals: true });
    updateAnOffer(
      this.state.selectedOrderId,
      this.state.priceForModal,
      this.state.detailForModal
    ).then(() => {
      this.setState({
        orderModalVisible: false,
        updatePriceModalVisible: false,
        confirmButtonLoadingForModals: false,
      });
      this.GetPendingOrdersData();
    });
  };

  render() {
    const state = this.state;

    const filteredData = state.data?.filter(
      (res) => !res.approval_role?.includes(this.props.user.secondRole)
    );

    return (
      <>
        <Table
          rowKey={(row) => "key" + row.id}
          size="small"
          loading={state.loading}
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          columns={state.columns}
          dataSource={filteredData}
          expandable={{
            expandedRowRender: (record) => (
              <OrderDetails
                externalDetails={
                  <>
                    <div style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold", marginRight: "5px" }}>
                        {"Fiyat"}:
                      </p>
                      <p>
                        {record.price
                          ? record.price + record.currency
                          : "Fiyat Bilgisi Yok"}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold", marginRight: "5px" }}>
                        {"Ödeme Detayları"}:
                      </p>
                      <p>{record.payment_detail || "Ödeme Detayı Yok"}</p>
                    </div>
                  </>
                }
                hideShowTransactions
                serial_number={record.serial_number}
              />
            ),
          }}
        />
        <Modal
          title={"Fiyat Teklifi Ver"}
          open={this.state.orderModalVisible}
          onOk={() => this.UpdateAnOffer()}
          confirmLoading={this.state.confirmButtonLoadingForModals}
          okText="Gönder"
          onCancel={() => {
            this.setState({ orderModalVisible: false });
          }}
        >
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Fiyat"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Bu alanı doldurun!",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  this.setState({ priceForModal: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              label="Ödeme Detayı"
              name="payment_detail"
              rules={[
                {
                  required: true,
                  message: "Bu alanı doldurun!",
                },
              ]}
            >
              <TextArea
                rows={3}
                maxLength={255}
                onChange={(e) =>
                  this.setState({ detailForModal: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={"Fiyat Teklifi Güncelle"}
          open={this.state.updatePriceModalVisible}
          onOk={() => this.UpdateAnOffer()}
          confirmLoading={this.state.confirmButtonLoadingForModals}
          okText="Güncelle"
          onCancel={() => {
            this.setState({ updatePriceModalVisible: false });
          }}
        >
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Fiyat"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Bu alanı doldurun!",
                },
              ]}
            >
              <Input
                defaultValue={this.state.orderDetails.price}
                type="number"
                onChange={(e) =>
                  this.setState({ priceForModal: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              label="Ödeme Detayı"
              name="payment_detail"
              rules={[
                {
                  required: true,
                  message: "Bu alanı doldurun!",
                },
              ]}
            >
              <Input
                defaultValue={this.state.orderDetails.payment_detail}
                maxLength={255}
                onChange={(e) =>
                  this.setState({ detailForModal: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PendingOrdersScreenForFinance);
