/* eslint-disable eqeqeq */
import React from "react";
import {
  Table,
  Input,
  Button,
  Divider,
  Select,
  Popconfirm,
  Switch,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  GetMovableMachines,
  SwitchMachines,
  DeleteSingleMachine,
  GetMachineStatus,
} from "../services/OrderService";
import { renderToString } from "react-dom/server";
import Modal from "antd/lib/modal/Modal";
import {
  Capitalize,
  ConvertDateTime,
  ConvertOrderLocation,
  ConvertTankStatus,
} from "../helper";
import OrderHistory from "../components/OrderHistory";
import { connect } from "react-redux";
import axios from "axios";
import ExcelJS from "exceljs";

import "dayjs/locale/tr";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import moment from "moment";

dayjs.extend(customParseFormat);

dayjs.locale("tr");

const QRCode = require("qrcode.react");

export function renderProperties(key, value) {
  if (value) {
    return (
      <div style={{ display: "flex" }}>
        <p style={{ fontWeight: "bold", marginRight: "5px" }}>{key}:</p>
        <p>{value}</p>
      </div>
    );
  }
}

function exportWithExcel(data) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("My Sheet");
  sheet.properties.defaultRowHeight = 80;

  sheet.columns = [
    { header: "Seri No", key: "serial_number", width: 32 },
    { header: "Tank", key: "tank_location", width: 32 },
    { header: "Kol", key: "arm_location", width: 32 },
    { header: "Şase", key: "chassis_location", width: 32 },
    { header: "Fan", key: `fan_location`, width: 32 },
    { header: "Planlanan Üretim Tarihi", key: `planned_date`, width: 32 },
  ];

  const promise = Promise.all(
    data?.map(async (item) => {
      sheet.addRow({
        serial_number: item?.serial_number,
        tank_location: item?.tank_location,
        arm_location: item?.arm_location,
        chassis_location: item?.chassis_location,
        fan_location: item?.fan_location,
        planned_date: moment(item?.planned_date).format("DD/MM/YYYY"),
      });
    })
  );
  promise.then(() => {
    const priceCol = sheet.getColumn(5);
    priceCol.eachCell((cell) => {
      const cellValue = sheet.getCell(cell?.address).value;
      if (cellValue > 50 && cellValue < 1000) {
        sheet.getCell(cell?.address).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      }
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "download.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
}

class ActiveMachineStatus extends React.Component {
  constructor(props) {
    super(props);
    this.renderOrderDetails = this.renderOrderDetails.bind(this);
  }
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  renderOrderDetails(record) {
    return Object.keys(this.state.orderDetails).map((detail) => {
      return detail == record.id
        ? this.state.orderDetails[detail].map((detail) => {
            return (
              <>
                <div
                  style={{
                    width: "80%",
                    marginTop: 30,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {renderProperties(
                      "Seri No",
                      Capitalize(detail.serial_number)
                    )}
                    {renderProperties(
                      "Pompa Seri No",
                      Capitalize(detail.pump_serial)
                    )}
                    {renderProperties(
                      "Bulunduğu Konum",
                      ConvertOrderLocation(detail.location) +
                        (detail.comment ? ` (${detail.comment})` : " ")
                    )}
                    {renderProperties(
                      "Son İşlem Tarihi",
                      ConvertDateTime(detail.row_created_date)
                    )}
                    {renderProperties(
                      "Planlama Tarihi",
                      ConvertDateTime(detail.planned_date)
                    )}
                  </div>
                  <div style={{ width: "min-content" }}>
                    {detail.location !== "teslimat" &&
                    detail.location !== "iptal" &&
                    this.props.user.role == "admin" ? (
                      <Button
                        style={{ marginBottom: 10, width: "100%" }}
                        type="primary"
                        onClick={() => {
                          this.setState({
                            orderModalVisible: true,
                            orderSelected: {
                              serial_number: detail.serial_number,
                              order_id: record.id,
                            },
                          });
                        }}
                      >
                        Siparişi Kaydır
                      </Button>
                    ) : null}

                    <Button
                      style={{ marginBottom: 10, width: "100%" }}
                      onClick={() => {
                        let qrCodeValue =
                          detail.serial_number + "," + record.description;
                        this.printQRCode(qrCodeValue, detail.serial_number);
                      }}
                    >
                      Qr Kodu Yazdır
                    </Button>
                    <Button
                      style={{ marginBottom: 10, width: "100%" }}
                      onClick={() => {
                        this.setState({
                          historyModalVisible: true,
                          historyModalId: detail.id,
                          orderSelected: {
                            serial_number: detail.serial_number,
                            order_id: record.id,
                          },
                        });
                      }}
                    >
                      İşlem Geçmişini Göster
                    </Button>
                    {detail.test_comment ? (
                      <Button
                        block
                        onClick={() => {
                          axios
                            .get("./finalkontrolformu.xlsx", {
                              responseType: "blob",
                            })
                            .then((res) => {
                              const FileSaver = require("file-saver");

                              var reader = new FileReader();
                              reader.readAsArrayBuffer(res.data);
                              reader.onload = function (e) {
                                const workbook = new ExcelJS.Workbook();
                                workbook.xlsx.load(e.target.result).then(() => {
                                  var worksheet = workbook.getWorksheet(1);
                                  var row = worksheet.getRow(58);
                                  row.getCell(1).value = detail.test_comment; // A5's value set to 5
                                  row.commit();

                                  var row2 = worksheet.getRow(8);
                                  row2.getCell(1).value = detail.serial_number;
                                  row2.getCell(5).value = detail.test_manager; // A5's value set to 5
                                  row2.getCell(6).value = ConvertDateTime(
                                    detail.test_date
                                  ); // A5's value set to 5
                                  row2.commit();

                                  workbook.xlsx.writeBuffer().then((buffer) => {
                                    const blob = new Blob([buffer], {
                                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                                    });

                                    FileSaver.saveAs(blob, "deneme.xlsx");
                                  });
                                });
                              };
                            });
                        }}
                      >
                        Test Raporu
                      </Button>
                    ) : null}

                    {this.props.user?.role == "admin" &&
                    detail.location !== "iptal" ? (
                      <Popconfirm
                        title="Bu makinayı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz"
                        onConfirm={() => DeleteSingleMachine(detail.id)}
                        okText="Evet"
                        cancelText="Hayır"
                      >
                        <Button
                          danger
                          block
                          style={{ marginBottom: 10 }}
                          type="primary"
                        >
                          Makinayı Sil
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </div>
                </div>
                <Divider />
              </>
            );
          })
        : null;
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    movableMachines: [],
    orderModalVisible: false,
    orderDetails: {},
    keepMachineDetails: false,
    loading: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a, b) => a.order_no.localeCompare(b.order_no),
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Tank",
        dataIndex: "tank_location",
        sorter: (a, b) => a.tank_location.localeCompare(b.tank_location),
        ...this.getColumnSearchProps("tank_location"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Kol",
        dataIndex: "arm_location",
        sorter: (a, b) => a.arm_location.localeCompare(b.arm_location),
        ...this.getColumnSearchProps("arm_location"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Şase",
        dataIndex: "chassis_location",
        ...this.getColumnSearchProps("chassis_location"),
        sorter: (a, b) => a.chassis_location.localeCompare(b.chassis_location),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Fan",
        dataIndex: "fan_location",
        ...this.getColumnSearchProps("fan_location"),
        sorter: (a, b) => a.fan_location.localeCompare(b.fan_location),
        render: (text) => <td>{Capitalize(text)}</td>,
      },

      {
        title: "Planlanan Üretim Tarihi",
        dataIndex: "planned_date",
        ...this.getColumnSearchProps("planned_date"),
        render: (date) => <td>{moment(date).format("DD/MM/YYYY")}</td>,
        sorter: (a, b) =>
          dayjs(
            a.planned_date !== "" ? a.planned_date : "25 Temmuz 2500",
            "D MMMM YYYY"
          ).unix() -
          dayjs(
            b.planned_date !== "" ? b.planned_date : "25 Temmuz 2500",
            "D MMMM YYYY"
          ).unix(),
      },
    ],
  };

  printQRCode(value, serialNumber) {
    let printWindow = window.open("", "", "height=1024, width=1024");

    let svg = renderToString(
      <div style={{ width: "50mm", height: "64mm" }}>
        <QRCode
          value={value}
          style={{ height: "44mm", width: "44mm", margin: "3mm" }}
          renderAs="svg"
        />
        <p style={{ margin: 10 }}>{serialNumber}</p>
      </div>
    );
    printWindow?.document.write(String(svg));

    printWindow.document.body.style.margin = 0;
    printWindow?.document.close();
    printWindow?.print();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isFinishedOrders !== prevProps.isFinishedOrders ||
      this.props.isCancelledOrders !== prevProps.isCancelledOrders
    ) {
      this.GetOrders();
    }
  }

  GetOrders() {
    GetMachineStatus().then((result) => {
      result = result?.map((data) => {
        return {
          ...data,
          arm_location: ConvertOrderLocation(data.arm_location),
          chassis_location: ConvertOrderLocation(data.chassis_location),
          fan_location: ConvertOrderLocation(data.fan_location),
          tank_location: ConvertTankStatus(data.tank_location),
          machine_location: ConvertOrderLocation(data.machine_location),
        };
      });

      this.setState({ data: result });

      this.setState({ loading: false });
    });
  }
  onChange = (checked) => {
    this.setState({ keepMachineDetails: checked ? true : false });
  };

  componentDidMount = () => {
    this.GetOrders();
    GetMovableMachines().then((machines) => {
      if (machines) {
        this.setState({ movableMachines: machines });
      }
    });
  };
  searchInput;

  render() {
    const state = this.state;

    return (
      <div
        style={{
          width: "70%",
          maxWidth: "70%",
          height: "100%",
          maxHeight: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Table
          rowKey={(row) => "key" + row.id}
          size="large"
          loading={this.state.loading}
          //   expandable={{
          //     expandedRowRender: (record) => (
          //       <div
          //         key={"keyexpandable " + record.id}
          //         style={{ marginLeft: "3rem" }}
          //       >
          //         {renderProperties("Ürün", record.description)}
          //         {renderProperties("Tarih", record.date)}
          //         {renderProperties(
          //           "Marka",
          //           this.configFile.brand.filter(
          //             (b) => b.type === record.brand
          //           )[0]?.text
          //         )}
          //         {renderProperties("Sipariş No", record.order_no)}
          //         {renderProperties(
          //           "Sipariş Miktarı",
          //           String(record.order_amount)
          //         )}
          //         {renderProperties(
          //           "Tip",
          //           this.configFile.type.filter((b) => b.type === record.type)[0]
          //             ?.text
          //             ? this.configFile.type.filter(
          //                 (b) => b.type === record.type
          //               )[0]?.text
          //             : this.configFile.machine_type.filter(
          //                 (b) => b.type === record.type
          //               )[0]?.text
          //         )}
          //         {renderProperties(
          //           "Fan Tipi",
          //           this.configFile.cooler_type.filter(
          //             (b) => b.type === record.cooler_type
          //           )[0]?.text
          //         )}
          //         {renderProperties(
          //           "Kazan Boyutu",
          //           record.tank_size ? record.tank_size + " LT" : null
          //         )}
          //         {renderProperties(
          //           "Kazan Materyali",
          //           this.configFile.caldron_material.filter(
          //             (b) => b.type === record.caldron_material
          //           )[0]?.text
          //         )}
          //         {renderProperties(
          //           "Pompa",
          //           this.configFile.pump.filter((b) => b.type === record.pump)[0]
          //             ?.text
          //         )}
          //         {renderProperties("Model Yılı", record.year)}
          //         {renderProperties(
          //           "Meme Tipi",
          //           this.configFile.meme_type.filter(
          //             (b) => b.type === record.meme_type
          //           )[0]?.text
          //         )}
          //         {renderProperties("Meme Aralığı", record.meme_distance)}
          //         {renderProperties(
          //           "Çeki Tipi",
          //           this.configFile.tow_type.filter(
          //             (b) => b.type === record.tow_type
          //           )[0]?.text
          //         )}
          //         {renderProperties(
          //           "Kol Tipi",
          //           this.configFile.arm_type.filter(
          //             (b) => b.type === record.arm_type
          //           )[0]?.text
          //         )}
          //         {renderProperties("Kol Uzunluğu", record.arm_length)}
          //         {renderProperties(
          //           "Şase Rengi",
          //           this.configFile.chassis_color.filter(
          //             (b) => b.type === record.chassis_color
          //           )[0]?.text
          //         )}
          //         {renderProperties(
          //           "Kazan Rengi",
          //           Capitalize(record.caldron_color)
          //         )}
          //         {renderProperties(
          //           "Kazan Tipi",
          //           this.configFile.caldron_type.filter(
          //             (b) => b.type === record.caldron_type
          //           )[0]?.text
          //         )}
          //         {renderProperties(
          //           "Kumanda",
          //           this.configFile.commander.filter(
          //             (b) => b.type === record.commander
          //           )[0]?.text
          //         )}
          //         {renderProperties("Alternatif Özellikler", record.alt_features)}
          //         <Button
          //           style={{ marginRight: "10px" }}
          //           type="primary"
          //           onClick={() => {
          //             GetMachineListFromOrderById(record.id).then(
          //               (orderDetails) => {
          //                 this.setState({
          //                   orderDetails: {
          //                     ...this.state.orderDetails,
          //                     [record.id]: orderDetails,
          //                   },
          //                 });
          //               }
          //             );
          //           }}
          //         >
          //           Detayları Göster
          //         </Button>
          //         {this.renderOrderDetails(record)}
          //         {record.status !== "cancelled" &&
          //         this.props.user?.role == "admin" &&
          //         !this.props.isFinishedOrders ? (
          //           <Popconfirm
          //             title="Bu siparişi iptal etmek istediğinizden emin misiniz?"
          //             onConfirm={() => DeleteOrder(record.id)}
          //             okText="Evet"
          //             cancelText="Hayır"
          //           >
          //             <Button
          //               danger
          //               style={{ marginBottom: 10, marginRight: 10 }}
          //               type="primary"
          //             >
          //               Siparişi İptal Et
          //             </Button>
          //           </Popconfirm>
          //         ) : null}

          //         <Link
          //           style={{
          //             display:
          //               record.order_amount !== record.pending_amount && "none",
          //             color: "white",
          //             marginBottom: 10,
          //             backgroundColor: "#1677ff",
          //             padding: "8px",
          //             borderRadius: "6px",
          //           }}
          //           to={{
          //             pathname: `/editOrder/${record.serial_number}`,
          //           }}
          //         >
          //           Düzenle
          //         </Link>
          //       </div>
          //     ),
          //   }}
          pagination={{ defaultPageSize: 100, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
        />

        <Button
          loading={this.state.loading}
          style={{
            float: "right",
            marginLeft: "1rem",
            marginRight: "1rem",
            cursor: "pointer",
          }}
          type="primary"
          onClick={() => exportWithExcel(this?.state?.data)}
        >
          Excel'e Aktar
        </Button>
        <Modal
          title={
            this.state.orderSelected?.serial_number + " Seri Numaralı Makine."
          }
          open={this.state.orderModalVisible}
          onOk={() => {
            this.setState({ orderModalVisible: false });
            SwitchMachines({
              order1: this.state.selectedToMove,
              order2: this.state.orderSelected,
              keepMachineDetails: this.state.keepMachineDetails,
            });
          }}
          onCancel={() => {
            this.setState({ orderModalVisible: false });
          }}
        >
          <p>Lütfen değişim yapmak istediğiniz makineyi seçin.</p>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            onSelect={(value) => {
              this.setState({
                selectedToMove: {
                  serial_number: value.split(" ")[0],
                  order_id: Number.parseInt(value.split(" ")[1]),
                },
              });
            }}
            placeholder="Lütfen bir makine seçin"
            defaultActiveFirstOption={true}
          >
            {this.state.movableMachines?.map((machine) => {
              return (
                <Select.Option
                  key={machine.id}
                  value={machine.serial_number + " " + machine.order_id}
                >
                  {machine.serial_number +
                    " " +
                    machine.customer +
                    " " +
                    Capitalize(machine.location)}
                </Select.Option>
              );
            })}
          </Select>
          <div style={{ marginTop: "2rem", display: "flex", gap: "1rem" }}>
            <span>Sipariş Detayını Değiştir</span>
            <Switch onChange={this.onChange} />
            <span>Sipariş Detayını Koru (STOK)</span>
          </div>
        </Modal>
        {this.state.historyModalVisible ? (
          <OrderHistory
            isVisible={this.state.historyModalVisible}
            machine_id={this.state.historyModalId}
            serial_number={this.state.orderSelected?.serial_number}
            setIsVisible={(visible) =>
              this.setState({ historyModalVisible: visible })
            }
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ActiveMachineStatus);
