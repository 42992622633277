import { Button } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { GetUsedMaterials } from "../services/ReportService";

const CategoryItem = ({ name, count }: { name: string; count: number }) => (
  <div
    style={{ width: "22vw", display: "flex", justifyContent: "space-between" }}
  >
    <p>{name}:</p>
    <p>{count}</p>
  </div>
);

const CategoryList = ({ categories }: any) => {
  const [expandedCategories, setExpandedCategories] = useState([]);

  const toggleCategory = (categoryName: any) => {
    setExpandedCategories((prevExpanded: any) =>
      prevExpanded.includes(categoryName)
        ? prevExpanded.filter((cat: string) => cat !== categoryName)
        : [...prevExpanded, categoryName]
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "#1A4876",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "2rem",
        }}
      >
        {categories?.slice(0, 4)?.map((category: any) => (
          <div key={category.name}>
            <h2>{category.name}</h2>
            {category.categoryName.slice(0, 5).map((item: any) => (
              <CategoryItem key={item.name} {...item} />
            ))}
            {expandedCategories.includes(category?.name) &&
              category.categoryName
                .slice(5)
                .map((item: any) => <CategoryItem key={item.name} {...item} />)}
            {category.categoryName.length > 5 && (
              <Button
                style={{ marginTop: ".5rem", cursor: "pointer" }}
                type="link"
                onClick={() => toggleCategory(category.name)}
              >
                {expandedCategories.includes(category.name)
                  ? "Daralt.."
                  : "Genişlet.."}
              </Button>
            )}
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          gap: "2rem",
        }}
      >
        {categories?.slice(4, 8)?.map((category: any) => (
          <div key={category.name}>
            <h2>{category.name}</h2>
            {category.categoryName.slice(0, 5).map((item: any) => (
              <CategoryItem key={item.name} {...item} />
            ))}
            {expandedCategories.includes(category.name) &&
              category.categoryName
                .slice(5)
                .map((item: any) => <CategoryItem key={item.name} {...item} />)}
            {category.categoryName.length > 5 && (
              <Button
                style={{ marginTop: ".5rem", cursor: "pointer" }}
                type="link"
                onClick={() => toggleCategory(category.name)}
              >
                {expandedCategories.includes(category.name)
                  ? "Daralt.."
                  : "Genişlet.."}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ProducedMaterials: React.FC = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState({});

  const handleStartDateChange = (_startDate: string) => {
    setStartDate(_startDate);
  };
  const handleEndDateChange = (_endDate: string) => {
    setEndDate(_endDate);
  };

  const handleSubmit = () => {
    GetUsedMaterials(startDate, endDate).then((response) => {
      setData(response);
    });
  };

  const dataArray = Object.keys(data).map((name: string) => ({
    name,
    categoryName: data[name],
  }));

  return (
    <>
      <div
        style={{
          boxSizing: "border-box",
          overflow: "auto",
          maxWidth: "100%",
          margin: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            height: "100%",
            padding: 5,
          }}
        >
          <div
            style={{
              paddingBottom: "2rem",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Title
                level={3}
                style={{ color: "#1A4876", textDecoration: "underline" }}
              >
                Kullanılan Malzemeler
              </Title>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <div style={{ display: "flex", gap: "1rem" }}>
                <Title level={5} style={{ color: "#1A4876" }}>
                  Başlangıç Tarihi
                </Title>
                <input
                  id="datepicker-input"
                  style={{
                    border: "1px solid #1A4876",
                    color: "#1A4876",
                    borderRadius: 10,
                    padding: "0.4rem",
                    marginTop: "1rem",
                    outline: "none",
                  }}
                  type="date"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e.target.value)}
                />
              </div>
              <div style={{ display: "flex", gap: "1rem" }}>
                <Title level={5} style={{ color: "#1A4876" }}>
                  Bitiş Tarihi
                </Title>
                <input
                  id="datepicker-input"
                  style={{
                    border: "1px solid #1A4876",
                    color: "#1A4876",
                    borderRadius: 10,
                    padding: "0.4rem",
                    marginTop: "1rem",
                    outline: "none",
                  }}
                  type="date"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e.target.value)}
                />
              </div>
              <Button
                style={{
                  marginTop: "1rem",
                }}
                type="primary"
                onClick={() => handleSubmit()}
              >
                Ara
              </Button>
            </div>
          </div>
          {Object.keys(data).length > 0 && (
            <CategoryList categories={dataArray} />
          )}
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Row
              style={{
                gap: "1rem",
              }}
            >
              {Object.keys(data)?.map((category: any) => (
                <div style={{}}>
                  <div key={category}>
                    <h2>{category}</h2>
                  </div>
                  <div>
                    {data[category]?.map((item: any, index: number) => (
                      <p key={index}>
                        {item.name}:{item.count}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </Row>
          </div>

      <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(data)?.map((category: any, index: number) => (
              <div
                key={index}
                style={{
                  margin: "10px",
                  width: "20%",
                }}
              >
                <h2>{category}</h2>
                <div
                  style={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "repeat(1, 1fr)",
                  }}
                >
                  {data[category]?.map((item: any, itemIndex: number) => (
                    <React.Fragment key={itemIndex}>
                      {itemIndex > 0 && itemIndex % 3 === 0 && (
                        <div
                          style={{
                            clear: "both",
                          }}
                        ></div>
                      )}
                      {renderGridItem(item, itemIndex)}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProducedMaterials;
