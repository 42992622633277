import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Table,
} from "antd";
import Search from "antd/lib/input/Search";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IAgrotekConfig, {
  ISerializationEffectiveField,
  ISerializationUnEffectiveField,
} from "../IAgrotekConfig";
import { getConfig } from "../redux/actions/authActions";
import { RootState } from "../redux/reducers";
import { addConfig } from "../services/ConfigService";
import {
  AddNeedsList,
  DeleteNeedsList,
  GetNeedsList,
} from "../services/NeedListService";

interface Props {}
const configFile: IAgrotekConfig = JSON.parse(
  localStorage.getItem("config") ?? "{}"
);

const typeToString: { [key: string]: string } = {
  machine_type: "Makine Tipleri",
  brand: "Markalar",
  type: "Tipler",
  cooler_type: "Fan Tipleri",
  tank_size: "Depo Boyutları",
  pump: "Pompalar",
  tow_type: "Çeki Tipleri",
  chassis_color: "Şase Renkleri",
  caldron_type: "Kazan Tipleri",
  fan_type: "Pervane Tipleri",
  arm_type: "Kol Tipleri",
  fan_radius: "Fan Çapları",
  order_tooker: "Sipariş Alanlar",
  commander: "Kumandalar",
  turktraktormodel: "Türk Traktör Modelleri",
  meme_type: "Meme Tipleri",
  mixer_type: "Mixer Tipleri",
  caldron_material: "Kazan Materyalleri",
  role: "Kullanıcı Rolleri",
};

function ManageConfigScreen(props: Props) {
  const [form] = Form.useForm();

  function renderTable() {
    return Object.keys(configFile).map((key) => {
      let configs = configFile[key];
      if (typeof configs == "object") {
        if (Object.keys(configs[0]).length === 2) {
          return renderDoubleColumnTable(
            configs as ISerializationUnEffectiveField[],
            key
          );
        } else if (Object.keys(configs[0]).length === 3) {
          return renderTripleColumnTable(
            configs as ISerializationEffectiveField[],
            key
          );
        }
      }
      return null;
    });
  }

  function NeedsListTable(props: { value: number | string; text: string }) {
    const { value, text } = props;
    const [data, setData] = useState([]);
    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
      GetData();
    }, [value]);

    function GetData() {
      GetNeedsList(value).then((res) => {
        if (res) {
          setData(res);
        }
      });
    }

    const columns = [
      {
        title: "Ürün Adı",
        dataIndex: "name",
        key: "type",
      },
      {
        title: "Gereken Miktar",
        dataIndex: "amount",
      },
      {
        title: "İşlemler",
        render: (text: string, order: any) => (
          <td>
            {user.role == "admin" ? (
              <Button
                style={{ marginBottom: 10 }}
                danger
                type="primary"
                onClick={() => {
                  DeleteNeedsList(order.id).then((res) => {
                    if (res) {
                      GetData();
                    } else {
                      Modal.error({
                        title: "Bir hata oluştu",
                        content: <p>Lütfen tekrar deneyin.</p>,
                        okText: "Tamam",
                      });
                    }
                  });
                }}
              >
                Sil
              </Button>
            ) : null}
          </td>
        ),
      },
    ];
    return (
      <Card style={{ marginBottom: 10 }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>{text}</b>
              <Popover
                content={
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                      AddNeedsList({
                        amount: values.amount,
                        name: values.name,
                        value,
                      }).then((res) => {
                        if (res) {
                          GetData();
                          form.resetFields();
                        } else
                          Modal.error({
                            title: "Bir hata oluştu",
                            content: <p>Lütfen tekrar deneyin.</p>,
                            okText: "Tamam",
                          });
                      });
                    }}
                  >
                    <Form.Item
                      name="name"
                      label="Ürün"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="amount" label="Miktar">
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`${text} Ekleme Yap`}
                trigger="click"
              >
                <Button type="primary" style={{ float: "right" }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={data}
          columns={columns}
        />
      </Card>
    );
  }

  function renderDoubleColumnTable(
    config: ISerializationUnEffectiveField[],
    key: string
  ) {
    const columns = [
      {
        title: "Tip",
        dataIndex: "type",
        key: "type",
        width: 50,
      },
      {
        title: "Gösterim",
        dataIndex: "text",
        width: 50,
      },
    ];
    return (
      <Card style={{ marginBottom: 10 }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>{typeToString[key]}</b>
              <Popover
                content={
                  <Form
                    layout="vertical"
                    onFinish={(values) => {
                      addConfig(String(key), values).then((res) => {
                        if (res) getConfig();
                      });
                    }}
                  >
                    <Form.Item
                      name="type"
                      label="Tip"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece küçük harf ve boşluksuz yazın.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="text" label="Gösterim">
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`${typeToString[key]}a Ekleme Yap`}
                trigger="click"
              >
                <Button type="primary" style={{ float: "right" }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={config?.sort((a: any, b: any) =>
            a.type.localeCompare(b.type)
          )}
          columns={columns}
        />
      </Card>
    );
  }

  function renderTripleColumnTable(
    config: ISerializationEffectiveField[],
    key: string
  ) {
    const columns = [
      {
        title: "Tip",
        dataIndex: "type",
        key: "type",
        width: 33,
      },
      {
        title: "Gösterim",
        dataIndex: "text",
        width: 33,
      },
      {
        title: "Kısaltma (Barkod üzerinde)",
        dataIndex: "shortend",
        width: 34,
      },
    ];
    return (
      <Card style={{ marginBottom: 10 }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>{typeToString[key]}</b>
              <Popover
                content={
                  <Form
                    layout="vertical"
                    onFinish={(values) => {
                      addConfig(String(key), values);
                    }}
                  >
                    <Form.Item
                      name="type"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece küçük harf ve boşluksuz yazın.",
                        },
                      ]}
                      label="Tip"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="text" label="Gösterim">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="shortend"
                      label="Kısaltma"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece büyük harf yazın, ve harf sayısına dikkat edin.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`${typeToString[key]}a Ekleme Yap`}
                trigger="click"
              >
                <Button type="primary" style={{ float: "right" }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={config?.sort((a: any, b: any) =>
            a.type.localeCompare(b.type)
          )}
          columns={columns}
        />
      </Card>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          style={{ width: "60%", marginBottom: "1%", marginTop: "2%" }}
          title=""
        >
          {renderTable()}
        </Card>
        <Card
          title="Üretim Süresi İşlemleri"
          style={{ width: "60%", marginBottom: "1%", marginTop: "2%" }}
        >
          <Search
            style={{ marginBottom: 10 }}
            addonBefore="Şase Üretim Süresi"
            placeholder={String(configFile?.chassis_production_time)}
            suffix="Gün"
            enterButton="Değiştir"
            size="large"
            onSearch={(value) => {
              addConfig("chassis_production_time", { text: value });
            }}
          />
          <Search
            style={{ marginBottom: 10 }}
            addonBefore="Fan Üretim Süresi"
            suffix="Gün"
            placeholder={String(configFile?.fan_production_time)}
            enterButton="Değiştir"
            size="large"
            onSearch={(value) => {
              addConfig("fan_production_time", { text: value });
            }}
          />
          <Search
            style={{ marginBottom: 10 }}
            addonBefore="Kol Üretim Süresi"
            placeholder={String(configFile?.arm_production_time)}
            suffix="Gün"
            enterButton="Değiştir"
            size="large"
            onSearch={(value) => {
              addConfig("arm_production_time", { text: value });
            }}
          />
        </Card>

        <Card
          title="İhtiyaç Listesi İşlemleri"
          style={{ width: "60%", marginBottom: "1%", marginTop: "2%" }}
        >
          {(() => {
            return configFile["tank_size"]
              .sort((a, b) => parseInt(b.type) - parseInt(a.type))
              .map((tank_size) => {
                return (
                  <NeedsListTable
                    value={parseInt(tank_size.type)}
                    text={`${tank_size.text} LT Tank İhtiyaç Listesi`}
                  />
                );
              });
          })()}
        </Card>
      </div>
    </>
  );
}

export default ManageConfigScreen;
