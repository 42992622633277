import { Table } from "antd";
import Kase from "../../kase.jpg";
import { StyleProvider } from "@ant-design/cssinjs";

const turkish_NumberToWords = (number = 0) => {
  /*
   * Assign spelling words in seperate arrays
   */
  const steps = [
    "",
    "bir",
    "iki",
    "üç",
    "dört",
    "beş",
    "altı",
    "yedi",
    "sekiz",
    "dokuz",
  ];
  const tens = [
    "",
    "on",
    "yirmi",
    "otuz",
    "kırk",
    "elli",
    "altmış",
    "yetmiş",
    "seksen",
    "doksan",
  ];
  const hundreds = [
    "",
    "yüz",
    "ikiyüz",
    "üçyüz",
    "dörtyüz",
    "beşyüz",
    "altıyüz",
    "yediyüz",
    "sekizyüz",
    "dokuzyüz",
  ];
  const nS = [
    "",
    "bin",
    "milyon",
    "milyar",
    "trilyon",
    "katrilyon",
    "kentilyon",
    "seksilyon",
    "septilyon",
    "oktilyon",
  ];
  /*
   * Maximum number step is 30
   */
  const max = 30;
  /*
   * Completing number if it has step length which are bigger than 3
   */
  const completer = ["", "00", "0"];
  /*
   * Variables which hold data temporary
   */
  let index;
  let backer;
  let triples;
  let result = "";

  /*
   * Combining less-thousands input to words
   * Ex: > turkish_NumberToWords('134')
   *     > 'yüz' + 'otuz' + 'dört'
   */
  const getUntilThousands = (triple) =>
    `${hundreds[triple[0]]} ${tens[triple[1]]} ${steps[triple[2]]}`;

  /*
   * Handling string parameter errors before calculation
   */
  const errorHandlers = () => {
    if (number.toString().length > max) {
      throw new RangeError`Girilen hesaplanamayacak kadar çok büyük. Sayı en fazla ${max} basamak olmalıdır`();
    }
    if (typeof number != "string") {
      throw new TypeError("Parametre string olmalıdır");
    }
    for (let index = 0; index < number.length; ++index) {
      if (number[index] < "0" || number[index] > "9") {
        throw new Error("Sayı sadece rakamlardan oluşmalıdır");
      }
    }
  };
  errorHandlers();

  /*
   * Seperating triples as a word from end edge
   */
  number = completer[number.length % 3] + number;
  /*
   * Iterating over seperated triple length
   */
  for (index = number.length, backer = 0; index > 0; index -= 3, ++backer) {
    /*
     * Reigstering next triple to calculate
     */
    triples = getUntilThousands(number.substr(index - 3, 3));
    /*
     * If the last triple hasn't reached yet,
     * extend the resulted and combined words to the right
     */
    if (triples !== "") {
      result = `${triples} ${nS[backer]} ${result}`;
    }
  }
  /*
   * There is no one thousand or a thousand in turkish,
   * just returning last word
   */
  if (result.substr(0, 7) === "bir bin") {
    return result.substr(4);
  } else if (result === "") {
    return "sıfır";
  } else {
    return result.toLocaleUpperCase("tr-TR");
  }
};

const formatNumber = (number) => {
  const firstPart = String(number.toFixed(2)).split(".")[0];
  const secondPart = String(number.toFixed(2)).split(".")[1];
  return firstPart.replace(/(.)(?=(.{3})+$)/g, "$1.") + "," + secondPart;
};

const ProformaInvoice = ({ item }) => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const formattedDate = `${day}/${month}/${year}`;

  const columns = [
    {
      title: "ÜRÜN CİNSİ",
      dataIndex: "name",
      width: "12cm",
    },
    {
      title: "BİRİM",
      dataIndex: "unit",
    },
    {
      title: "ADET",
      dataIndex: "quantity",
    },
    {
      title: "BİRİM FİYAT",
      dataIndex: "price",
      width: "3cm",
    },
    {
      title: "TUTAR",
      dataIndex: "totalPrice",
      width: "4cm",
    },
  ];

  const data = [
    {
      key: item?.id ?? "-",
      name: item?.description ?? "-",
      quantity: item?.order_amount ?? "-",
      unit: "ADET",
      price: item.price ? formatNumber(item?.price / 1.1) : "-",
      totalPrice: item?.price
        ? formatNumber((item?.price * item.order_amount) / 1.1)
        : "-",
    },
  ];

  return (
    <StyleProvider hashPriority="high">
      <div
        style={{
          padding: "1cm",
          width: "21cm",
          height: "29.7cm",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <header
          style={{ display: "flex", alignItems: "center", height: "100px" }}
        >
          <div style={{ flex: 1 }}>
            <img width="200" height="auto" src="agrotek.png" alt="logo" />
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            AGROTEK İLAÇLAMA MAKİNALARI VE TARIM TEKNOLOJİLERİ İTHALAT İHRACAT
            ZIRAİ ÜRÜNLER SANAYİ TİCARET LTD. ŞTİ.
          </div>
        </header>

        <span style={{ alignSelf: "end" }}>Tarih: {formattedDate}</span>
        <span
          style={{
            alignSelf: "center",
            marginTop: "0.5cm",
            marginBottom: "10px",
          }}
        >
          ZİRAAT BANKASI
        </span>
        <span style={{ alignSelf: "center", marginBottom: "6px" }}>
          BANKA ŞUBESİ
        </span>
        <span>
          Sn. {item?.customer} aşağıda açıklamaları yazılı olan ilaçlama
          makinesini firmamızdan talep etmektedir.
        </span>
        <span>
          Sn. {item?.customer}'ün bu talebinin gerçekleştirilmesi için gerekli
          işlemlerin yapılmasını arz ederiz.
        </span>
        <span style={{ marginBottom: "10px" }}>Saygılarımızla</span>
        <span
          style={{
            alignSelf: "end",
            marginRight: "1cm",
            marginBottom: "0.5cm",
          }}
        >
          Agrotek İlaçlama Mak. LTD. ŞTİ.
        </span>
        <span
          style={{
            display: "flex",
            alignSelf: "end",
            height: "100px",
            marginBottom: "10px",
          }}
        >
          <img
            style={{ height: "2.28cm", width: "7.57cm" }}
            src={Kase}
            alt="logo"
          />
        </span>
        <span
          style={{
            fontWeight: "bolder",
            alignSelf: "center",
            marginBottom: "2rem",
          }}
        >
          PROFORMA FATURA
        </span>

        <div
          style={{
            width: "100%",
            border: "1px solid black",
            display: "flex",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>Sayın :{item.customer}</span>
            <span>Adres : {item.address}</span>
            <span></span>
            <span></span>
            <span>Vergi No / tc no : {item.tckn}</span>
            <span>Telefon : {item.phone_number}</span>
          </div>

          <div
            style={{
              flex: 1,
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              borderLeft: "1px solid black",
            }}
          >
            <span>
              AGROTEK İLAÇLAMA MAKİNALARI VE TARIM TEKN.İTH.İHR.ZİRAİ ÜRÜNLER
              SAN.TİC.LTD.ŞTİ.
            </span>
            <span>HACIRAHMANLI MH. İSTİKLAL CD.(KÜME EVLER) NO:20</span>
            <span>SARUHANLI / MANİSA</span>
            <span>ZİRAAT BANKASI / SARUHANLI ŞB. / ŞB.KODU: 622</span>
            <span>IBAN: TR84 001 0006 2253 4366 4350 01</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            border: "1px solid black",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  padding: "5px",
                }}
              >
                TARİH
              </span>
              <span
                style={{
                  textAlign: "center",
                  padding: "13px",
                }}
              >
                {formattedDate}
              </span>
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "column",
                display: "flex",
                borderRight: "1px solid black",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  padding: "5px",
                }}
              >
                PROFORMA FATURA NUMARASI
              </span>
              <span
                style={{
                  textAlign: "center",
                  padding: "13px",
                }}
              >
                {item?.order_no}
              </span>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  padding: "5px",
                }}
              >
                RUHSAT TARİH / NO
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  height: "100%",
                }}
              >
                <span style={{ flex: 1 }}>4/20/2022</span>
                <div
                  style={{
                    width: "1px",
                    height: "100%",
                    backgroundColor: "black",
                  }}
                />
                <span style={{ flex: 1 }}>3058</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  padding: "5px",
                }}
              >
                DENEY RAPORU NO
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  height: "30px",
                }}
              >
                <span style={{ flex: 1 }}>2022/11499-TMM.036</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  padding: "5px",
                }}
              >
                SAYFA
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  textAlign: "center",
                  height: "30px",
                }}
              >
                <span style={{ flex: 1 }}>1/1</span>
              </div>
            </div>
          </div>
        </div>

        <Table
          style={{ marginTop: "1rem" }}
          columns={columns}
          dataSource={data}
          bordered
          footer={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                fontWeight: "bold",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "12rem",
                    marginTop: "0.5cm",
                    borderBottom: "1px solid black",
                  }}
                >
                  <span>%10 KDV</span>
                  <span>
                    {item.price
                      ? formatNumber((item.price * item.order_amount) / 11)
                      : "-"}{" "}
                    {item.currency}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "12rem",
                    borderBottom: "1px solid black",
                  }}
                >
                  <span>G.TOPLAM</span>
                  <span>
                    {item.price
                      ? formatNumber(item.price * item.order_amount)
                      : "-"}{" "}
                    {item.currency}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "12rem",
                    borderBottom: "1px solid black",
                  }}
                >
                  <span>
                    YALNIZ{" "}
                    {turkish_NumberToWords(
                      String(item.price * item.order_amount)
                    )}{" "}
                    {item.currency}
                  </span>
                </div>
              </div>
            </div>
          )}
          pagination={false}
        />

        <span
          style={{
            textDecoration: "underline",
            marginTop: "1rem",
            fontSize: "14px",
          }}
        >
          NOT: İLAÇLAMA MAKİNALARI KORUMA KONTROL GENEL MD.’NE BAĞLI OLARAK
          RUHSATLI VE DENEY RAPORLU OLDUĞUNDAN AYRICA ZİRAİ KREDİLENDİRME
          BELGESİ İSTENMEMEKTEDİR.
        </span>
        <span
          style={{
            textDecoration: "underline",
            marginTop: "1rem",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          **** KREDİNİN ONAYLANMASI HALİNDE PROFORMA FATURADA GÖRÜNEN TÜM
          BEDELİN HESABIMIZA TRANSFERİNİ RİCA EDERİZ.
        </span>
        <span
          style={{
            textDecoration: "underline",
            marginTop: "1rem",
            fontWeight: "bold",
          }}
        >
          **** PROFORMA FATURA HAZIRLANMA TARİHİNDEN İTİBAREN 14 GÜN GEÇERLİDİR.
        </span>
      </div>
    </StyleProvider>
  );
};

export default ProformaInvoice;
