/* eslint-disable eqeqeq */
import React from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { Capitalize, ConvertDateTime, ConvertOrderLocation } from "../helper";
import { GetFanProductionTasks } from "../services/ProductionPlanService";
import { renderToString } from "react-dom/server";
import QRCode from "qrcode.react";
import { connect } from "react-redux";
import PartHistory from "../components/PartsHistory";
import ExcelJS from "exceljs";
const moment = require("moment/min/moment-with-locales");
moment.locale("tr");

const configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

function exportWithExcel(data) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("My Sheet");
  sheet.properties.defaultRowHeight = 80;

  sheet.columns = [
    { header: "Sipariş Numarası", key: "order_no", width: 10 },
    { header: "Seri No", key: "serial_number", width: 32 },
    { header: "Tip", key: "type", width: 32 },
    { header: "Marka", key: "brand", width: 32 },
    { header: "Model", key: "description", width: 32 },
    { header: "Fan Tipi", key: `fan_type`, width: 32 },
    { header: "Pervane Tipi", key: `cooler_type`, width: 32 },
    { header: "Pervane Çapı", key: `fan_radius`, width: 32 },
    { header: "Meme Tipi", key: `meme_type`, width: 32 },
    { header: "Mixer Tipi", key: `mixer_type`, width: 32 },
    { header: "Durum", key: `location`, width: 32 },
    { header: "Alternatif Özellikler", key: `alt_features`, width: 64 },
    { header: "Son İşlem Tarihi", key: `row_updated_date`, width: 32 },

    {
      header: "QR",
      key: "qr_code",
      width: 30,
    },
  ];

  const promise = Promise.all(
    data?.map(async (item, index) => {
      const rowNumber = index + 1;
      sheet.addRow({
        order_no: item?.order_no,
        serial_number: item?.serial_number,
        type: configFile.type.filter((b) => b.type === item.type)[0]?.text
          ? configFile.type.filter((b) => b.type === item.type)[0]?.text
          : configFile.machine_type.filter((b) => b.type === item.type)[0]
              ?.text,
        brand: configFile.brand.filter((b) => b.type === item.brand)[0]?.text,
        description: item.description,
        fan_type: item?.fan_type,
        cooler_type: configFile.cooler_type.filter(
          (b) => b.type === item.cooler_type
        )[0]?.text,
        fan_radius: item?.fan_radius,
        meme_type: item.meme_type,
        location: item?.location,
        alt_features: item?.alt_features,
        row_updated_date: item?.row_updated_date,
      });
      let qrCodeValue = "FU" + item.serial_number;

      let url = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeValue}`;

      const res = await getBase64FromUrl(url);

      const imageId2 = workbook.addImage({
        base64: res,
        extension: "jpg",
      });

      sheet.addImage(imageId2, {
        tl: { col: 12, row: rowNumber },
        ext: { width: 100, height: 100 },
      });
    })
  );
  promise.then(() => {
    const priceCol = sheet.getColumn(5);
    priceCol.eachCell((cell) => {
      const cellValue = sheet.getCell(cell?.address).value;
      if (cellValue > 50 && cellValue < 1000) {
        sheet.getCell(cell?.address).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      }
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "download.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
}

class ChassisProductionTasksScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    csvdata: [[]],
    orderDetails: {},
    loading: true,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a, b) => a.order_no.localeCompare(b.order_no),
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Tip",
        dataIndex: "type",
        sorter: (a, b) => a.type.localeCompare(b.type),
        ...this.getColumnSearchProps("type"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Marka",
        dataIndex: "brand",
        sorter: (a, b) => a.brand.localeCompare(b.brand),
        ...this.getColumnSearchProps("brand"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Model",
        dataIndex: "description",
        sorter: (a, b) => a.description.localeCompare(b.description),
        ...this.getColumnSearchProps("model"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Fan Tipi",
        dataIndex: "cooler_type",
        sorter: (a, b) =>
          a.cooler_type &&
          a.cooler_type.localeCompare(b.cooler_type && b.cooler_type),
        ...this.getColumnSearchProps("cooler_type"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Pervane Tipi",
        dataIndex: "fan_type",
        sorter: (a, b) =>
          a.fan_type && a.fan_type.localeCompare(b.fan_type && b.fan_type),
        ...this.getColumnSearchProps("fan_type"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Pervane Çapı",
        dataIndex: "fan_radius",
        sorter: (a, b) => a.fan_radius - b.fan_radius,
        ...this.getColumnSearchProps("fan_radius"),
      },
      {
        title: "Meme Tipi",
        dataIndex: "meme_type",
        sorter: (a, b) => a.meme_type - b.meme_type,
        ...this.getColumnSearchProps("meme_type"),
      },
      {
        title: "Mixer Tipi",
        dataIndex: "mixer_type",
        sorter: (a, b) => a.mixer_type - b.mixer_type,
        ...this.getColumnSearchProps("mixer_type"),
      },
      {
        title: "Durum",
        dataIndex: "location",
        sorter: (a, b) => a.location.localeCompare(b.location),
        ...this.getColumnSearchProps("location"),
      },
      {
        title: "Son İşlem Tarihi",
        dataIndex: "row_updated_date",
        sorter: (a, b) =>
          moment(a.row_updated_date).unix() - moment(b.row_updated_date).unix(),
        ...this.getColumnSearchProps("row_updated_date"),
      },
      {
        title: "İşlemler",
        render: (text, order) => (
          <td>
            {this.props.user.role == "admin" ? (
              <Button
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  this.printQRCode("FU" + order.serial_number);
                }}
              >
                QR Kodu Yazdır
              </Button>
            ) : null}
          </td>
        ),
      },
      {
        render: (text, order) => (
          <td>
            {this.props.user.role == "admin" ? (
              <Button
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  this.setState({
                    historyModalVisible: true,
                    historyModalId: order.machine_id,
                    orderSelected: {
                      ...order,
                      serial_number: order.serial_number,
                      order_id: order.id,
                    },
                  });
                }}
              >
                İşlem Geçmişi
              </Button>
            ) : null}
          </td>
        ),
      },
    ],
  };

  printQRCode(value, amount) {
    let printWindow = window.open("", "", "height=1024, width=1024");

    let svg = renderToString(
      <div style={{ width: "50mm", height: "64mm" }}>
        <QRCode
          value={value}
          style={{ height: "44mm", width: "44mm", margin: "3mm" }}
          renderAs="svg"
        />
        <p style={{ margin: 10 }}>{value}</p>
      </div>
    );
    printWindow?.document.write(String(svg));

    printWindow.document.body.style.margin = 0;
    printWindow?.document.close();
    printWindow?.print();
  }

  componentDidMount = async () => {
    GetFanProductionTasks().then((data) => {
      data = data?.map((data) => {
        return {
          ...data,
          location: ConvertOrderLocation(data.location),
          row_updated_date: ConvertDateTime(data.row_updated_date),
        };
      });

      this.setState({ data });

      let csv = [
        [
          "Sipariş No",
          "Seri No",
          "Tip",
          "Marka",
          "Ürün",
          "Fan Tipi",
          "Pervane Tipi",
          "Pervane Çapı",
          "Meme Tipi",
          "Mixer Tipi",
          "Durum",
          "Son İşlem Tarihi",
        ],
      ];

      data?.forEach((record) =>
        csv.push([
          record.order_no,
          record.serial_number,
          record.type,
          record.brand,
          record.description,
          record.cooler_type,
          record.fan_type,
          record.fan_radius,
          record.meme_type,
          record.location,
          record.row_updated_date,
        ])
      );

      this.setState({ csvdata: csv, loading: false });
    });
  };

  searchInput;

  render() {
    const state = this.state;

    return (
      <>
        <Table
          rowKey={(row) => "key" + row.id}
          size="small"
          loading={this.state.loading}
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
        />
        <Button style={{ float: "right", marginRight: 10 }} type="default">
          <CSVLink separator="$" data={state.csvdata}>
            CSV'ye aktar
          </CSVLink>
        </Button>
        <Button
          loading={this.state.loading}
          style={{
            float: "right",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}
          type="primary"
          onClick={() => exportWithExcel(this?.state?.data)}
        >
          QR Kod
        </Button>
        {this.state.historyModalVisible ? (
          <PartHistory
            details={this.state.orderSelected}
            key={this.state.historyModalId}
            isVisible={this.state.historyModalVisible}
            machine_id={this.state.historyModalId}
            serial_number={this.state.orderSelected?.serial_number}
            partType={1}
            setIsVisible={(visible) =>
              this.setState({ historyModalVisible: visible })
            }
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ChassisProductionTasksScreen);
