//IhtiyacListesiConfigScreen

import { Button, Card, Form, Input, InputNumber, Modal, Popover, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IAgrotekConfig, { ISerializationEffectiveField, ISerializationUnEffectiveField } from '../IAgrotekConfig';
import { getConfig } from '../redux/actions/authActions';
import { RootState } from '../redux/reducers';
import { addConfig } from '../services/ConfigService';
import { AddNeedsList, DeleteNeedsList, GetNeedsList } from '../services/NeedListService';

interface Props {}
const configFile: IAgrotekConfig = JSON.parse(localStorage.getItem('config') ?? '{}');

function IhtiyacListesiConfigScreen(props: Props) {
    const [form] = Form.useForm();
    function NeedsListTable(props: { value: number | string; text: string }) {
      const { value, text } = props;
      const [data, setData] = useState([]);
      const user = useSelector((state: RootState) => state.auth.user);
  
      useEffect(() => {
        GetData();
      }, [value]);
  
      function GetData() {
        GetNeedsList(value).then((res) => {
          if (res) {
            setData(res);
          }
        });
      }
      const columns = [
        {
          title: 'Ürün Adı',
          dataIndex: 'name',
          key: 'type',
        },
        {
          title: 'Gereken Miktar',
          dataIndex: 'amount',
        },
        {
          title: 'İşlemler',
          render: (text: string, order: any) => (
            <td>
              {user.role == 'admin' ? (
                <Button
                  style={{ marginBottom: 10 }}
                  danger
                  type="primary"
                  onClick={() => {
                    DeleteNeedsList(order.id).then((res) => {
                      if (res) {
                        GetData();
                      } else {
                        Modal.error({
                          title: 'Bir hata oluştu',
                          content: <p>Lütfen tekrar deneyin.</p>,
                          okText: 'Tamam',
                        });
                      }
                    });
                  }}
                >
                  Sil
                </Button>
              ) : null}
            </td>
          ),
        },
      ];
      return (  
        <Table
          pagination={false}
          style={{ width: '100%' }}
          title={() => (
            <>
              <b>{text}</b>
              <Popover
                content={
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                      AddNeedsList({ amount: values.amount, name: values.name, tank_size: value }).then((res) => {
                        if (res) {
                          GetData();
                          form.resetFields();
                        } else
                          Modal.error({
                            title: 'Bir hata oluştu',
                            content: <p>Lütfen tekrar deneyin.</p>,
                            okText: 'Tamam',
                          });
                      });
                    }}
                  >
                    <Form.Item
                      name="name"
                      label="Ürün"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="amount" label="Miktar">
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`${text} Ekleme Yap`}
                trigger="click"
              >
                <Button type="primary" style={{ float: 'right' }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={data}
          columns={columns}
          rowKey = 'id'
          key={value.toString()+'Head'}
        />
      );
    }  
    return (
      <>
        <div key='hello' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>  
          <Card key='requiermentTable' title="İhtiyaç Listesi İşlemleri" style={{ width: '60%', marginBottom: '1%', marginTop: '2%' }}>
            {(() => {
                const sortedData = configFile['tank_size']
                .sort((a, b) => parseInt(b.type) - parseInt(a.type));
              return sortedData.map((tank_size) => {
                  return <NeedsListTable value={parseInt(tank_size.type)} text={`${tank_size.text} LT Tank İhtiyaç Listesi`} key ={tank_size.type}/>;
                });
            })()}
          </Card>
        </div>
      </>
    );
  }
  
  export default IhtiyacListesiConfigScreen;
  