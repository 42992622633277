import { store } from "./index";
import CryptoJS from "react-native-crypto-js";

import "dayjs/locale/tr";
import dayjs from "dayjs";
dayjs.locale("tr");

export function GetToken() {
  const token: string = store.getState().auth.user.token;
  return token;
}

export const padLeft = function (
  text: string,
  padChar: string,
  size: number
): string {
  return (String(padChar).repeat(size) + text).substr(size * -1, size);
};

export function Capitalize(s: String) {
  if (s) {
    return s?.charAt(0)?.toUpperCase() + s?.slice(1);
  }
  return "";
}

export function EncryptQR(qrCode: string) {
  let result = CryptoJS.AES.encrypt(qrCode, "yigitcan").toString();

  return result;
}

export function ConvertDateTime(dateTime: string, format?: string) {
  return dayjs(dateTime).format(format || "D MMMM YYYY HH:mm");
}

export function ConvertOrderLocation(OrderLocation: string) {
  switch (OrderLocation) {
    case "kollu":
      return "Kollu Üretimde";
    case "turbo":
      return "Turbo Üretimde";
    case "diger":
      return "Diğer Üretimde";
    case "test":
      return "Testte";
    case "depo":
      return "Depoda";
    case "bahce":
      return "Bahçede";
    case "onaybekliyor":
      return "Üretim Onayı Bekliyor";
    case "planlandı":
      return "Üretim Planına Alındı";
    case "teslimat":
      return "Teslim Edildi";
    case "onproduction":
      return "Üretimde";
    case "sendedpaint":
      return "Boyaya Gönderildi";
    case "sendedgalvaniz":
      return "Galvanize Gönderildi";
    case "receivedgalvaniz":
      return "Galvaniz işleminde.";
    case "receivedpaint":
      return "Boya işleminde.";
    case "sendedsaruhanli":
      return "Boyadan çıktı.";
    case "receivedsaruhanli":
      return "Saruhanlı Depoda (Hazır).";
    case "completed":
      return "Tamamlandı";

    default:
      return Capitalize(OrderLocation);
  }
}

export function ConvertTankStatus(TankStatus: string) {
  if (!TankStatus) return "Stoktan";

  switch (TankStatus) {
    case "delivered":
      return "Teslim Edildi";
    case "producted":
      return "Üretim Tamamlandı (Hazır)";
    case "confirmed":
      return "Üretiliyor (Onaylandı)";
    case "cancelled":
      return "İptal Edildi";
    case "ontheway":
      return "Yolda (Saruhanlıya Gönderildi)";
    case "pending":
      return "Onay Bekliyor";
    default:
      return TankStatus;
  }
}

export function ConvertOrderStatus(OrderStatus: string) {
  switch (OrderStatus) {
    case "active":
      return "Aktif";
    case "cancelled":
      return "İptal Edildi";
    case "finished":
      return "Tamamlandı";
    default:
      return Capitalize(OrderStatus);
  }
}

export function ConvertProductionTasksStatus(TasksStatus: string) {
  switch (TasksStatus) {
    case "onproduction":
      return "Üretiliyor";
    case "ondelivery":
      return "Sevkiyatta";
    case "delivered":
      return "Sevkiyat Tamamlandı";
    case "completed":
      return "Tamamlandı";
    default:
      return Capitalize(TasksStatus);
  }
}
