/* eslint-disable eqeqeq */
import React, { ReactNode } from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GenericOrder, GetActiveOrders } from "../services/OrderService";
import { Capitalize, ConvertDateTime, ConvertOrderLocation } from "../helper";
import OrderDetails from "../components/OrderDetails";

import "dayjs/locale/tr";
import dayjs, { Dayjs } from "dayjs";
dayjs.locale("tr");

export default class ActiveOrdersScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: ReactNode) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    csvdata: [
      [
        "Seri No",
        "Sipariş No",
        "Sipariş Tarihi",
        "Müşteri",
        "Telefon",
        "Adres",
        "Sipariş Alan",
        "Sipariş Özellikleri",
        "Durum",
        "Üretim Birimi",
      ],
    ],
    movableMachines: [],
    orderModalVisible: false,
    orderDetails: {},
    loading: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.order_no.localeCompare(b.order_no),
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Müşteri",
        dataIndex: "customer",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.customer.localeCompare(b.customer),
        ...this.getColumnSearchProps("customer"),
      },
      {
        title: "Telefon No",
        dataIndex: "phone_number",
        ...this.getColumnSearchProps("phone_number"),
      },
      {
        title: "Adres",
        dataIndex: "address",
        ...this.getColumnSearchProps("address"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Ürün Tipi",
        dataIndex: "production_unit",
        render: (text: string) => <td>{Capitalize(text)}</td>,
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.production_unit.localeCompare(b.production_unit),
      },
      {
        title: "Son İşlem Tarihi",
        dataIndex: "last_location_date",
        defaultSortOrder: "descend",
        render: (text: string) => <td>{ConvertDateTime(text)}</td>,
        sorter: (a: GenericOrder, b: GenericOrder) =>
          dayjs(a.last_location_date).unix() -
          dayjs(b.last_location_date).unix(),
      },
      {
        title: "Bulunduğu Konum",
        dataIndex: "location",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.location.localeCompare(b.location),
        ...this.getColumnSearchProps("location"),
      },
    ],
  };

  componentDidMount = () => {
    let csvdata = [
      [
        "Seri No",
        "Sipariş No",
        "Sipariş Tarihi",
        "Müşteri",
        "Telefon",
        "Adres",
        "Sipariş Alan",
        "Durum",
        "Üretim Birimi",
        "Sipariş Özellikleri",
      ],
    ];
    GetActiveOrders().then((res) => {
      let filteredRes = res?.filter(
        (detail) =>
          !(
            detail.location == "iptal" ||
            detail.location == "teslimat" ||
            detail.location == "onaybekliyor"
          )
      );
      filteredRes = filteredRes?.map((detail) => {
        detail.location = ConvertOrderLocation(detail.location);
        return detail;
      });

      this.setState({ data: filteredRes, csvDataloaded: true });
      this.state.data?.forEach((order) => {
        let order_details =
          order.brand +
          "$" +
          order.production_unit +
          "$" +
          order.caldron_color +
          "$" +
          order.caldron_type +
          "$" +
          order.cooler_type +
          "$" +
          order.fan_radius +
          "$" +
          order.fan_type +
          "$" +
          order.pump +
          "$" +
          order.meme_distance +
          "$" +
          order.meme_type +
          "$" +
          order.tank_size +
          "$" +
          order.type +
          "$" +
          order.tow_type +
          "$" +
          order.alt_features +
          "$" +
          order.year;
        csvdata.push([
          order.serial_number,
          order.order_no,
          order.date,
          order.customer,
          order.phone_number,
          order.address,
          order.order_tooker,
          order.location,
          order.production_unit,
          order_details,
        ]);
      });
      this.setState({ csvdata });
    });
  };

  searchInput: React.ReactNode;

  render() {
    const state = this.state;

    return (
      <>
        <Table
          rowKey={(row) => "key" + row.last_location_date + row.serial_number}
          size="small"
          loading={this.state.loading}
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
          expandable={{
            expandedRowRender: (record) => (
              <OrderDetails serial_number={record.serial_number} />
            ),
          }}
        />
      </>
    );
  }
}
