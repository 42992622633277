import React from "react";
import { useLocation } from "react-router-dom";

const ArmInvoice = () => {
  const location = useLocation();
  const item = location?.state?.item;

  const data = {
    "Marka :": item?.brand ?? "-",
    "Tip :": item?.type ?? "-",
    "Kazan Rengi :": item?.caldron_color ?? "-",
    "Hacim (LT):": item?.tank_size ?? "-",
    "Materyal :": item?.caldron_material ?? "-",
    "Çeki Tipi :": item?.cooler_type ?? "-",
    "Pompa :": item?.pump ?? "-",
    "Kumanda :": item?.commander ?? "-",
    "Mixer Tipi :": item?.mixer_type ?? "-",
    "Kol Tipi :": item?.arm_type ?? "-",
    "Meme Tipi :": item?.meme_type ?? "-",
    "Meme Aralığı :": item?.meme_distance ?? "-",
    "Kol Uzunluğu :": item?.arm_length ?? "-",
    "Şase Rengi :": item?.chassis_color ?? "-",
    "Ek Özellikler :": item?.description ?? "-",
    "Sipariş Alan :": item?.order_tooker ?? "-",
  };

  return (
    <div style={{ width: "1500px", marginRight: "auto", marginLeft: "auto" }}>
      <div style={{ border: "1px solid black" }}>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid black",
            gap: "1rem",
            padding: "1rem",
            justifyContent: "space-evenly",
          }}
        >
          <img width="40" height="40" src="agrotek.png" alt="logo" />
          <div style={{ display: "flex", gap: "1rem" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <span>ÇİFTÇİ</span>
              <div
                style={{
                  border: "1px solid orange",
                  height: "15px",
                  width: "30px",
                }}
              />
            </div>
            <div style={{ display: "flex", gap: "5px" }}>
              <span>BAYİ</span>
              <div
                style={{
                  border: "1px solid orange",
                  height: "15px",
                  width: "30px",
                }}
              />
            </div>
            <div style={{ display: "flex", gap: "5px" }}></div>
            <span>KURUMSAL</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <span>SÖZLEŞME TARİHİ: ......./....../...... </span>
          <span>SERİ NO:</span>
          <span>PLASİYER:</span>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "1rem",
            }}
          >
            <span style={{ textAlign: "center" }}>
              MÜŞTERİNİN AD-SOYAD/ÜNVANI:
            </span>
            <span
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
              }}
            >
              MÜŞTERİNİN ADRESİ /GSM/TEL NO:
            </span>
            <span
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
              }}
            >
              MAKİNE ADI:
            </span>
            <br />

            <span
              style={{
                height: "27px",
                textAlign: "center",
                borderTop: "1px solid black",
              }}
            >
              SATIŞ ŞEKLİ:
            </span>

            <span
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              TAHSİLAT VADESİ:
            </span>
            <br />
            <br />
          </div>
          <div style={{ backgroundColor: "black", width: "1px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 3,
              gap: "1rem",
            }}
          >
            <span style={{ marginLeft: "20px", height: "19px" }}>
              {item?.customer}
            </span>
            <span
              style={{
                borderTop: "1px solid black",
              }}
            >
              <span style={{ marginLeft: "20px", height: "19px" }}>
                {item?.phone_number}
              </span>
            </span>
            <div
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                height: "19px",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  ADET
                </span>
                <span>{item?.order_amount} AD.</span>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  width: "1px",
                  height: "115px",
                }}
              />
              <div style={{}}>
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  FİYAT
                </span>
                <span>{}</span>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  width: "1px",
                  height: "115px",
                }}
              />
              <div style={{}}>
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  TUTAR
                </span>
                <span>{}</span>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  width: "1px",
                  height: "115px",
                }}
              />
              <div style={{}}>
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  ÖZEL NOTLAR
                </span>
                <span></span>
              </div>
            </div>
            <br />

            <div
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                display: "flex",
                justifyContent: "space-around",
                paddingLeft: "1rem",
                paddingTop: "8px",
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                <span>NAKİT</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>

              <div style={{ display: "flex", gap: "5px" }}>
                <span>ZİRAİ KREDİ</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <span>C/H</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <span>ÇEK</span>
                <div
                  style={{
                    border: "1px solid orange",
                    height: "15px",
                    width: "30px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                display: "flex",
              }}
            >
              <span
                style={{
                  flex: 2,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                ÖDEME TARİHİ
              </span>
              <span
                style={{
                  flex: 1,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                TUTAR
              </span>
              <span style={{ flex: 2, borderBottom: "1px solid black" }}>
                KALAN BAKİYE
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "1rem", marginTop: "1rem" }}>
        <span>
          1-Makina, ……………………… …………………………. adresinde ALICIYA teslim edilecektir.
          Taşıma ve Nakliye Bedeli …..……. ait olup, bu nedenle oluşacak hasarlar
          da ……………. sorumluluğundadır. Makina’nın teslimi ile sorumluluk ALICIYA
          geçer. <br /> 2-Makina 2 yıl boyunca SATICININ garantisi altında olup
          bu süre içerisinde makinanın servis ve bakımları ücretsiz olarak
          yapılacaktır. Sonraki yıllarda makinanın servis ve bakımından ücret
          alınabileceğini ALICI kabul eder. Makinanın teslimi ile birlikte,
          makinanın kurulum ve kullanımla ilgili olarak ALICI’ya eğitim
          verilecektir. Hatalı kullanımdan doğan arızalar garanti kapsamı
          dışındadır.
          <br /> 3-Nakit satımlarda, ALICI’dan peşinat alınır, kalan bedel en
          geç makinanın teslim günü ALICI tarafından SATICI’ya ödenecektir.
          Bedelin ödenmemesi halinde makina, SATICI tarafından ALICI’ya teslim
          edilmez.
          <br /> 4-Makina ……….. tarihinde ALICI’ya teslim edilecektir.
          SATICI’nın teslimdeki 1 aylık gecikmesinin, olağan olduğunu ALICI
          baştan kabul etmektedir. ALICI’nın; en geç …………. tarihine kadar
          makinayı teslim almaması halinde, ALICI ; makina bedelini bu tarihten
          itibaren işleyecek ticari temerrüt faizi ile tazmin etmekle yükümlü
          olacağını kabul ve taahhüt eder.
          <br /> 5-ALICI; makina’nın teslimi anında makinanın başında
          fotoğraflarının çekilmesini ve bunun SATICI’ya ait sosyal medya
          hesaplarında ad-soyad ve il bilgisi ile paylaşılmasını açık rızası ile
          kabul etmiş olup, bunun kişisel veri ihlali olmadığını ve olmayacağını
          kabul ve beyan etmiştir.
          <br /> 6-SATICI; 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun
          10.maddesi gereği Aydınlatma Metnini ALICI’ya arz etmektedir.
          SATICI’nın veri sorumlusu Agrotek İlaçlama Makinaları Ve Tarım
          Tek.İth.İhr.Zirai Ürünler San.Tic. Ltd. Şti. olup, aşağıdaki kaşe ve
          imza bölümünde adres, vergi dairesi ve numarası, mersis numarası ve
          telefon ve fax numaraları bulunmaktadır. SATICI ; işbu sözleşme
          kapsamında ALICI’nın ad ve soyadını, ünvanını, T.C. Kimlik Numarasını,
          adres bilgisini, vergi kimlik numarasını ve fotoğrafını işleyecektir.
          İşbu kişisel veriler SATICI tarafından faturalandırma, ürün teslimi,
          eğitim hizmet takibi,garanti takibi, ürün takibi, reklam ve tanıtım
          sebepleri ile işlenecektir. Kişisel veri işleme süresi 10 senedir.
          Kişisel Verilerden ALICI’nın adı-soyadı, bulunduğu il ve makina ile
          çekilmiş fotoğrafları SATICI’nın sosyal medya hesaplarında
          paylaşılacaktır. ALICININ ad ve soyad, ünvan, T.C. Kimlik Numarası,
          adres bilgisi, vergi kimlik numarası gibi kişisel verileri
          faturalandırma süreci ile ilgili olarak, Vergi Dairesi, Tarım İl
          Müdürlüğü, Sanayi ve Ticaret Bakanlığı, Hazine ve Maliye Bakanlığı ve
          sair resmi kurumlarla paylaşılmaktadır. 6698 sayılı Kanun’un
          11.maddesi uyarınca SATICI; ALICI’yı “Kişisel verilerinin işlenip
          işlenmediğini öğrenme,kişisel verileri işlenmişse buna ilişkin bilgi
          talep etme, kişisel verilerinin işlenme amacını ve bunların amacına
          uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt
          dışında kişisel verilerinin aktarıldığı üçüncü kişileri bilme, kişisel
          verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesini isteme, kişisel verilerin silinmesini veya yok
          edilmesini isteme, kişisel verilerin düzeltilmesi, silinmesi veya yok
          edilmesine ilişkin işlemlerin kişisel verilerin aktarıldığı üçüncü
          kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik
          sistemler vasıtasıyla analiz edilmesi suretiyle aleyhine bir sonucun
          ortaya çıkmasına itiraz etme,kişisel verilerinin kanuna aykırı olarak
          işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini
          talep etme” haklarına sahip olduğu konusunda açıkça bilgilendirmiştir.
        </span>
      </div>
      <div
        style={{
          padding: "1rem",
          marginTop: "1rem",
          display: "flex",
          gap: "24rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: "bold",
          }}
        >
          <span>FATURA NO :</span>
          <span>İRSALİYE NO :</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: "bold",
          }}
        >
          <span>SEVK TARİHİ :</span>
          <span>ARAÇ BİLGİSİ :</span>
        </div>
      </div>
      <div
        style={{
          padding: "1rem",
          marginTop: "1rem",
          display: "flex",
          gap: "3rem",
        }}
      >
        <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
          SATIŞA KONU MAKİNE KONFİGRASYONU
        </span>
        <span
          style={{
            border: "1px solid black",
            width: "300px",
            padding: "4px",
            height: "20px",
          }}
        >
          SERİ NO:{" "}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-1.2rem",
          }}
        >
          <span>SATICI KAŞE / İMZA</span>
          <textarea cols="30" rows="5"></textarea>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-1.2rem",
          }}
        >
          <span>ALICI AD-SOYAD / İMZA</span>
          <textarea cols="30" rows="5"></textarea>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "10%",
        }}
      >
        <div>
          <ul>
            {Object.entries(data).map(([key, value]) => (
              <li key={key}>
                <strong>{key}</strong> {value}
              </li>
            ))}
          </ul>
        </div>

        <span
          style={{
            border: "1px solid black",
            width: "300px",
            height: "150px",
            padding: "4px",
          }}
        >
          NOTLAR:
        </span>
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <span>MODEL :</span>
          <span>BAĞLANTI TİPİ :</span>
          <span>TANK CİNSİ :</span>
          <span>HACİM LT :</span>
          <span>TANK RENGİ :</span>
          <span>TANK İÇİ YIKAMA :</span>
          <span>ÇEKİ TİPİ :</span>
          <span>POMPA :</span>
          <span>KUMANDA :</span>
          <span>PİSTON KUMANDA :</span>
          <span>KOL TİPİ :</span>
          <span>KOL GENİŞLİĞİ :</span>
          <span>ENJEKTÖR TİPİ :</span>
          <span>ENJEKTÖR ARASI :</span>
          <span>ŞASİ RENGİ :</span>
        </div>
        <div
          style={{
            marginLeft: "5rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>ALBATROS</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AGROTEK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>ASILIR</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ÇEKİLİR</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>POLYESTER</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>POLYETİLEN</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TRENT</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>VIOLET</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YAĞM.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>SARD.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>400</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>600</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>800</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>1000</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>1200</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>1600</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>2000</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>3000</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>KB</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>MB</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YB</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KG</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KM</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KY</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KS</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>SG</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>SY</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>B</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>VAR</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YOK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>TARLA TİPİ PEHLİVAN</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>DAYAMALI</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>DÜZ ÇEKİ</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>AR 1064</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AR 1203</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AR 1604</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BP 135</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BP 185</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BHA 170</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BHA 200</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AGR.160</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>BY MATIC 50</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>M170</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YERLİ M170</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>VDR50</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>UCM</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ARAG 180</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ARAG 400</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>YERLİ S.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>BRG.SEL.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>STANDART</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>SIRALI</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>SABİT</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TRENT</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>COMPACT</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TH.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>PİRAMİT</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TEK.SİL.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ÇIPLAK</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>HORT.DOL.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>AGROJET</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>10 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>12 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>15 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>16 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>17 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>18 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>21 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>24 MT.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>PBM.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>VPBM.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TYMN1T.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>TYMN4T.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>ARG4T.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>35 CM.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>38 CM.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>50 CM.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>PPRC BORU</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>KROM BORU</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>SÜNE MEMESİZ</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>SÜNE MEMELİ</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <span>BOYA</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
            <span>GALV.DALD.</span>
            <div
              style={{
                border: "1px solid orange",
                height: "15px",
                width: "30px",
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ArmInvoice;
