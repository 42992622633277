/* eslint-disable eqeqeq */
import React from "react";
import ReactDomServer from "react-dom/server";

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal as ModalFunction,
  Popconfirm,
  Select,
  Switch,
  Table,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { renderToString } from "react-dom/server";
import {
  CopyOrder,
  DeleteOrder,
  DeleteSingleMachine,
  GetAllOrders,
  GetCancelledOrders,
  GetFinishedOrders,
  GetMachineListFromOrderById,
  GetMovableMachines,
  SetChassisNo,
  SetSasNo,
  SwitchMachines,
} from "../services/OrderService";

import axios from "axios";
import ExcelJS from "exceljs";
import { connect } from "react-redux";
import OrderHistory from "../components/OrderHistory";
import { Capitalize, ConvertDateTime, ConvertOrderLocation } from "../helper";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Link } from "react-router-dom";
import FanInvoice from "./Invoice/FanInvoice";
import ProformaInvoice from "./Invoice/ProformaInvoice";

dayjs.extend(customParseFormat);

dayjs.locale("tr");

const QRCode = require("qrcode.react");

export function renderProperties(key, value) {
  if (value) {
    return (
      <div style={{ display: "flex" }}>
        <p style={{ fontWeight: "bold", marginRight: "5px" }}>{key}:</p>
        <p>{value}</p>
      </div>
    );
  }
}

class OrderScreen extends React.Component {
  constructor(props) {
    super(props);
    this.renderOrderDetails = this.renderOrderDetails.bind(this);
  }
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  renderOrderDetails(record) {
    return Object.keys(this.state.orderDetails).map((detail) => {
      return detail == record.id
        ? this.state.orderDetails[detail].map((detail) => {
            return (
              <>
                <div
                  style={{
                    width: "80%",
                    marginTop: 30,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {renderProperties("TCKN No", record.tckn)}
                    {renderProperties(
                      "Seri No",
                      Capitalize(detail.serial_number)
                    )}
                    {renderProperties("Şase No", Capitalize(detail.chassis_no))}
                    {renderProperties("Sas No", Capitalize(detail.sas_no))}
                    {renderProperties(
                      "Pompa Seri No",
                      Capitalize(detail.pump_serial)
                    )}
                    {renderProperties(
                      "Bulunduğu Konum",
                      ConvertOrderLocation(detail.location) +
                        (detail.comment ? ` (${detail.comment})` : " ")
                    )}
                    {renderProperties(
                      "Son İşlem Tarihi",
                      ConvertDateTime(detail.row_created_date)
                    )}
                    {renderProperties(
                      "Planlama Tarihi",
                      ConvertDateTime(detail.planned_date)
                    )}
                    {detail.complete_duration !== null &&
                      renderProperties(
                        "Üretimde Geçen Süre",

                        dayjs(detail.complete_duration).format(
                          "h [saat] m [dakika] s [saniye]"
                        )
                      )}
                  </div>
                  <div style={{ width: "min-content" }}>
                    {detail.location !== "teslimat" &&
                    detail.location !== "iptal" &&
                    record.status !== "pendingfinance" &&
                    this.props.user.role == "admin" ? (
                      <Button
                        style={{ marginBottom: 10, width: "100%" }}
                        type="primary"
                        onClick={() => {
                          this.setState({
                            orderModalVisible: true,
                            orderSelected: {
                              serial_number: detail.serial_number,
                              order_id: record.id,
                            },
                          });
                        }}
                      >
                        Siparişi Kaydır
                      </Button>
                    ) : null}
                    <Button
                      style={{ marginBottom: 10, width: "100%" }}
                      onClick={() => {
                        let qrCodeValue =
                          detail.serial_number + "," + record.description;
                        this.printQRCode(qrCodeValue, detail.serial_number);
                      }}
                    >
                      Qr Kodu Yazdır
                    </Button>
                    <Button
                      style={{ marginBottom: 10, width: "100%" }}
                      onClick={() => {
                        this.setState({
                          historyModalVisible: true,
                          historyModalId: detail.id,
                          orderSelected: {
                            serial_number: detail.serial_number,
                            order_id: record.id,
                          },
                        });
                      }}
                    >
                      İşlem Geçmişini Göster
                    </Button>
                    {detail.test_comment ? (
                      <Button
                        block
                        onClick={() => {
                          axios
                            .get("./finalkontrolformu.xlsx", {
                              responseType: "blob",
                            })
                            .then((res) => {
                              const FileSaver = require("file-saver");

                              var reader = new FileReader();
                              reader.readAsArrayBuffer(res.data);
                              reader.onload = function (e) {
                                const workbook = new ExcelJS.Workbook();
                                workbook.xlsx.load(e.target.result).then(() => {
                                  var worksheet = workbook.getWorksheet(1);
                                  var row = worksheet.getRow(58);
                                  row.getCell(1).value = detail.test_comment; // A5's value set to 5
                                  row.commit();

                                  var row2 = worksheet.getRow(8);
                                  row2.getCell(1).value = detail.serial_number;
                                  row2.getCell(5).value = detail.test_manager; // A5's value set to 5
                                  row2.getCell(6).value = ConvertDateTime(
                                    detail.test_date
                                  ); // A5's value set to 5
                                  row2.commit();

                                  workbook.xlsx.writeBuffer().then((buffer) => {
                                    const blob = new Blob([buffer], {
                                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                                    });

                                    FileSaver.saveAs(blob, "deneme.xlsx");
                                  });
                                });
                              };
                            });
                        }}
                      >
                        Test Raporu
                      </Button>
                    ) : null}
                    {this.props.user?.role == "admin" &&
                    detail.location !== "iptal" ? (
                      <>
                        <Popconfirm
                          title="Bu makinayı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz"
                          onConfirm={() => DeleteSingleMachine(detail.id)}
                          okText="Evet"
                          cancelText="Hayır"
                        >
                          <Button
                            danger
                            block
                            style={{ marginBottom: 10 }}
                            type="primary"
                          >
                            Makinayı Sil
                          </Button>
                        </Popconfirm>
                      </>
                    ) : null}
                    {this.props.user?.role == "admin" && (
                      <Button
                        style={{ marginBottom: 10 }}
                        type="primary"
                        onClick={() => {
                          this.setState({
                            machineDetail: detail,
                            changeChassisAndSasNoVisible: true,
                          });
                        }}
                      >
                        Şase ve Sas Numarasını Güncelle
                      </Button>
                    )}
                  </div>
                </div>
                <Divider />

                <Modal
                  footer=""
                  title={"Şase ve Sas Numarasını Güncelle"}
                  open={this.state.changeChassisAndSasNoVisible}
                  // onOk={() => this.UpdateAnOffer()}
                  // confirmLoading={this.state.confirmButtonLoadingForModals}
                  okText=""
                  onOk={false}
                  onCancel={() => {
                    this.setState({ changeChassisAndSasNoVisible: false });
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <Input
                      placeholder="Şase Numarasını Güncelle"
                      onChange={(e) =>
                        this.setState({
                          chassisNo: e.target.value,
                        })
                      }
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        SetChassisNo({
                          machine_id: this.state.machineDetail.id,
                          chassis_no: this.state.chassisNo,
                        })
                          .then((result) => {
                            GetMachineListFromOrderById(record.id).then(
                              (orderDetails) => {
                                this.setState({
                                  orderDetails: {
                                    ...this.state.orderDetails,
                                    [record.id]: orderDetails,
                                  },
                                });
                              }
                            );
                          })
                          .catch((err) => {});
                      }}
                    >
                      Güncelle
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <Input
                      placeholder="Sas Numarasını Güncelle"
                      onChange={(e) =>
                        this.setState({
                          sasNo: e.target.value,
                        })
                      }
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        SetSasNo({
                          machine_id: this.state.machineDetail.id,
                          sas_no: this.state.sasNo,
                        })
                          .then((result) => {
                            GetMachineListFromOrderById(record.id).then(
                              (orderDetails) => {
                                this.setState({
                                  orderDetails: {
                                    ...this.state.orderDetails,
                                    [record.id]: orderDetails,
                                  },
                                });
                              }
                            );
                          })
                          .catch((err) => {});
                      }}
                    >
                      Güncelle
                    </Button>
                  </div>
                </Modal>
              </>
            );
          })
        : null;
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onChange = (checked) => {
    this.setState({ keepMachineDetails: checked ? true : false });
  };

  state = {
    movableMachines: [],
    orderModalVisible: false,
    cancelOrderModalVisible: false,
    cancelReason: "",
    changeChassisAndSasNoVisible: false,
    machineDetail: null,
    keepMachineDetails: false,
    csvdata: [
      [
        "Seri No",
        "Sipariş No",
        "Sipariş Tarihi",
        "Müşteri",
        "Telefon",
        "Adres",
        "Sipariş Alan",
        "Durum",
      ],
    ],
    orderDetails: {},
    chassisNo: "",
    sasNo: "",
    loading: false,
    loadingForCancelButton: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a, b) => a.order_no.localeCompare(b.order_no),
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Müşteri",
        dataIndex: "customer",
        sorter: (a, b) => a.customer.localeCompare(b.customer),
        ...this.getColumnSearchProps("customer"),
      },
      {
        title: "Telefon No",
        dataIndex: "phone_number",
        ...this.getColumnSearchProps("phone_number"),
      },
      {
        title: "Lokasyon",
        dataIndex: "address",
        ...this.getColumnSearchProps("address"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Ürün Tipi",
        dataIndex: "production_unit",
        sorter: (a, b) => a.production_unit.localeCompare(b.production_unit),
        ...this.getColumnSearchProps("production_unit"),
        render: (text) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Miktar",
        sorter: (a, b) => a.pending_amount - b.pending_amount,
        ...this.getColumnSearchProps("order_amount"),
        render: (text, order) => (
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              GetMachineListFromOrderById(order.id).then((orderDetails) => {
                const amounts = orderDetails.reduce((acc, curr) => {
                  const location = ConvertOrderLocation(curr.location);
                  if (acc[location]) {
                    acc[location] = acc[location] + 1;
                  } else acc[location] = 1;
                  return acc;
                }, {});

                ModalFunction.success({
                  title: order.order_no + " Numaralı Siparişin Durumu",
                  content: (
                    <p>
                      {Object.keys(amounts).map((key) => (
                        <p>{key + " : " + amounts[key]}</p>
                      ))}
                    </p>
                  ),
                  okText: "Tamam",
                });
              });
            }}
          >
            {String(order.order_amount - order.pending_amount) +
              "/" +
              order.order_amount}
          </td>
        ),
      },
      {
        title: "Sipariş Tarihi",
        dataIndex: "date",
        //render: (text: string) => <td>{ConvertDateTime(text)}</td>,
        sorter: (a, b) =>
          dayjs(a.date, "D MMMM YYYY, HH:mm").unix() -
          dayjs(b.date, "D MMMM YYYY, HH:mm").unix(),
        ...this.getColumnSearchProps("date"),
      },

      {
        title: "Sipariş Alan",
        dataIndex: "order_tooker",
        sorter: (a, b) => a.order_tooker.localeCompare(b.order_tooker),
        ...this.getColumnSearchProps("order_tooker"),
        render: (text) => <td>{text.toUpperCase()}</td>,
      },
      {
        title: "Sipariş Durumu",
        dataIndex: "laststatus",
        sorter: (a, b) => a.laststatus.localeCompare(b.laststatus),
        ...this.getColumnSearchProps("laststatus"),
        render: (text, column) => (
          <td>
            {column.status === "pendingfinance"
              ? "FİNANS ONAYI BEKLİYOR"
              : text?.toUpperCase()}
          </td>
        ),
      },
      {
        title: "Planlanan Tarih",
        dataIndex: "planned_date",
        sorter: (a, b) =>
          dayjs(a.planned_date, "D MMMM YYYY, HH:mm").unix() -
          dayjs(b.planned_date, "D MMMM YYYY, HH:mm").unix(),
        ...this.getColumnSearchProps("planned_date"),
        render: (text) => (
          <td>{text ? ConvertDateTime(text, "D MMMM YYYY") : "Planlanmadı"}</td>
        ),
      },
    ],
  };

  printQRCode(value, serialNumber) {
    let printWindow = window.open("", "", "height=1024, width=1024");

    let svg = renderToString(
      <div style={{ width: "50mm", height: "64mm" }}>
        <QRCode
          value={value}
          style={{ height: "44mm", width: "44mm", margin: "3mm" }}
          renderAs="svg"
        />
        <p style={{ margin: 10 }}>{serialNumber}</p>
      </div>
    );
    printWindow?.document.write(String(svg));

    printWindow.document.body.style.margin = 0;
    printWindow?.document.close();
    printWindow?.print();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isFinishedOrders !== prevProps.isFinishedOrders ||
      this.props.isCancelledOrders !== prevProps.isCancelledOrders
    ) {
      this.GetOrders();
    }
  }

  GetOrders() {
    this.props.isFinishedOrders
      ? GetFinishedOrders().then((result) => {
          if (result) {
            let dateModifiedData = result?.map((order) => {
              order.date = dayjs(order.date).format("D MMMM YYYY, HH:mm");
              return order;
            });
            this.setState({ data: dateModifiedData });
          }
        })
      : this.props.isCancelledOrders
      ? GetCancelledOrders().then((result) => {
          let dateModifiedData = result?.map((order) => {
            order.date = dayjs(order.date).format("D MMMM YYYY, HH:mm");
            return order;
          });
          this.setState({ data: dateModifiedData });
        })
      : GetAllOrders().then((result) => {
          let dateModifiedData = result
            ?.sort((order1, order2) => {
              return dayjs(order1.date, "D MMMM YYYY, HH:mm").unix() >
                dayjs(order2.date, "D MMMM YYYY, HH:mm").unix()
                ? 1
                : -1;
            })
            .map((order) => {
              order.date = dayjs(order.date).format("D MMMM YYYY, HH:mm");
              return order;
            });
          this.setState({ data: dateModifiedData });
        });
  }

  componentDidMount = () => {
    this.GetOrders();
    GetMovableMachines().then((machines) => {
      if (machines) {
        this.setState({ movableMachines: machines });
      }
    });
  };
  searchInput;

  render() {
    const state = this.state;

    return (
      <div
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Table
          rowKey={(row) => "key" + row.id}
          rowClassName={(record, index) => {
            if (record.copied_order === "") return "";
            else if (
              state.data.some(
                (order) =>
                  order.order_no === record.copied_order &&
                  order.status !== "pendingfinance" &&
                  order.status !== "cancelled"
              )
            ) {
              return "bg-red";
            }
          }}
          size="small"
          loading={this.state.loading}
          expandable={{
            expandedRowRender: (record) => (
              <div
                key={"keyexpandable " + record.id}
                style={{ marginLeft: "3rem" }}
              >
                {renderProperties("TCKN No", record.tckn)}
                {renderProperties("Ürün", record.description)}
                {renderProperties("Tarih", record.date)}
                {renderProperties(
                  "Marka",
                  this.configFile.brand.filter(
                    (b) => b.type === record.brand
                  )[0]?.text
                )}
                {renderProperties("Sipariş No", record.order_no)}
                {renderProperties(
                  "Sipariş Miktarı",
                  String(record.order_amount)
                )}
                {renderProperties(
                  "Tip",
                  this.configFile.type.filter((b) => b.type === record.type)[0]
                    ?.text
                    ? this.configFile.type.filter(
                        (b) => b.type === record.type
                      )[0]?.text
                    : this.configFile.machine_type.filter(
                        (b) => b.type === record.type
                      )[0]?.text
                )}
                {renderProperties(
                  "Fan Tipi",
                  this.configFile.cooler_type.filter(
                    (b) => b.type === record.cooler_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Kazan Boyutu",
                  record.tank_size ? record.tank_size + " LT" : null
                )}
                {renderProperties(
                  "Kazan Materyali",
                  this.configFile.caldron_material.filter(
                    (b) => b.type === record.caldron_material
                  )[0]?.text
                )}
                {renderProperties(
                  "Pompa",
                  this.configFile.pump.filter((b) => b.type === record.pump)[0]
                    ?.text
                )}
                {renderProperties("Model Yılı", record.year)}
                {renderProperties(
                  "Meme Tipi",
                  this.configFile.meme_type.filter(
                    (b) => b.type === record.meme_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Mixer Tipi",
                  this.configFile.mixer_type.filter(
                    (b) => b.type === record.mixer_type
                  )[0]?.text
                )}
                {renderProperties("Meme Aralığı", record.meme_distance)}
                {renderProperties(
                  "Çeki Tipi",
                  this.configFile.tow_type.filter(
                    (b) => b.type === record.tow_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Kol Tipi",
                  this.configFile.arm_type.filter(
                    (b) => b.type === record.arm_type
                  )[0]?.text
                )}
                {renderProperties("Kol Uzunluğu", record.arm_length)}
                {renderProperties(
                  "Şase Rengi",
                  this.configFile.chassis_color.filter(
                    (b) => b.type === record.chassis_color
                  )[0]?.text
                )}
                {renderProperties(
                  "Kazan Rengi",
                  Capitalize(record.caldron_color)
                )}
                {renderProperties(
                  "Kazan Tipi",
                  this.configFile.caldron_type.filter(
                    (b) => b.type === record.caldron_type
                  )[0]?.text
                )}
                {renderProperties(
                  "Kumanda",
                  this.configFile.commander.filter(
                    (b) => b.type === record.commander
                  )[0]?.text
                )}
                {renderProperties("Alternatif Özellikler", record.alt_features)}
                {renderProperties(
                  "Ödeme Detayları",
                  record.payment_detail || "Ödeme Detayı Yok"
                )}
                {renderProperties(
                  "İptal Sebebi",
                  record.cancel_reason || "İptal Sebebi Yok"
                )}
                {renderProperties(
                  "Fiyat",
                  record.price
                    ? record.price + record.currency
                    : "Fiyat Bilgisi Yok"
                )}

                <Button
                  style={{ marginRight: "10px" }}
                  type="primary"
                  onClick={() => {
                    let printWindow = window.open(
                      "",
                      "",
                      "height=1024, width=1024"
                    );

                    printWindow?.document.write(
                      ReactDomServer.renderToStaticMarkup(
                        <FanInvoice item={record} />
                      )
                    );

                    printWindow.document.head.appendChild(
                      document.createElement("style")
                    ).innerHTML =
                      "body {margin:0; padding:4px;} span {font-size: 12px;} .ant-table-cell {border-bottom: 1px solid black;} table {  border-collapse: collapse;} table, th, td {border: 1px solid; text-align: center;}";

                    printWindow.document.body.contentEditable = true;

                    printWindow?.document.close();
                  }}
                >
                  Satış Sözleşmesi
                </Button>

                <Button
                  style={{ marginRight: "10px" }}
                  type="primary"
                  onClick={() => {
                    let printWindow = window.open(
                      "",
                      "",
                      "height=1024, width=1024"
                    );

                    printWindow?.document.write(
                      ReactDomServer.renderToStaticMarkup(
                        <ProformaInvoice item={record} />
                      )
                    );

                    printWindow.document.head.appendChild(
                      document.createElement("style")
                    ).innerHTML =
                      "span {font-size: 12px;} .ant-table-cell {border-bottom: 1px solid black;} table {  border-collapse: collapse;} table, th, td {border: 1px solid; text-align: center;}";

                    printWindow.document.body.contentEditable = true;

                    printWindow?.document.close();
                  }}
                >
                  Proforma Fatura
                </Button>

                <Button
                  style={{ marginRight: "10px" }}
                  type="primary"
                  onClick={() => {
                    GetMachineListFromOrderById(record.id).then(
                      (orderDetails) => {
                        this.setState({
                          orderDetails: {
                            ...this.state.orderDetails,
                            [record.id]: orderDetails,
                          },
                        });
                      }
                    );
                  }}
                >
                  Detayları Göster
                </Button>
                {this.props.user?.role == "admin" ? (
                  <Popconfirm
                    title="Bu siparişi stok olarak kopyalamak  istediğinizden emin misiniz?"
                    onConfirm={() => CopyOrder(record.serial_number)}
                    okText="Evet"
                    cancelText="Hayır"
                  >
                    <Button style={{ marginRight: "10px" }} type="primary">
                      STOK Olarak Kopyala
                    </Button>
                  </Popconfirm>
                ) : null}
                {this.renderOrderDetails(record)}
                {record.status !== "cancelled" &&
                this.props.user?.role == "admin" &&
                !this.props.isFinishedOrders ? (
                  <Popconfirm
                    title="Bu siparişi iptal etmek istediğinizden emin misiniz?"
                    onConfirm={() =>
                      this.setState({
                        orderDetails: record,
                        cancelOrderModalVisible: true,
                        loadingForCancelButton: true,
                      })
                    }
                    okText="Evet"
                    cancelText="Hayır"
                  >
                    <Button
                      danger
                      style={{ marginBottom: 10, marginRight: 10 }}
                      type="primary"
                      loading={this.state.loadingForCancelButton}
                    >
                      Siparişi İptal Et
                    </Button>
                  </Popconfirm>
                ) : null}
                <Link
                  style={{
                    display:
                      this.props.user.mail !== "dilek@agrotek.com.tr" &&
                      this.props.user.mail !== "levent@agrotek.com.tr" &&
                      "none",
                    color: "white",
                    marginBottom: 10,
                    backgroundColor: "#1677ff",
                    padding: "8px",
                    borderRadius: "6px",
                  }}
                  to={{
                    pathname: `/editOrder/${record.serial_number}`,
                  }}
                >
                  Düzenle
                </Link>
              </div>
            ),
          }}
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
        />

        <Modal
          title="Sipariş iptal et"
          open={this.state.cancelOrderModalVisible}
          onOk={() => {
            this.setState({
              loadingForCancelButton: true,
            });

            DeleteOrder(this.state.orderDetails.id, this.state.cancelReason)
              .then((result) => {
                this.setState({
                  loadingForCancelButton: false,
                  cancelOrderModalVisible: false,
                });
                this.GetOrders();
              })
              .catch((err) => {});
          }}
          onCancel={() => {
            this.setState({
              cancelOrderModalVisible: false,
              loadingForCancelButton: false,
            });
          }}
          okText="İptal Et"
          cancelText="Vazgeç"
        >
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="İptal Sebebi"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Bu alanı doldurun!",
                },
              ]}
            >
              <Input
                type="text"
                onChange={(e) =>
                  this.setState({ cancelReason: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={
            this.state.orderSelected?.serial_number + " Seri Numaralı Makine."
          }
          open={this.state.orderModalVisible}
          onOk={() => {
            this.setState({ orderModalVisible: false });
            SwitchMachines({
              order1: this.state.selectedToMove,
              order2: this.state.orderSelected,
              keepMachineDetails: this.state.keepMachineDetails,
            });
          }}
          onCancel={() => {
            this.setState({ orderModalVisible: false });
          }}
        >
          <p>Lütfen değişim yapmak istediğiniz makineyi seçin.</p>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            style={{ width: "100%" }}
            onSelect={(value) => {
              this.setState({
                selectedToMove: {
                  serial_number: value.split(" ")[0],
                  order_id: Number.parseInt(value.split(" ")[1]),
                },
              });
            }}
            placeholder="Lütfen bir makine seçin"
            defaultActiveFirstOption={true}
          >
            {this.state.movableMachines?.map((machine) => {
              return (
                <Select.Option
                  key={machine.id}
                  value={machine.serial_number + " " + machine.order_id}
                >
                  {machine.serial_number +
                    " " +
                    machine.customer +
                    " " +
                    Capitalize(machine.location)}
                </Select.Option>
              );
            })}
          </Select>
          <div style={{ marginTop: "2rem", display: "flex", gap: "1rem" }}>
            <span>Sipariş Detayını Değiştir</span>
            <Switch onChange={this.onChange} />
            <span>Sipariş Detayını Koru (STOK)</span>
          </div>
        </Modal>
        {this.state.historyModalVisible ? (
          <OrderHistory
            isVisible={this.state.historyModalVisible}
            machine_id={this.state.historyModalId}
            serial_number={this.state.orderSelected?.serial_number}
            setIsVisible={(visible) =>
              this.setState({ historyModalVisible: visible })
            }
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(OrderScreen);
