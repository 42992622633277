import { Button, Card, Form, Input, Popover, Table } from "antd";
import IAgrotekConfig, {
  ISerializationEffectiveField,
} from "../IAgrotekConfig";
import { addConfig } from "../services/ConfigService";

interface Props {}
const configFile: IAgrotekConfig = JSON.parse(
  localStorage.getItem("config") ?? "{}"
);

function UserRollsConfigScreen(props: Props) {
  function renderTable() {
    return Object.keys(configFile).map((key) => {
      let configs = configFile[key];
      if (key === "role") {
        let configNew = configs as ISerializationEffectiveField[];
        return renderTripleColumnTable(configNew, key);
      }

      return null;
    });
  }

  function renderTripleColumnTable(
    config: ISerializationEffectiveField[],
    key: string
  ) {
    const columns = [
      {
        title: "Tip",
        dataIndex: "type",
        width: 33,
      },
      {
        title: "Gösterim",
        dataIndex: "text",
        width: 33,
      },
      {
        title: "İşlem",
        dataIndex: "shortend",
        width: 34,
      },
    ];
    const data = config?.sort((a: any, b: any) => a.type.localeCompare(b.type));
    return (
      <Card key="hello" style={{ marginBottom: 10 }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>Kullanıcı Rolleri</b>
              <Popover
                content={
                  <Form
                    layout="vertical"
                    onFinish={(values) => {
                      addConfig(String(key), values);
                    }}
                  >
                    <Form.Item
                      name="type"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece küçük harf ve boşluksuz yazın.",
                        },
                      ]}
                      label="Tip"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="text" label="Gösterim">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="shortend"
                      label="Kısaltma"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen sadece büyük harf yazın, ve harf sayısına dikkat edin.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`Yeni kullanıcı ekle`}
                trigger="click"
              >
                <Button type="primary" style={{ float: "right" }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={data}
          columns={columns}
          rowKey="type"
        />
      </Card>
    );
  }

  return (
    <>
      <div
        key="hello"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          style={{ width: "60%", marginBottom: "1%", marginTop: "2%" }}
          title=""
        >
          {renderTable()}
        </Card>
      </div>
    </>
  );
}

export default UserRollsConfigScreen;
