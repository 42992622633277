/* eslint-disable eqeqeq */
import React from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  GetAllTankFromSaruhanli,
  sendToTankProducer,
} from "../services/OrderService";
import { CSVLink } from "react-csv";
import { Capitalize } from "../helper";
import { renderToString } from "react-dom/server";
import QRCode from "qrcode.react";
import { connect } from "react-redux";
import "dayjs/locale/tr";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
dayjs.locale("tr");

class TankProducerScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  exportWithExcel(data) {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 100;

    sheet.columns = [
      {
        header: "Sipariş Numarası",
        key: "order_no",
        width: 10,
      },
      { header: "Müşteri Adı", key: "customer", width: 32 },
      { header: "Sipariş Tarihi", key: "description", width: 32 },
      { header: "Son İşlem Tarihi", key: "last_operation_date", width: 32 },
      { header: "Durum", key: `status`, width: 32 },
      { header: "Depo Boyutu", key: `tank_size`, width: 32 },
      { header: "Kazan Tipi", key: `caldron_type`, width: 32 },
      { header: "Kazan Rengi", key: `caldron_color`, width: 32 },
      { header: "Marka", key: `brand`, width: 32 },

      {
        header: "QR",
        key: "qr_code",
        width: 30,
      },
    ];

    const promise = Promise.all(
      data.map(async (item, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          order_no: item?.order_no,
          customer: item?.customer,
          description: item?.order_date,
          last_operation_date: item?.last_operation_date,
          status: item?.status,
          tank_size: item?.tank_size,
          caldron_type: item?.caldron_type,
          caldron_color: item?.caldron_color,
          brand: item?.brand,
        });
        let qrCodeValue = "TU" + item.serial_number;

        let url =
          await `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeValue}`;

        const res = await this.getBase64FromUrl(url);

        const imageId2 = workbook.addImage({
          base64: res,
          extension: "jpg",
        });

        sheet.addImage(imageId2, {
          tl: { col: 9, row: rowNumber },
          ext: { width: 100, height: 100 },
        });
      })
    );

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "download.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  }

  state = {
    csvdata: [[]],
    orderDetails: {},
    loading: true,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    activeOrders: [],
    showAllOrders: false,
    columns: [
      {
        title: "Sipariş No",
        dataIndex: "order_no",
        sorter: (a, b) => a.order_no - b.order_no,
        ...this.getColumnSearchProps("order_no"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        /*
        title: "Müşteri",
        dataIndex: "customer",
        sorter: (a: GenericOrder, b: GenericOrder) => a.customer.localeCompare(b.customer),
        ...this.getColumnSearchProps("customer"),
      */
      },
      {
        /*
        title: "Sipariş Tarihi",
        dataIndex: "order_date",
        sorter: (a: GenericOrder, b: GenericOrder) => dayjs(a.order_date, "D MMMM YYYY, HH:mm").unix() - dayjs(b.order_date, "D MMMM YYYY, HH:mm").unix(),
        ...this.getColumnSearchProps("order_date"),
      */
      },
      {
        title: "Planlanan Üretim Tarihi",
        dataIndex: "planned_date",
        sorter: (a, b) =>
          dayjs(
            a.planned_date !== "" ? a.planned_date : "25 Temmuz 2500",
            "D MMMM YYYY"
          ).unix() -
          dayjs(
            b.planned_date !== "" ? b.planned_date : "25 Temmuz 2500",
            "D MMMM YYYY"
          ).unix(),
        ...this.getColumnSearchProps("planned_date"),
      },
      {
        title: "Son İşlem Tarihi",
        dataIndex: "last_operation_date",
        sorter: (a, b) =>
          dayjs(a.last_operation_date, "D MMMM YYYY, HH:mm").unix() -
          dayjs(b.last_operation_date, "D MMMM YYYY, HH:mm").unix(),
        ...this.getColumnSearchProps("last_operation_date"),
      },
      {
        title: "Durum",
        dataIndex: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        ...this.getColumnSearchProps("status"),
      },
      {
        title: "Ürün Tipi",
        dataIndex: "production_unit",
        sorter: (a, b) => a.production_unit.localeCompare(b.production_unit),
        ...this.getColumnSearchProps("production_unit"),
      },
      {
        title: "Depo Boyutu",
        dataIndex: "tank_size",
        sorter: (a, b) => a.tank_size - b.tank_size,
        ...this.getColumnSearchProps("tank_size"),
      },
      {
        title: "Depo Rengi",
        dataIndex: "caldron_color",
        ...this.getColumnSearchProps("caldron_color"),
      },
      {
        title: "Depo Tipi",
        dataIndex: "caldron_type",
        sorter: (a, b) => a?.caldron_type?.localeCompare(b?.caldron_type),
      },
      {
        title: "Marka",
        dataIndex: "brand",
        sorter: (a, b) => a.brand.localeCompare(b.brand),
      },
      {
        title: "İşlemler",
        render: (text, order) => (
          <td>
            {this.props.user.role == "admin" ||
            this.props.user.role == "tankproducer" ? (
              <Button
                block
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  this.printQRCode("TU" + order.serial_number);
                }}
              >
                QR Kodu Yazdır
              </Button>
            ) : null}
            {this.props.user.role == "tankproducer" &&
              (order.status === "Onay Bekliyor" ||
                order.status === "Üretiliyor (Onaylandı)") && (
                <Button
                  block
                  style={{ marginBottom: 10 }}
                  type="primary"
                  onClick={() => {
                    const status =
                      order.status === "Onay Bekliyor"
                        ? "pending"
                        : order.status === "Üretiliyor (Onaylandı)"
                        ? "confirmed"
                        : "";
                    sendToTankProducer(status, order.id).then(async () => {
                      const result = await GetAllTankFromSaruhanli();

                      const filteredResult = this.filterResult(result);
                      this.setState({ data: filteredResult });
                    });
                  }}
                >
                  {order.status === "Onay Bekliyor"
                    ? "Onayla"
                    : order.status === "Üretiliyor (Onaylandı)"
                    ? "Üretildi Olarak İşaretle"
                    : ""}
                </Button>
              )}
          </td>
        ),
      },
    ],
  };

  printQRCode(value) {
    let printWindow = window.open("", "", "height=1024, width=1024");

    let svg = renderToString(
      <div style={{ width: "50mm", height: "64mm" }}>
        <QRCode
          value={value}
          style={{ height: "44mm", width: "44mm", margin: "3mm" }}
          renderAs="svg"
        />
        <p style={{ margin: 10 }}>{value}</p>
      </div>
    );
    printWindow?.document.write(String(svg));

    printWindow.document.body.style.margin = 0;
    printWindow?.document.close();
    printWindow?.print();
  }

  filterResult(result) {
    result.forEach((record) => {
      record.last_operation_date = record.deliver_date
        ? dayjs(record.deliver_date).format("D MMMM YYYY, HH:mm")
        : record.producted_date
        ? dayjs(record.producted_date).format("D MMMM YYYY, HH:mm")
        : record.confirmed_date
        ? dayjs(record.confirmed_date).format("D MMMM YYYY, HH:mm")
        : dayjs(record.order_date).format("D MMMM YYYY, HH:mm");
      record.order_date = dayjs(record.order_date).format("D MMMM YYYY, HH:mm");
      record.planned_date = record.planned_date
        ? dayjs(record.planned_date).format("D MMMM YYYY")
        : "";
      record.brand = Capitalize(record.brand);
      record.status =
        record.status == "delivered"
          ? "Teslim Edildi"
          : record.status == "producted"
          ? "Üretim Tamamlandı (Hazır)"
          : record.status == "confirmed"
          ? "Üretiliyor (Onaylandı)"
          : record.status == "cancelled"
          ? "İptal Edildi"
          : record.status == "ontheway"
          ? "Yolda (Saruhanlıya Gönderildi)"
          : record.status === "pending"
          ? "Onay Bekliyor"
          : record.status;
      record.caldron_type = record.caldron_type
        ? Capitalize(record.caldron_type)
        : "";
      record.caldron_color = Capitalize(record.caldron_color);
      record.status = Capitalize(record.status);
    });
    return result;
  }

  componentDidMount = async () => {
    const result = await GetAllTankFromSaruhanli();

    const filteredResult = this.filterResult(result);
    const onlyActiveOrders = filteredResult.filter(
      (res) => res.status !== "Teslim Edildi"
    );

    this.setState({ data: filteredResult, activeOrders: onlyActiveOrders });

    let csv = [
      [
        "Sipariş No",
        "Müşteri",
        "Sipariş Tarihi",
        "Son İşlem Tarihi",
        "Durum",
        "Depo Boyutu",
        "Kazan Tipi",
        "Kazan Rengi",
        "Marka",
      ],
    ];

    result.forEach((record) =>
      csv.push([
        record.order_no,
        record.customer,
        record.order_date,
        record.last_operation_date,
        record.status,
        record.tank_size,
        record.caldron_type,
        record.caldron_color,
        record.brand,
      ])
    );

    this.setState({ csvdata: csv, loading: false });
  };

  searchInput;

  render() {
    const state = this.state;

    return (
      <>
        <Table
          rowKey={(row) => "key" + row.id}
          size="small"
          loading={this.state.loading}
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
        />
        <Button style={{ float: "right", marginRight: 10 }} type="default">
          <CSVLink data={state.csvdata}>CSV'ye aktar</CSVLink>
        </Button>
        <Button
          loading={this.state.loading}
          style={{ float: "right", marginRight: 10 }}
          type="primary"
          onClick={() => this.exportWithExcel(state?.data)}
        >
          QR Kod Yazdır
        </Button>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TankProducerScreen);
