import React from "react";
import { Modal, Form, Input } from "antd";

interface ChangePasswordFormProps {
  visible: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
  mail: string;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ visible, mail, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Kullanıcının Şifresini Değiştir"
      okText="Değiştir"
      cancelText="İptal Et"
      onCancel={onCancel}
      onOk={() => {
        if (form.getFieldValue("password") === form.getFieldValue("password1")) {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate({ ...values, Mail: mail });
            })
            .catch((info) => {

            });
        }
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: "public" }} requiredMark={false}>
        <Form.Item name="Password" label="Şifre" rules={[{ required: true, message: "Lütfen bir şifre girin." }]}>
          <Input type="password" />
        </Form.Item>
        <Form.Item name="Password1" label="Şifre (Tekrar)" rules={[{ required: true, message: "Lütfen aynı şifreyi tekrar girin." }]}>
          <Input type="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordForm;
