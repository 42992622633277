import React, { useState } from "react";
import { Button, Card, Form, Input, Layout, Row } from "antd";
import { Body } from "./MainScreen";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/authActions";
import { Modal } from "antd";
import { API_ADDRESS } from "..";
import { Content } from "antd/es/layout/layout";
import { Link } from "react-router-dom";

function ForgotPasswordScreen() {
  const dispatch = useDispatch();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const onFinish = (values: any) => {
    Axios.post(API_ADDRESS + "/api/auth/ResetPassword", {
      Mail: values.mail,
    })
      .then((res) => {
        if (res.status === 200) {
          Modal.success({
            title: "İşlem Başarılı.",
            content: "Lütfen mail kutunuzu kontrol edin.",
          });
        }
      })
      .catch((err) => {
        Modal.warning({
          title: "Bir hata oluştu.",
          content: "Lütfen mail adresinizi kontrol edin.",
        });
      });
    dispatch(login(values.mail, values.password));
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Layout style={{ height: "100%" }}>
      <Content>
        <Row style={{ height: "100%" }} justify="center" align="middle">
          <Card bordered style={{ minWidth: "350px", height: "min-content" }}>
            <div
              style={{
                display: "flex",
                height: "auto",
                width: "auto",
                justifyContent: "center",
              }}
            >
              <img width="200" height="200" src="agrotek.png" alt="image" />
            </div>
            <h1>Şifremi Unuttum</h1>
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              requiredMark={false}
            >
              <Form.Item
                name="mail"
                rules={[
                  { required: true, message: "Bu alan boş bırakılamaz." },
                  {
                    type: "email",
                    message: "Lütfen geçerli bir mail adresi girin.",
                  },
                ]}
              >
                <Input placeholder="birisi@gmail.com" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitButtonLoading}
                  block
                >
                  Şifremi Sıfırla
                </Button>
              </Form.Item>
              <Link to="/login" className="center">
                Giriş yap
              </Link>
            </Form>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
}

export default ForgotPasswordScreen;
