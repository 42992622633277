import { Button, Card, Col, Form, Input, InputNumber, Modal, Popover, Row, Select, Table } from "antd";
import Search from "antd/lib/input/Search";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IAgrotekConfig, { ISerializationEffectiveField, ISerializationUnEffectiveField } from "../IAgrotekConfig";
import { getConfig } from "../redux/actions/authActions";
import { RootState } from "../redux/reducers";
import { addConfig } from "../services/ConfigService";
import { AddGenericTaskRoleAuthorizationList, AddNeedsList, DeleteGenericTaskRoleAuthorizationList, DeleteNeedsList, GetGenericTaskRoleAuthorizationList, GetNeedsList } from "../services/NeedListService";

interface Props {}
const configFile: IAgrotekConfig = JSON.parse(localStorage.getItem("config") ?? "{}");

const typeToString: { [key: string]: string } = {
  machine_type: "Makine Tipleri",
  brand: "Markalar",
  type: "Tipler",
  cooler_type: "Fan Tipleri",
  tank_size: "Depo Boyutları",
  pump: "Pompalar",
  tow_type: "Çeki Tipleri",
  chassis_color: "Şase Renkleri",
  caldron_type: "Kazan Tipleri",
  fan_type: "Pervane Tipleri",
  arm_type: "Kol Tipleri",
  fan_radius: "Fan Çapları",
  order_tooker: "Sipariş Alanlar",
  commander: "Kumandalar",
  turktraktormodel: "Türk Traktör Modelleri",
  meme_type: "Meme Tipleri",
  caldron_material: "Kazan Materyalleri",
  role: "Kullanıcı Rolleri",
};

function GenericTasksConfigScreen(props: Props) {
  const [form] = Form.useForm();

  function RoleListTable(props: { value: string; text: string }) {
    const { value, text } = props;
    const [data, setData] = useState([]);
    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
      GetData();
    }, [value]);

    function GetData() {
      GetGenericTaskRoleAuthorizationList(value).then((res) => {
        if (res) {
          setData(res);
        }
      });
    }

    const columns = [
      {
        title: "Yetki",
        dataIndex: "authority",
        render: (text:any) => <td>{configFile["role"].find((role) => role.type === text)?.text}</td>,
      },
      {
        title: "İşlemler",
        width: "min-content",
        render: (text: string, order: any) => (
          <td>
            {user.role == "admin" ? (
              <Button
                style={{ marginBottom: 10 }}
                danger
                type="primary"
                onClick={() => {
                  DeleteGenericTaskRoleAuthorizationList(order.id).then((res) => {
                    if (res) {
                      GetData();
                    } else {
                      Modal.error({
                        title: "Bir hata oluştu",
                        content: <p>Lütfen tekrar deneyin.</p>,
                        okText: "Tamam",
                      });
                    }
                  });
                }}
              >
                Sil
              </Button>
            ) : null}
          </td>
        ),
      },
    ];

    return (
      <Card>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>{text}</b>
              <Popover
                content={
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                      AddGenericTaskRoleAuthorizationList({ role: value, authority: values.authority }).then((res) => {
                        if (res) {
                          GetData();
                          form.resetFields();
                        } else
                          Modal.error({
                            title: "Bir hata oluştu",
                            content: <p>Lütfen tekrar deneyin.</p>,
                            okText: "Tamam",
                          });
                      });
                    }}
                  >
                    <Form.Item
                      name="authority"
                      label="Yetki"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select>
                        {(() => {
                          return configFile["role"]
                            .sort((a, b) => b.text.localeCompare(b.text))
                            .filter((newUser) => newUser.type !== user.role)
                            .map((user) => {
                              return <Select.Option value={user.type}>{user.text}</Select.Option>;
                            });
                        })()}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Ekle
                      </Button>
                    </Form.Item>
                  </Form>
                }
                title={`${text} Ekleme Yap`}
                trigger="click"
              >
                <Button type="primary" style={{ float: "right" }}>
                  Ekle
                </Button>
              </Popover>
            </>
          )}
          dataSource={data}
          columns={columns}
        />
      </Card>
    );
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Row gutter={[10, 10]}>
            {(() => {
              return configFile["role"]
                .sort((a, b) => b.text.localeCompare(b.text))
                .map((user) => {
                  return (
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <RoleListTable value={user.type} text={`${user.text} Rolünün Yetkileri `} />
                    </Col>
                  );
                });
            })()}
          </Row>
      </div>
    </>
  );
}

export default GenericTasksConfigScreen;
