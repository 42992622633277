/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Select } from "antd";
import { useEffect, useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  AddShortcut,
  GetShortcuts,
  RemoveShortcuts,
} from "../services/OrderService";

function Shortcuts() {
  const [_items, setItems] = useState([]);
  const items = _items?.filter((item) => item !== "");

  const [dataForSelect, setDataForSelect] = useState();
  const [selectedData, setSelectedData] = useState();

  const history = useHistory();

  const constantProduction = [
    {
      label: "Kollu Siparişler",
      route: "kollu",
    },
    {
      label: "Turbo Siparişler",
      route: "turbo",
    },
    {
      label: "Diğer Siparişler",
      route: "diger",
    },
    {
      label: "Hazır Tanklar",
      route: "tanklar",
    },
    {
      label: "Aktif Makina Durumları",
      route: "aktifMakina",
    },
  ];

  useEffect(() => {
    let data = localStorage.getItem("config");
    let parsedData = JSON.parse(data)?.role;
    setDataForSelect(parsedData);
  }, []);

  useEffect(async () => {
    GetShortcuts().then((res) => setItems(res?.split(",")));
  }, []);

  function onChange(sourceIndex, targetIndex) {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
  }

  const handleChange = (value) => {
    setSelectedData(value);
  };
  const handleClick = async () => {
    AddShortcut(items, selectedData).then((res) => setItems(res?.split(",")));
    setSelectedData(null);
  };
  const handleDelete = async (item) => {
    RemoveShortcuts(items, item).then((res) => setItems(res?.split(",")));
  };

  let isSmallScreen = screen.width < 800;

  return (
    <>
      <Wrapper>
        <Button
          disabled={!selectedData}
          style={styles.button}
          onClick={handleClick}
          type="primary"
        >
          Kısayol Oluştur +
        </Button>

        <Select
          placeholder="Seçin"
          style={{
            width: 120,
          }}
          value={selectedData}
          onChange={handleChange}
          options={dataForSelect
            ?.filter((data) => !items?.includes(data.type))
            ?.map((data) => ({
              value: data.type,
              label: data.text,
            }))}
        />
      </Wrapper>

      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={isSmallScreen ? 2 : 4}
          rowHeight={100}
          style={{ height: "400px" }}
        >
          {items
            ?.filter((item) => item !== "")
            ?.map((item, key) => (
              <GridItem
                key={key}
                style={{
                  display: !item ? "none" : "block",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    position: "relative",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 10,
                      borderTopRightRadius: "10px",
                      backgroundColor: "#FF4845",
                      color: "white",
                      boxShadow: "none",
                      border: "none",
                      height: "26px",
                      width: "40px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(item)}
                  >
                    Sil
                  </button>
                  <div
                    style={{
                      margin: "5px",
                      borderRadius: "10px",
                      backgroundColor: "rgb(245,245,245)",
                      width: "100%",
                      height: "90px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "grabbing",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer", userSelect: "none" }}
                      onClick={(e) => {
                        history.push({
                          pathname: "GenericTaskWithRoleScreen",
                          state: { role: item },
                        });
                      }}
                    >
                      {dataForSelect?.find((data) => data?.type === item)?.text}
                    </span>
                  </div>
                </div>
              </GridItem>
            ))}
        </GridDropZone>
      </GridContextProvider>
      <hr />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          gap: "1rem",
        }}
      >
        {constantProduction?.map((prod, key) => (
          <div
            key={key}
            onClick={() =>
              history.push({
                pathname:
                  prod.route === "aktifMakina"
                    ? `/activeMachines`
                    : prod.route === "tanklar"
                    ? `/tankProducerFromShortcuts`
                    : `filteredOrders/${prod.route}`,
              })
            }
            style={{
              margin: "5px",
              borderRadius: "10px",
              backgroundColor: "rgb(245,245,245)",
              width: "100%",
              height: "90px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "grabbing",
            }}
          >
            {prod.label}
          </div>
        ))}
      </div>
    </>
  );
}
const styles = {
  button: {
    marginLeft: 25,
  },
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
`;
export default Shortcuts;
