import { Modal } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "..";
import { GetToken } from "../helper";

export async function CreateProductionPlan(values: any, onSuccess: () => void) {
  const response = await Axios.post(
    API_ADDRESS + "/api/ProductionPlan/CreatePlan",
    values,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res) {
        onSuccess();
        return true;
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: (
          <p>Plan oluşturulurken bir hata oluştu lütfen tekrar deneyin..</p>
        ),
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function CancelProductionPlan(
  machineId: number,
  onSuccess: () => void
) {
  const response = await Axios.put(
    API_ADDRESS + `/api/ProductionPlan/CancelPlan/${machineId}`,
    null,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res) {
        onSuccess();
        return true;
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: (
          <p>Sipariş iptal edilirken bir hata oluştu.Lütfen tekrar deneyin..</p>
        ),
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function GetProductionPlans() {
  const response = await Axios.get(
    API_ADDRESS + "/api/ProductionPlan/GetPlans",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetChassisProductionTasks() {
  const response = await Axios.get(
    API_ADDRESS + "/api/ProductionTasks/GetChassisProductionTasks",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetArmProductionTasks() {
  const response = await Axios.get(
    API_ADDRESS + "/api/ProductionTasks/GetArmProductionTasks",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function CreateGenericTask(data: {
  title: string;
  description: string;
  group: string;
  amount: number;
}) {
  const response = await Axios.post(
    API_ADDRESS + "/api/Task/AddGenericTask",
    data,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function ModifyGenericTask(data: {
  id: number;
  new_group: string;
}) {
  const response = await Axios.put(
    API_ADDRESS + "/api/Task/ModifyGenericTask",
    data,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return true;
    })
    .catch((err) => {
      return false;
    });

  return response;
}

export async function GetActiveGenericTasks() {
  const response = await Axios.get(
    API_ADDRESS + "/api/Task/GetGenericTasksWeb",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}
export async function GetGenericTaskWithRole(id: string) {
  const response = await Axios.get(
    API_ADDRESS + `/api/Task/GetGenericTasksWithRole/${id}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetFinishedGenericTasks() {
  const response = await Axios.get(
    API_ADDRESS + "/api/Task/GetFinishedGenericTasks",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function DeleteGenericTask(id: number) {
  const response = await Axios.delete(
    API_ADDRESS + "/api/Task/DeleteGenericTask/" + id,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Bir hata oluştu",
        content: <p>Lütfen tekrar deneyin..</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function DivideGenericTask(id: number, amount: number) {
  const response = await Axios.put(
    API_ADDRESS + "/api/Task/DivideGenericTask/" + id + "/" + amount,
    {},
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Bir hata oluştu",
        content: <p>Lütfen tekrar deneyin..</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function UpdateGenericTask(id: number, amount: number) {
  const response = await Axios.put(
    `${API_ADDRESS}/api/Task/UpdateGenericTask`,
    {
      id,
      amount,
    },
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Modal.error({
        title: "Bir hata oluştu",
        content: <p>Lütfen tekrar deneyin..</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function GetFanProductionTasks() {
  const response = await Axios.get(
    API_ADDRESS + "/api/ProductionTasks/GetFanProductionTasks",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}
