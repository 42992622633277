import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import IAgrotekConfig from "../IAgrotekConfig";
import { GetTotalNeeds } from "../services/NeedListService";

interface Props {}
const configFile: IAgrotekConfig = JSON.parse(
  localStorage.getItem("config") ?? "{}"
);

function OtherNeedListScreen(props: Props) {
  function NeedsListTable(props: { value: number | string; text: string }) {
    const { value, text } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
      GetData();
    }, [value]);

    function GetData() {
      GetTotalNeeds(value).then((res) => {
        if (res) {
          setData(res);
        }
      });
    }

    const columns = [
      {
        title: "Ürün Adı",
        dataIndex: "name",
        key: "type",
        width: 50,
      },
      {
        title: "Gereken Miktar",
        dataIndex: "amount",
        width: 50,
      },
    ];
    return (
      <Card style={{ width: "60%", marginBottom: "1%", marginTop: "2%" }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          title={() => (
            <>
              <b>{text}</b>
            </>
          )}
          dataSource={data}
          columns={columns}
        />
      </Card>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {(() => {
          return configFile["tank_size"]
            .sort((a, b) => parseInt(b.type) - parseInt(a.type))
            .map((tank_size) => {
              return (
                <NeedsListTable
                  value={parseInt(tank_size.type)}
                  text={`${tank_size.type} LT Tank İçin İhtiyaç Listesi`}
                />
              );
            });
        })()}
      </div>
    </>
  );
}

export default OtherNeedListScreen;
