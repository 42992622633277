import { Spin, Timeline, Popconfirm, Button, Card } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConvertDateTime, ConvertOrderLocation } from "../helper";
import { RootState } from "../redux/reducers";
import {
  GetArmHistory,
  GetChassisHistory,
  GetFanHistory,
  GetOrderHistory,
  sendToArm,
  sendToChassis,
  sendToFan,
  UndoArmTo,
  UndoChassisTo,
  UndoFanTo,
  UndoSendTo,
} from "../services/OrderService";

interface Props {
  isVisible: boolean;
  machine_id: number;
  serial_number: string;
  setIsVisible: (bool: boolean) => void;
  partType: PartTypes;
  details: any;
}

enum PartTypes {
  arm,
  fan,
  chassis,
}

function PartsHistory(props: {
  machine_id: number;
  type: PartTypes;
  details: any;
}) {
  const { machine_id, type, details } = props;
  const [data, setData] = useState(null as unknown as []);
  const user = useSelector((state: RootState) => state.auth.user);
  const [lastLocation, setLastLocation] = useState(null) as any;

  useEffect(() => {
    GetHistory();
  }, [machine_id]);

  function GetHistory() {
    switch (type) {
      case PartTypes.arm:
        GetArmHistory(machine_id).then((res) => {
          if (res.length > 0) {
            setData(res);
            setLastLocation(
              res?.reduce(
                (
                  a: { row_created_date: number },
                  b: { row_created_date: number }
                ) => (a.row_created_date > b.row_created_date ? a : b)
              )
            );
          }
        });
        break;
      case PartTypes.fan:
        GetFanHistory(machine_id).then((res) => {
          if (res.length > 0) {
            setData(res);
            setLastLocation(
              res?.reduce(
                (
                  a: { row_created_date: number },
                  b: { row_created_date: number }
                ) => (a.row_created_date > b.row_created_date ? a : b)
              )
            );
          }
        });
        break;
      case PartTypes.chassis:
        GetChassisHistory(machine_id).then((res) => {
          if (res.length > 0) {
            setData(res);
            setLastLocation(
              res?.reduce(
                (
                  a: { row_created_date: number },
                  b: { row_created_date: number }
                ) => (a.row_created_date > b.row_created_date ? a : b)
              )
            );
          }
        });
        break;
    }
  }

  function renderTimeline() {
    return data?.map((timeline: any) => {
      return (
        <Timeline.Item
          key={"timelinekey" + timeline.row_created_date}
          label={ConvertDateTime(timeline.row_created_date)}
        >
          <p>
            <b>{ConvertOrderLocation(timeline.location)}</b>
          </p>
          <p>{`Gönderen: ${timeline.name} ${timeline.surname}`}</p>
        </Timeline.Item>
      );
    });
  }

  return data ? (
    <Card
      style={{ marginBottom: 5 }}
      title={
        type === PartTypes.arm
          ? "Kol İşlem Geçmişi"
          : type === PartTypes.chassis
          ? "Şase İşlem Geçmişi"
          : "Fan İşlem Geçmişi"
      }
    >
      <Timeline mode="left" style={{ width: "100%" }}>
        {renderTimeline()}
      </Timeline>
      {user.role === "admin" &&
      lastLocation?.location !== "onaybekliyor" &&
      lastLocation?.location !== "iptal" ? (
        <Popconfirm
          title="Bu parçayı bir sonraki konuma göndermek istediğinizden emin misiniz?"
          onConfirm={() => {
            switch (type) {
              case PartTypes.arm:
                switch (lastLocation?.location) {
                  case "onproduction":
                    sendToArm(
                      details.chassis_color === "galvaniz"
                        ? "sendedgalvaniz"
                        : "sendedpaint",
                      details.machine_id,
                      GetHistory
                    );

                    break;
                  case "sendedpaint":
                  case "sendedgalvaniz":
                    sendToArm(
                      details.chassis_color === "galvaniz"
                        ? "receivedgalvaniz"
                        : "receivedpaint",
                      details.machine_id,
                      GetHistory
                    );
                    break;
                  case "receivedgalvaniz":
                  case "receivedpaint":
                    sendToArm(
                      "sendedsaruhanli",
                      details.machine_id,
                      GetHistory
                    );
                    break;
                  case "sendedsaruhanli":
                    sendToArm(
                      "receivedsaruhanli",
                      details.machine_id,
                      GetHistory
                    );
                }
                break;
              case PartTypes.fan:
                switch (lastLocation?.location) {
                  case "onproduction":
                    sendToFan(
                      "receivedsaruhanli",
                      details.machine_id,
                      GetHistory
                    );
                }
                break;
              case PartTypes.chassis:
                switch (lastLocation?.location) {
                  case "onproduction":
                    sendToChassis(
                      details.chassis_color === "galvaniz"
                        ? "sendedgalvaniz"
                        : "sendedpaint",
                      details.machine_id,
                      GetHistory
                    );
                    break;
                  case "sendedpaint":
                  case "sendedgalvaniz":
                    sendToChassis(
                      details.chassis_color === "galvaniz"
                        ? "receivedgalvaniz"
                        : "receivedpaint",
                      details.machine_id,
                      GetHistory
                    );
                    break;
                  case "receivedgalvaniz":
                  case "receivedpaint":
                    sendToChassis(
                      "sendedsaruhanli",
                      details.machine_id,
                      GetHistory
                    );
                    break;
                  case "sendedsaruhanli":
                    sendToChassis(
                      "receivedsaruhanli",
                      details.machine_id,
                      GetHistory
                    );
                }
                break;
            }
          }}
          okText="Evet"
          cancelText="Hayır"
        >
          <Button style={{ marginBottom: 10 }} type="primary">
            Bir Sonraki Konuma Gönder
          </Button>
        </Popconfirm>
      ) : null}
    </Card>
  ) : null;
}

function PartHistory(props: Props) {
  const {
    isVisible,
    setIsVisible,
    machine_id,
    serial_number,
    partType,
    details,
  } = props;
  const [data, setData] = useState(null as unknown as []);

  const [lastLocation, setLastLocation] = useState(null) as any;
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    GetHistory();
  }, [machine_id]);

  function GetHistory() {
    GetOrderHistory(machine_id).then((res) => {
      if (res) {
        setData(res);
        setLastLocation(
          res?.reduce(
            (
              a: { row_created_date: number },
              b: { row_created_date: number }
            ) => (a.row_created_date > b.row_created_date ? a : b)
          )
        );
      }
    });
  }
  function renderTimeline() {
    return data?.map((timeline: any) => {
      return (
        <Timeline.Item
          key={"timelinekey" + timeline.row_created_date}
          label={ConvertDateTime(timeline.row_created_date)}
        >
          <p>
            <b>{ConvertOrderLocation(timeline.location)}</b>
          </p>
          <p>{`Gönderen: ${timeline.name} ${timeline.surname}`}</p>
        </Timeline.Item>
      );
    });
  }

  return (
    <Modal
      title={serial_number + " Numaralı Parçanın İşlem Geçmişi"}
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <PartsHistory
              details={details}
              machine_id={machine_id}
              type={partType}
            />
            <Card title="Makine İşlem Geçmişi" style={{ marginBottom: 5 }}>
              <Timeline mode="left" style={{ width: "100%" }}>
                {renderTimeline()}
              </Timeline>
            </Card>
            {user.role === "admin" &&
            lastLocation?.location !== "onaybekliyor" &&
            lastLocation?.location !== "iptal" ? (
              <Popconfirm
                title="Bu parçayı bir önceki konuma göndermek istediğinizden emin misiniz?"
                onConfirm={() => {
                  switch (partType) {
                    case PartTypes.arm:
                      UndoArmTo(machine_id).then((res) => {
                        if (res) {
                          GetHistory();
                        }
                      });
                      break;
                    case PartTypes.fan:
                      UndoFanTo(machine_id).then((res) => {
                        if (res) {
                          GetHistory();
                        }
                      });
                      break;
                    case PartTypes.chassis:
                      UndoChassisTo(machine_id).then((res) => {
                        if (res) {
                          GetHistory();
                        }
                      });
                      break;
                  }
                }}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button style={{ marginBottom: 10 }} type="primary">
                  Bir Önceki Konuma Geri Gönder
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </Modal>
  );
}

export default PartHistory;
