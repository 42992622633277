import { Col, Row, Typography, Form, Checkbox, DatePicker, Button } from "antd";
import {
  ExportCSVModel,
  ExportCSVReport,
  ExportCSVReportWithGroup,
} from "../services/ReportService";
import { useHistory } from "react-router";

import "dayjs/locale/tr";
import dayjs from "dayjs";
import { useState } from "react";

dayjs.locale("tr");

const { Title } = Typography;
const { RangePicker } = DatePicker;

function ExportCSV() {
  const history = useHistory();
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 },
  };
  const [isChecked, setIsChecked] = useState(false);

  const onFinish = (values: any) => {
    let model: any = new ExportCSVModel();

    values["bulundugu-konum"]?.forEach((value: any) => {
      model[value] = true;
    });

    model.OrderStartDate = values["siparis-tarihi"][0];
    model.OrderEndDate = values["siparis-tarihi"][1];
    model.StatusStartDate = values["son-islem-tarihi"][0];
    model.StatusEndDate = values["son-islem-tarihi"][1];

    !isChecked
      ? ExportCSVReport(model).then((res) => {
          if (res) {
            history.push("/");
            //setCSVData(res);
          }
        })
      : ExportCSVReportWithGroup(model).then((res) => {
          if (res) {
            history.push("/");
          }
        });
  };

  const locations: { [key: string]: string } = {
    turbo: "Turbo Üretimde",
    kollu: "Kollu Üretimde",
    diger: "Diğer Üretimde",
    test: "Testte",
    iptal: "İptal Edilmiş",
    onaybekliyor: "Üretim Onayı Bekliyor",
    teslimat: "Teslim Edilmiş",
    depo: "Depoda",
    bahce: "Bahçede",
    planlandı: "Planlandı",
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        overflow: "auto",
        maxWidth: "100%",
        margin: 10,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          backgroundColor: "rgb(240,240,240)",
          padding: "5%",
        }}
      >
        <Title level={4}>Siparişleri CSV'ye aktar</Title>
        <Form
          {...formItemLayout}
          name="reportPrefences"
          onFinish={onFinish}
          initialValues={{
            "bulundugu-konum": ["turbo", "kollu", "diger", "test"],
            // "siparis-tarihi": [dayjs().startOf("year"), dayjs().add(1, "days")],
            // "son-islem-tarihi": [
            //   dayjs().startOf("year"),
            //   dayjs().add(1, "days"),
            // ],
          }}
        >
          <Form.Item label="Sipariş Tarihi" name="siparis-tarihi">
            <RangePicker />
          </Form.Item>
          <Form.Item label="Son İşlem Tarihi" name="son-islem-tarihi">
            <RangePicker />
          </Form.Item>
          <Form.Item name="bulundugu-konum">
            <Checkbox.Group>
              <Row>
                {(function renderCheckboxs() {
                  return Object.keys(locations).map((location) => {
                    return (
                      <Col key={"exportcsvcheckbox" + location} span={12}>
                        <Checkbox
                          value={location}
                          style={{ lineHeight: "32px" }}
                        >
                          {locations[location]}
                        </Checkbox>
                      </Col>
                    );
                  });
                })()}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Row
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Checkbox onChange={() => setIsChecked(!isChecked)}>
              Grupla
            </Checkbox>

            <Button htmlType="submit" type="primary">
              Excel'e Aktar
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default ExportCSV;
