import Axios from "axios";
import { API_ADDRESS } from "..";
import { GetToken } from "../helper";

interface RegisterValues {
  Name: string;
  Surname: string;
  Role: string;
  Mail: string;
}

interface ChangePasswordValues {
  Mail: string;
  Password: string;
}

export async function GetAllUsers() {
  const response = await Axios.get(API_ADDRESS + "/api/auth/getAllUsers", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function Register(values: RegisterValues) {
  const response = await Axios.post(API_ADDRESS + "/api/auth/register", values, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 201) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function ChangePasswordAdmin(values: ChangePasswordValues) {
  const response = await Axios.post(API_ADDRESS + "/api/auth/changePasswordAdmin", values, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export async function DeleteUser(mail: string) {
  const response = await Axios.delete(API_ADDRESS + "/api/auth/deleteuser/" + mail, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
    })
    .catch(() => {
      return false;
    });

  return response;
}

export async function ReopenUser(mail: string) {
  const response = await Axios.put(
    API_ADDRESS + "/api/auth/ReopenUser/" + mail,
    {},
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
    })
    .catch(() => {
      return false;
    });

  return response;
}

export async function ChangeRole(Mail: string, Role: string) {
  const response = await Axios.put(
    API_ADDRESS + "/api/auth/ChangeRole/",
    { Mail, Role },
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
    })
    .catch(() => {
      return false;
    });

  return response;
}
