import Axios from "axios";
import { API_ADDRESS } from "..";
import { GetToken } from "../helper";

export async function GetChassisNeeds() {
  const response = await Axios.get(API_ADDRESS + "/api/Config/GetChassisNeeds/", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetTotalNeeds(value: number | string) {
  const response = await Axios.get(API_ADDRESS + "/api/Config/GetTotalNeeds/" + value, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetFanNeeds() {
  const response = await Axios.get(API_ADDRESS + "/api/Config/GetFanNeeds/", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetPumpNeeds() {
  const response = await Axios.get(API_ADDRESS + "/api/Config/GetPumpNeeds/", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetCommanderNeeds() {
  const response = await Axios.get(API_ADDRESS + "/api/Config/GetCommanderNeeds/", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetTowNeeds() {
  const response = await Axios.get(API_ADDRESS + "/api/Config/GetTowNeeds/", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetGenericTaskRoleAuthorizationList(value: string) {
  const response = await Axios.get(API_ADDRESS + "/api/Task/GetGenericTaskRoleAuthorizationList/" + value, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function AddGenericTaskRoleAuthorizationList(obj: { role: string; authority: string }) {
  const response = await Axios.post(API_ADDRESS + "/api/Task/AddGenericTaskRoleAuthorizationList/", obj, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function DeleteGenericTaskRoleAuthorizationList(id: number) {
  const response = await Axios.delete(API_ADDRESS + "/api/Task/DeleteGenericTaskRoleAuthorizationList/" + id, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function GetNeedsList(value: number | string) {
  const response = await Axios.get(API_ADDRESS + "/api/Config/GetNeedList/" + value, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function AddNeedsList(obj: { name: string; amount: number; tank_size: number | string }) {
  const response = await Axios.post(API_ADDRESS + "/api/Config/AddNeedList/", obj, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}

export async function DeleteNeedsList(id: number) {
  const response = await Axios.delete(API_ADDRESS + "/api/Config/DeleteNeedList/" + id, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  return response;
}
