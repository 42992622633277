/* eslint-disable eqeqeq */
import React, { ReactNode, useState } from "react";
import {
  Table,
  Input,
  Button,
  Select,
  Card,
  InputNumber,
  Popconfirm,
  Popover,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GenericOrder } from "../services/OrderService";
import { Capitalize, ConvertDateTime } from "../helper";
import {
  DivideGenericTask,
  DeleteGenericTask,
  GetActiveGenericTasks,
  CreateGenericTask,
  GetFinishedGenericTasks,
  ModifyGenericTask,
  UpdateGenericTask,
} from "../services/ProductionPlanService";
import { connect } from "react-redux";
import GenericTaskHistory from "../components/TaskHistory";
import * as XLSX from "xlsx/xlsx.mjs";

const config: IAgrotekConfig = JSON.parse(
  localStorage.getItem("config") ?? "{}"
);
const moment = require("moment/min/moment-with-locales");
moment.locale("tr");

const DivideContent = (Props: {
  maxAmount: number,
  id: number,
  getTasks: () => void,
}) => {
  const [amount, setAmount] = useState(1);

  return (
    <div>
      <InputNumber
        max={Props.maxAmount}
        style={{ width: "70%" }}
        size="large"
        defaultValue={1}
        formatter={(value) => `Bölünen Miktar ${value}`}
        parser={(value) => value.split("Bölünen Miktar ")[1]}
        onChange={(value) => setAmount(value)}
      />
      <Button
        danger
        size="large"
        style={{ marginLeft: 10 }}
        onClick={() => {
          DivideGenericTask(Props.id, amount).then((res) => {
            if (res) Props.getTasks();
          });
        }}
      >
        Böl
      </Button>
    </div>
  );
};

class QRCodeScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: ReactNode) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    selectedRole: "anasiparis",
    selectedUnit: "adet",
    csvdata: [
      [
        "Görev No",
        "Grup",
        "Başlık",
        "Ek Açıklama",
        "Miktar",
        "Kazan Tipi",
        "Tamamlanan",
        "Görev Verilme Tarihi",
        "Son İşlem Tarihi",
      ],
    ],
    loading: false,
    data: [],
    columns: [
      {
        title: "Görev No",
        dataIndex: "id",
        sorter: (a: GenericOrder, b: GenericOrder) => a.id - b.id,
        ...this.getColumnSearchProps("id"),
      },
      {
        title: "Grup",
        dataIndex: "last_location",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.last_location.localeCompare(b.last_location),
        ...this.getColumnSearchProps("last_location"),
        render: (text: string, data) => (
          <td>
            {this.props.isActive ? (
              <Select
                disabled={this.props.user.role !== "admin"}
                value={text}
                size="large"
                style={{ width: 200 }}
                onChange={(value) => {
                  ModifyGenericTask({ id: data.id, new_group: value }).then(
                    (res) => {
                      this.GetTasks();
                    }
                  );
                }}
              >
                {(() => {
                  return config.role?.map((role) => {
                    return (
                      <Select.Option value={role.type}>
                        {role.text}
                      </Select.Option>
                    );
                  });
                })()}
              </Select>
            ) : (
              (() => {
                return config.role?.map((role) => {
                  if (role.type == text) return <p>{role.text}</p>;
                });
              })()
            )}
          </td>
        ),
      },
      {
        title: "Başlık",
        dataIndex: "title",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.title.localeCompare(b.title),
        ...this.getColumnSearchProps("title"),
        render: (text: string) => <td>{Capitalize(text)}</td>,
      },
      {
        title: "Ek Açıklama",
        dataIndex: "description",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.description.localeCompare(b.description),
        ...this.getColumnSearchProps("description"),
        render: (text: string) => <td>{text ? Capitalize(text) : ""}</td>,
      },
      {
        title: "Miktar",
        dataIndex: "amount",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          parseInt(a.amount.split(" ")[0]) - parseInt(b.amount.split(" ")[0]),
      },
      {
        title: "Tamamlanan",
        dataIndex: "completed_amount",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          a.completed_amount - b.completed_amount,
      },
      {
        title: "Görev Verilme Tarihi",
        dataIndex: "row_created_date",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          moment(a.row_created_date).unix() - moment(b.row_created_date).unix(),
        ...this.getColumnSearchProps("row_created_date"),
        render: (text) => <td>{ConvertDateTime(text)}</td>,
      },
      {
        title: "Son İşlem Tarihi",
        dataIndex: "row_updated_date",
        sorter: (a: GenericOrder, b: GenericOrder) =>
          moment(a.row_updated_date).unix() - moment(b.row_updated_date).unix(),
        ...this.getColumnSearchProps("row_updated_date"),
        render: (text) => <td>{ConvertDateTime(text)}</td>,
      },

      {
        title: "İşlemler",
        render: (text, order: GenericOrder) => (
          <td>
            {(this.props.user.role == "admin" ||
              this.props.user.role == "ambar1") &&
            order.last_location == "ambar1" &&
            order.title == "QR Kod Basımı" ? (
              <Popconfirm
                title={
                  <div>
                    Bu görevi tamamlamak istediğinizden eminmisiniz? <br /> Bu
                    işlem geri alınamaz
                  </div>
                }
                onConfirm={() => {
                  UpdateGenericTask(order.id, 1).then((res) => {
                    if (res) this.GetTasks();
                  });
                }}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button style={{ margin: 5 }} block size="large" type="primary">
                  Tamamla
                </Button>
              </Popconfirm>
            ) : null}
            {this.props.user.role == "admin" ? (
              <Popconfirm
                title={
                  <div>
                    Bu görevi silmek istediğinizden eminmisiniz? <br /> Bu işlem
                    geri alınamaz
                  </div>
                }
                onConfirm={() => {
                  DeleteGenericTask(order.id).then((res) => {
                    if (res) this.GetTasks();
                  });
                }}
                okText="Evet"
                cancelText="Hayir"
              >
                <Button
                  style={{ margin: 5 }}
                  block
                  danger
                  size="large"
                  type="primary"
                >
                  Sil
                </Button>
              </Popconfirm>
            ) : null}
            <Button
              block
              style={{ margin: 5 }}
              onClick={() => {
                this.setState({
                  isModalVisible: true,
                  selectedTaskId: order.id,
                });
              }}
              size="large"
              type="primary"
            >
              İşlem Geçmişi
            </Button>
            {this.props.user.role == "admin" ||
            this.props.user.role == "lazer" ||
            this.props.user.role === "lazer2" ||
            this.props.user.role === "lazerkesim" ||
            (this.props.user.role == "depo" &&
              order.completed_amount == 0 &&
              this.props.isActive) ? (
              <Popover
                content={
                  <DivideContent
                    maxAmount={parseInt(order.amount.split(" ")[0]) - 1}
                    id={order.id}
                    getTasks={this.GetTasks.bind(this)}
                  />
                }
                title={
                  <>
                    Görevin tamamlanan ve kalan kısmını ayırmak istediğinizden
                    emin misiniz? <br /> Bu işlem geri alınamaz
                  </>
                }
                trigger="click"
              >
                <Button
                  style={{ margin: 5 }}
                  block
                  danger
                  size="large"
                  type="primary"
                >
                  Böl
                </Button>
              </Popover>
            ) : null}
          </td>
        ),
      },
    ],
  };

  componentDidMount() {
    this.GetTasks();
  }

  componentWillReceiveProps = (newProps, oldProps) => {
    setTimeout(() => {
      this.GetTasks();
    }, 500);
  };

  GetTasks() {
    let isActive = this.props.isActive;

    this.setState({ loading: true });
    let csvdata = [
      [
        "Görev No",
        "Grup",
        "Başlık",
        "Ek Açıklama",
        "Miktar",
        "Tamamlanan",
        "Görev Verilme Tarihi",
        "Son İşlem Tarihi",
      ],
    ];

    if (isActive) {
      GetActiveGenericTasks().then((data) => {
        data?.forEach((task) => {
          csvdata.push([
            task.id,
            task.last_location,
            task.title,
            task.description,
            task.amount,
            task.completed_amount,
            ConvertDateTime(task.row_created_date),
            ConvertDateTime(task.row_updated_date),
          ]);
        });
        this.setState({ data, csvdata, loading: false });
      });
    } else {
      GetFinishedGenericTasks().then((data) => {
        data?.forEach((task) => {
          csvdata.push([
            task.id,
            task.last_location,
            task.title,
            task.description,
            task.amount,
            task.completed_amount,
            ConvertDateTime(task.row_created_date),
            ConvertDateTime(task.row_updated_date),
          ]);
        });
        this.setState({ data, csvdata, loading: false });
      });
    }
  }
  SaveExcel = (data) => {
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
  searchInput: React.ReactNode;

  render() {
    const state = this.state;

    return (
      <>
        <div>
          <Card>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                marginBottom: 5,
              }}
            >
              <Input
                disabled={!this.props.isActive}
                onChange={(e) => {
                  this.setState({ selectedTitle: e.target.value });
                }}
                value={this.state.selectedTitle}
                style={{ width: 500 }}
                size="large"
                addonBefore="Görev"
                placeholder="Görev Tanımı"
              />
              <Input
                disabled={!this.props.isActive}
                onChange={(e) => {
                  this.setState({ selectedDescription: e.target.value });
                }}
                value={this.state.selectedDescription}
                style={{ width: 500, marginLeft: 25, marginRight: 25 }}
                size="large"
                addonBefore="Açıklama?"
                placeholder="Opsiyonel"
              />
              <Input.Group compact>
                <InputNumber
                  disabled={!this.props.isActive}
                  onChange={(e) => {
                    this.setState({ selectedAmount: e });
                  }}
                  value={this.state.selectedAmount}
                  style={{ width: 100 }}
                  min={1}
                  placeholder="0"
                  size="large"
                />
                <Input
                  disabled={!this.props.isActive}
                  onChange={(e) => {
                    this.setState({ selectedUnit: e.target.value });
                  }}
                  value={this.state.selectedUnit}
                  style={{ width: 100 }}
                  defaultValue="adet"
                  size="large"
                />
              </Input.Group>
              <Button
                style={styles.button}
                type="primary"
                loading={this.state.addTaskButtonLoading}
                disabled={
                  !state.selectedAmount ||
                  !state.selectedTitle ||
                  !state.selectedRole
                }
                onClick={() => {
                  this.setState({ addTaskButtonLoading: true });
                  CreateGenericTask({
                    title: state.selectedTitle,
                    description: state.selectedDescription,
                    group: state.selectedRole,
                    amount: state.selectedAmount,
                    unit: state.selectedUnit,
                  }).then((res) => {
                    if (res) {
                      this.setState({
                        selectedTitle: null,
                        selectedDescription: null,
                        selectedAmount: null,
                        selectedUnit: "adet",
                      });
                      this.GetTasks();
                    }
                    this.setState({ addTaskButtonLoading: false });
                  });
                }}
              >
                Emir Oluştur
              </Button>
            </div>
            <Button
              style={{ ...styles.button, float: "right" }}
              onClick={() => this.SaveExcel(this.state.csvdata)}
            >
              Emirleri Excele Aktar
            </Button>
          </Card>
        </div>

        <Table
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          rowKey={(row) => "key" + row.id}
          size="small"
          style={{ maxWidth: "99.5%" }}
          loading={this.state.loading}
          columns={this.state.columns}
          dataSource={state.data}
        />
        <GenericTaskHistory
          isVisible={this.state.isModalVisible}
          task_id={this.state.selectedTaskId}
          setIsVisible={(value) => this.setState({ isModalVisible: value })}
        />
      </>
    );
  }
}

const styles = {
  button: {
    marginLeft: 25,
  },
};

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(QRCodeScreen);
