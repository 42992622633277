/* eslint-disable eqeqeq */
import React, { createRef } from "react";
import {
  Button,
  Calendar,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Select,
} from "antd";
import { connect } from "react-redux";
import { GetPendingOrders } from "../services/OrderService";
import Title from "antd/lib/typography/Title";
import {
  CancelProductionPlan,
  CreateProductionPlan,
  GetProductionPlans,
} from "../services/ProductionPlanService";
import Text from "antd/lib/typography/Text";
import { DeleteOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { CSVLink } from "react-csv";
import { ConvertDateTime } from "../helper";
import * as XLSX from "xlsx/xlsx.mjs";

import "dayjs/locale/tr";
import dayjs, { Dayjs } from "dayjs";

const { Option } = Select;
dayjs.locale("tr");

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class OrderPlanningScreen extends React.Component<any> {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");
  formRef = createRef<FormInstance>();

  constructor(props: any) {
    super(props);
    this.dateCellRender = this.dateCellRender.bind(this);
    this.GetData = this.GetData.bind(this);
  }

  state = {
    loading: true,
    selectedDate: null as unknown as Dayjs,
    pendingOrders: [] as unknown as [any],
    data: [] as unknown as [any],
    csvdata: [[]] as unknown as [[any]],
  };

  componentDidMount() {
    this.GetData();
  }

  GetData() {
    GetProductionPlans().then((res) => {
      this.setState({ data: res });

      let csv = [["Planlanan Tarih", "Müşteri", "Seri No", "Ürün", "Durum"]];

      res.forEach((record: any) =>
        csv.push([
          ConvertDateTime(record.planned_date),
          record.customer,
          record.serial_number,
          record.description,
          record.status,
        ])
      );
      this.setState({ csvdata: csv, data: res, loading: false });
    });
    GetPendingOrders().then((res) => {
      this.setState({ pendingOrders: res });
    });
  }

  dateCellRender(value: Dayjs) {
    const filteredData = this.state.data?.filter((data) =>
      value.isSame(data.planned_date, "day")
    );
    return (
      <ul style={{ margin: 0, padding: 0 }} className="events">
        {filteredData?.map((item) => (
          <li key={item.serial_number}>
            <Text style={{ fontSize: 8 }} ellipsis>
              {item.serial_number}
            </Text>
          </li>
        ))}
      </ul>
    );
  }

  onFinish = (values: any) => {
    CreateProductionPlan(
      { ...values, planned_date: this.state?.selectedDate.format() },
      this.GetData
    ).then(() => {
      this.formRef.current?.resetFields();
    });
  };

  onFinishFailed = (errorInfo: any) => {

  };
  SaveExcel = (data) => {

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
  render() {
    const { selectedDate, pendingOrders } = this.state;
    const { onFinish, onFinishFailed } = this;

    return (
      <>
        <Row>
          <Col md={24} lg={10}>
            {selectedDate ? (
              <>
                <Card style={{ margin: "5%" }}>
                  <Form
                    {...layout}
                    name="basic"
                    ref={this.formRef}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Title level={5}>{`${selectedDate
                      ?.startOf("day")
                      .locale("tr")
                      .format("D MMMM YYYY")} Tarihine İş Ekle`}</Title>

                    <Form.Item
                      label="Üretilecek Makinalar"
                      name="machine_ids"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen en az bir makine seçin.",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {(() => {
                          return pendingOrders?.map((order) => {
                            return (
                              <Option value={order.id}>
                                {order.customer + " " + order.serial_number}
                              </Option>
                            );
                          });
                        })()}
                      </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      {this.props.user.role == "admin" && (
                        <Button type="primary" htmlType="submit">
                          Ekle
                        </Button>
                      )}
                    </Form.Item>
                  </Form>
                </Card>
                <Card style={{ margin: "5%" }}>
                  {(() => {
                    let tasks = this.state.data?.filter((data) =>
                      this.state.selectedDate?.isSame(data.planned_date, "day")
                    );
                    return (
                      <ul style={{ margin: 0, padding: 0 }} className="events">
                        {tasks?.map((item) => (
                          <li key={item.serial_number}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: 5,
                                padding: 2,
                                borderBottom: "0.5px solid",
                              }}
                            >
                              <Text ellipsis>
                                {item.customer + " - " + item.serial_number}
                              </Text>
                              {this.props.user.role == "admin" && (
                                <Popconfirm
                                  title="Bu planı silmek istediğinizden emin misiniz?"
                                  onConfirm={() => {
                                    CancelProductionPlan(
                                      item.machine_id,
                                      this.GetData
                                    );
                                  }}
                                  okText="Evet"
                                  cancelText="Hayır"
                                >
                                  <Button
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    danger
                                  />
                                </Popconfirm>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    );
                  })()}
                </Card>
              </>
            ) : null}
          </Col>
          <Col md={24} lg={14}>
            <Button
              loading={this.state.loading}
              style={{ float: "right" }}
              onClick={() => this.SaveExcel(this.state.csvdata)}
            >
              Excele Aktar
            </Button>
            <Calendar
              onSelect={(date) => {
                this.setState({ selectedDate: dayjs(date) });
              }}
              dateCellRender={this.dateCellRender}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: { auth: { user: any } }, ownProps: any) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(OrderPlanningScreen);
