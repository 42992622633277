import { Modal } from "antd";
import Axios from "axios";
import { Dispatch } from "redux";
import { API_ADDRESS } from "../..";
import { LOGIN, LOGOUT } from "../reducers/authReducer";

export async function getConfig() {
  await Axios.get(API_ADDRESS + "/api/config").then((res) => {
    localStorage.setItem("config", JSON.stringify(res.data));
  });
  return true;
}

export function login(Mail: string, Password: string) {
  return async (dispatch: Dispatch) => {
    Axios.post(API_ADDRESS + "/api/auth/login", { Mail, Password })
      .then((res) => {
        sessionStorage.setItem("user", JSON.stringify(res.data));
        dispatch({ type: LOGIN, payload: res.data });
        window.location.href = "/";
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          Modal.error({
            title: "Giriş Yetkiniz Yok",
            content: <p>Lütfen admin ile iletişime geçin..</p>,
            okText: "Tamam",
          });
        } else {
          Modal.error({
            title: "Bilgileriniz Yanlış",
            content: <p>Lütfen bilgilerinizi kontrol edin..</p>,
            okText: "Tamam",
          });
        }
      });
  };
}

export function logout() {
  return async (dispatch: Dispatch) => {
    sessionStorage.removeItem("user");
    dispatch({ type: LOGOUT });
  };
}
