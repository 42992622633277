import React, { useState } from "react";
import { Button, Card, Col, Form, Input, Layout, Row } from "antd";
import { Body } from "./MainScreen";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/authActions";
import { Link } from "react-router-dom";
import Text from "antd/lib/typography/Text";
const { Header, Content, Sider } = Layout;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

function LoginScreen() {
  const dispatch = useDispatch();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitButtonLoading(true);
    dispatch(login(values.mail, values.password));
    setSubmitButtonLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Layout style={{ height: "100%" }}>
      <Content>
        <Row style={{ height: "100%" }} justify="center" align="middle">
          <Card
            bordered
            style={{ width: "min-content", height: "min-content" }}
          >
            <Card style={{ minWidth: "350px" }}>
              <div
                style={{
                  display: "flex",
                  height: "auto",
                  width: "auto",
                  justifyContent: "center",
                }}
              >
                <img width="200" height="200" src="agrotek.png" alt="image" />
              </div>
            </Card>
            <h1 className="center">Giriş</h1>
            <Form
              {...layout}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              requiredMark={false}
            >
              <Form.Item
                name="mail"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bir kullanıcı adı girin.",
                  },
                ]}
              >
                <Input placeholder="kullanıcı@mail.com" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Bu alan boş bırakılamaz." },
                ]}
              >
                <Input.Password placeholder="******" />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  style={{ marginBottom: "1rem" }}
                  type="primary"
                  htmlType="submit"
                  loading={submitButtonLoading}
                  block
                >
                  Giriş Yap
                </Button>
                <Link to="/forgotPassword" className="center">
                  Şifremi unuttum
                </Link>
              </Form.Item>
              <div className="center">
                <a href="https://covisart.com.tr/">
                  <img width="auto" height="16" src="logo.png" alt="image" />
                </a>
              </div>
              <Text className="center">Software: v1.17.2.24</Text>
            </Form>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
}

export default LoginScreen;
